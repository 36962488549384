import React from 'react';
import { FaRegEdit } from "react-icons/fa";
import { useDispatch } from 'react-redux';
import Avatar from '../../../components/assert/images/management/men.png';
import InternalUserAction from '../../../redex/InternalUser/Actions';
import ProfileEdit from './model/ProfileEdit';

const Content = () => {
  const profile = {
    user: {
      firstName: "Karpagamurugan",
      middleName: "",
      lastName: "A",
      dateOfBirth: "1999-01-29",
      genderLookup: { name: "Male" },
      emailAddress: "karpagamurugan@bwefinserv.com",
      phoneNumber: "9791631673",
      fullName: "Karpagamurugan A",
      department: "IT",
      position: "SOFTWARE_ENG_FNT",
      employmentStartDate: "2023-07-03",
      employmentEndDate:'',
      employmentStatus: "ACTIVE",
      emergencyContact: "9524305120",
      bloodGroup: "B +VE",
      supervisorName: "Sharma Radhakrishna Venkataramanan",
      pan: "EVCPA4518Q",
      aadhaar: "650171481642",
      address: {
        pinCode: "641044",
        country: "India",
        state: "TAMIL NADU",
        city: "Kodaikanal",
        district: "Dindigul",
        addressLine1: "31/4",
        addressLine2: "",
      }
    }
  };
  const dispatch = useDispatch();

  const { user } = profile;
  const address = user.address;

  return (
    <div>
      <div className="bootstrap snippets bootdey dash_profile_section">
        <div className="row dash_grid">
          <div className="profile-nav col-md-3">
            <div className="panel">
              <div className="user-heading round">
                <div className='dash_profile_img'>
                  <img src={Avatar} alt="" />
                </div>
                <h1>{user.fullName}</h1>
                <p>{user.emailAddress}</p>
              </div>
            </div>
          </div>
          <div className="profile-info col-md-9">
            <div className="panel">
              <div className="panel-body bio-graph-info">
                <div className='heading_profile bb'>
                  <h1>Bio Graph</h1>
                  <button onClick={() => dispatch({ type: InternalUserAction.EDIT_PROFILE_MODEL, payload: { open: true, data: profile } })}> <FaRegEdit /> Edit profile</button>
                </div>
                <div className="row">
                  <div className="bio-row">
                    <p><span>First Name </span>: {user.firstName}</p>
                  </div>
                  <div className="bio-row">
                    <p><span>Middle Name </span>: {user.middleName || 'N/A'}</p>
                  </div>
                  <div className="bio-row">
                    <p><span>Last Name </span>: {user.lastName}</p>
                  </div>
                  <div className="bio-row">
                    <p><span>Birthday</span>: {user.dateOfBirth}</p>
                  </div>
                  <div className="bio-row">
                    <p><span>Gender </span>: {user.genderLookup.name}</p>
                  </div>
                  <div className="bio-row">
                    <p><span>Email </span>: {user.emailAddress}</p>
                  </div>
                  <div className="bio-row">
                    <p><span>Phone Number </span>: {user.phoneNumber}</p>
                  </div>
                  <div className="bio-row">
                    <p><span>Department </span>: {user.department}</p>
                  </div>
                  <div className="bio-row">
                    <p><span>Position </span>: {user.position}</p>
                  </div>
                  <div className="bio-row">
                    <p><span>Start Date </span>: {user.employmentStartDate}</p>
                  </div>
                  <div className="bio-row">
                    <p><span>End Date </span>: {user.employmentEndDate || 'N/A'}</p>
                  </div>
                  <div className="bio-row">
                    <p><span>Status </span>: {user.employmentStatus}</p>
                  </div>
                  <div className="bio-row">
                    <p><span>Supervisor </span>: {user.supervisorName}</p>
                  </div>
                  <div className="bio-row">
                    <p><span>Emergency </span>: {user.emergencyContact}</p>
                  </div>
                  <div className="bio-row">
                    <p><span>Blood Group </span>: {user.bloodGroup}</p>
                  </div>
                  <div className="bio-row">
                    <p><span>PAN </span>: {user.pan}</p>
                  </div>
                  <div className="bio-row">
                    <p><span>Aadhaar </span>: {user.aadhaar}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="profile-info col-md-12">
            <div className="panel">
              <div className="panel-body bio-graph-info">
                <h1 className='bb'>Address</h1>
                <div className="row">
                  <div className="bio-row">
                    <p><span>Address Line 1 </span>: {address.addressLine1}</p>
                  </div>
                  <div className="bio-row">
                    <p><span>Address Line 2 </span>: {address.addressLine2 || 'N/A'}</p>
                  </div>
                  <div className="bio-row">
                    <p><span>City </span>: {address.city}</p>
                  </div>
                  <div className="bio-row">
                    <p><span>State </span>: {address.state}</p>
                  </div>
                  <div className="bio-row">
                    <p><span>Country </span>: {address.country}</p>
                  </div>
                  <div className="bio-row">
                    <p><span>District </span>: {address.district}</p>
                  </div>
                  <div className="bio-row">
                    <p><span>Pin Code </span>: {address.pinCode}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ProfileEdit />
    </div>
  );
};

export default Content;
