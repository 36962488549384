import React, { useEffect, useState } from 'react';
import Pagination from '../../common/TablePagination';



const Content = () => {
  const [leaves] = useState([
    {
      "id": 152,
      "employeeId": 11,
      "employeeName": "BWE Admin",
      "leaveType": "VACATION",
      "startDate": "2024-07-11",
      "startIsHalfDay": null,
      "endDate": "2024-07-12",
      "endIsHalfDay": null,
      "reason": "test",
      "isApproved": false,
      "isRejected": true,
      "nominatorId": null,
      "notes": null,
      "isCancelled": false,
      "createdTime": "2024-02-28 17:27:30"
    },
    {
      "id": 178,
      "employeeId": 11,
      "employeeName": "BWE Admin",
      "leaveType": "MISCARRIAGE",
      "startDate": "2024-07-16",
      "startIsHalfDay": null,
      "endDate": "2024-07-16",
      "endIsHalfDay": null,
      "reason": "test",
      "isApproved": false,
      "isRejected": false,
      "nominatorId": null,
      "notes": "testt",
      "isCancelled": true,
      "createdTime": "2024-07-16 16:40:11"
    },
    {
      "id": 181,
      "employeeId": 11,
      "employeeName": "BWE Admin",
      "leaveType": "CHILDCARE",
      "startDate": "2024-07-17",
      "startIsHalfDay": null,
      "endDate": "2024-07-17",
      "endIsHalfDay": null,
      "reason": "testt",
      "isApproved": false,
      "isRejected": false,
      "nominatorId": null,
      "notes": "test",
      "isCancelled": true,
      "createdTime": "2024-07-16 17:04:00"
    },
    {
      "id": 152,
      "employeeId": 11,
      "employeeName": "BWE Admin",
      "leaveType": "VACATION",
      "startDate": "2024-07-11",
      "startIsHalfDay": null,
      "endDate": "2024-07-12",
      "endIsHalfDay": null,
      "reason": "test",
      "isApproved": false,
      "isRejected": true,
      "nominatorId": null,
      "notes": null,
      "isCancelled": false,
      "createdTime": "2024-02-28 17:27:30"
    },
    {
      "id": 178,
      "employeeId": 11,
      "employeeName": "BWE Admin",
      "leaveType": "MISCARRIAGE",
      "startDate": "2024-07-16",
      "startIsHalfDay": null,
      "endDate": "2024-07-16",
      "endIsHalfDay": null,
      "reason": "test",
      "isApproved": false,
      "isRejected": false,
      "nominatorId": null,
      "notes": "testt",
      "isCancelled": true,
      "createdTime": "2024-07-16 16:40:11"
    },
    {
      "id": 181,
      "employeeId": 11,
      "employeeName": "BWE Admin",
      "leaveType": "CHILDCARE",
      "startDate": "2024-07-17",
      "startIsHalfDay": null,
      "endDate": "2024-07-17",
      "endIsHalfDay": null,
      "reason": "testt",
      "isApproved": false,
      "isRejected": false,
      "nominatorId": null,
      "notes": "test",
      "isCancelled": true,
      "createdTime": "2024-07-16 17:04:00"
    },
    {
      "id": 152,
      "employeeId": 11,
      "employeeName": "BWE Admin",
      "leaveType": "VACATION",
      "startDate": "2024-07-11",
      "startIsHalfDay": null,
      "endDate": "2024-07-12",
      "endIsHalfDay": null,
      "reason": "test",
      "isApproved": false,
      "isRejected": true,
      "nominatorId": null,
      "notes": null,
      "isCancelled": false,
      "createdTime": "2024-02-28 17:27:30"
    },
    {
      "id": 178,
      "employeeId": 11,
      "employeeName": "BWE Admin",
      "leaveType": "MISCARRIAGE",
      "startDate": "2024-07-16",
      "startIsHalfDay": null,
      "endDate": "2024-07-16",
      "endIsHalfDay": null,
      "reason": "test",
      "isApproved": false,
      "isRejected": false,
      "nominatorId": null,
      "notes": "testt",
      "isCancelled": true,
      "createdTime": "2024-07-16 16:40:11"
    },
    {
      "id": 181,
      "employeeId": 11,
      "employeeName": "BWE Admin",
      "leaveType": "CHILDCARE",
      "startDate": "2024-07-17",
      "startIsHalfDay": null,
      "endDate": "2024-07-17",
      "endIsHalfDay": null,
      "reason": "testt",
      "isApproved": false,
      "isRejected": false,
      "nominatorId": null,
      "notes": "test",
      "isCancelled": true,
      "createdTime": "2024-07-16 17:04:00"
    },
    {
      "id": 152,
      "employeeId": 11,
      "employeeName": "BWE Admin",
      "leaveType": "VACATION",
      "startDate": "2024-07-11",
      "startIsHalfDay": null,
      "endDate": "2024-07-12",
      "endIsHalfDay": null,
      "reason": "test",
      "isApproved": false,
      "isRejected": true,
      "nominatorId": null,
      "notes": null,
      "isCancelled": false,
      "createdTime": "2024-02-28 17:27:30"
    },
    {
      "id": 178,
      "employeeId": 11,
      "employeeName": "BWE Admin",
      "leaveType": "MISCARRIAGE",
      "startDate": "2024-07-16",
      "startIsHalfDay": null,
      "endDate": "2024-07-16",
      "endIsHalfDay": null,
      "reason": "test",
      "isApproved": false,
      "isRejected": false,
      "nominatorId": null,
      "notes": "testt",
      "isCancelled": true,
      "createdTime": "2024-07-16 16:40:11"
    },
    {
      "id": 181,
      "employeeId": 11,
      "employeeName": "BWE Admin",
      "leaveType": "CHILDCARE",
      "startDate": "2024-07-17",
      "startIsHalfDay": null,
      "endDate": "2024-07-17",
      "endIsHalfDay": null,
      "reason": "testt",
      "isApproved": false,
      "isRejected": false,
      "nominatorId": null,
      "notes": "test",
      "isCancelled": true,
      "createdTime": "2024-07-16 17:04:00"
    },
    {
      "id": 152,
      "employeeId": 11,
      "employeeName": "BWE Admin",
      "leaveType": "VACATION",
      "startDate": "2024-07-11",
      "startIsHalfDay": null,
      "endDate": "2024-07-12",
      "endIsHalfDay": null,
      "reason": "test",
      "isApproved": false,
      "isRejected": true,
      "nominatorId": null,
      "notes": null,
      "isCancelled": false,
      "createdTime": "2024-02-28 17:27:30"
    },
    {
      "id": 178,
      "employeeId": 11,
      "employeeName": "BWE Admin",
      "leaveType": "MISCARRIAGE",
      "startDate": "2024-07-16",
      "startIsHalfDay": null,
      "endDate": "2024-07-16",
      "endIsHalfDay": null,
      "reason": "test",
      "isApproved": false,
      "isRejected": false,
      "nominatorId": null,
      "notes": "testt",
      "isCancelled": true,
      "createdTime": "2024-07-16 16:40:11"
    },
    {
      "id": 181,
      "employeeId": 11,
      "employeeName": "BWE Admin",
      "leaveType": "CHILDCARE",
      "startDate": "2024-07-17",
      "startIsHalfDay": null,
      "endDate": "2024-07-17",
      "endIsHalfDay": null,
      "reason": "testt",
      "isApproved": false,
      "isRejected": false,
      "nominatorId": null,
      "notes": "test",
      "isCancelled": true,
      "createdTime": "2024-07-16 17:04:00"
    },
    {
      "id": 152,
      "employeeId": 11,
      "employeeName": "BWE Admin",
      "leaveType": "VACATION",
      "startDate": "2024-07-11",
      "startIsHalfDay": null,
      "endDate": "2024-07-12",
      "endIsHalfDay": null,
      "reason": "test",
      "isApproved": false,
      "isRejected": true,
      "nominatorId": null,
      "notes": null,
      "isCancelled": false,
      "createdTime": "2024-02-28 17:27:30"
    },
    {
      "id": 178,
      "employeeId": 11,
      "employeeName": "BWE Admin",
      "leaveType": "MISCARRIAGE",
      "startDate": "2024-07-16",
      "startIsHalfDay": null,
      "endDate": "2024-07-16",
      "endIsHalfDay": null,
      "reason": "test",
      "isApproved": false,
      "isRejected": false,
      "nominatorId": null,
      "notes": "testt",
      "isCancelled": true,
      "createdTime": "2024-07-16 16:40:11"
    },
    {
      "id": 181,
      "employeeId": 11,
      "employeeName": "BWE Admin",
      "leaveType": "CHILDCARE",
      "startDate": "2024-07-17",
      "startIsHalfDay": null,
      "endDate": "2024-07-17",
      "endIsHalfDay": null,
      "reason": "testt",
      "isApproved": false,
      "isRejected": false,
      "nominatorId": null,
      "notes": "test",
      "isCancelled": true,
      "createdTime": "2024-07-16 17:04:00"
    },
  ]);

  const [tableCount, setTableCount] = useState(5);
  const [paginationData, setPaginationData] = useState([]);
  const [searchResult, setSearchResult] = useState([])
  const [searchText, setSearchText] = useState()
  
  useEffect(() => {
    if (leaves?.length !== 0) {
      let temp = searchText?.length ? leaves?.filter((value) => (
        Object.values(value)?.some((el) => (
          el?.toString().includes(searchText)
        ))
      )) : leaves
      setSearchResult(temp)
    } else {
      setSearchResult([])
    }
  }, [leaves, searchText])

  return (
    <div className="dash-content-grid">
      <div className="">
        <div className="leave_and_blnz_cont">
          <div className=" sub_leave_inputs">
            <div className='search_leave'>
              <div className="dash-fm-group"><label className="dash-fm-label">Start Date</label><input className="dash-fm-input dateFontsize14" type="date" name="startDate" /></div>
              <div className="dash-fm-group"><label className="dash-fm-label">End Date</label><input className="dash-fm-input dateFontsize14" type="date" name="endDate" /></div>
              <div className="dash-fm-group"><button className="dash_action_btn">Submit</button></div>
            </div>
            <div className="dash-fm-group leav_web_btn"><button className="dash_action_btn green_color_btn">My Leave Balance</button></div>
          </div>

        </div>
        <div className="col-lg-12">
          <div className="tableTopHeaderSec">
            <select onChange={(e) => setTableCount(e?.target?.value)} type="select" className="EntriesPerPage">
              <option value="4">5</option>
              <option value="15">15</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="75">75</option>
            </select>
            <div className="createUser_sec">
              <input className="tableSearch_input" onChange={((e) => setSearchText(e?.target?.value))} placeholder="Enter search..." />
              <button className="dash_action_btn apply_leave_btn">Apply Leave</button></div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="table-responsive">
            <table className="dashTable table table-bordered table-hover">
              <thead>
                <tr>
                  <th className="mw-50">S no</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>LeaveType</th>
                  <th>Reason</th>
                  <th className="mw-150">Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {
                  paginationData?.slice(0, tableCount).map((el, ind) => (
                    <tr key={ind}>
                      <td>{el?.id}</td>
                      <td>2024-07-11</td>
                      <td>2024-07-12</td>
                      <td>VACATION</td>
                      <td>test</td>
                      <td><span className="badge bg-danger">Rejected</span></td>
                      <td></td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
            <Pagination pageName="empLeavList" data={searchResult} setData={setPaginationData} perPage={tableCount} total={searchResult.length} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Content;
