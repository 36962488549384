import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import servicesData from '../servicess/serviesContent';
import { Link } from 'react-router-dom';

const OurServices = () => {
    const options = {
        items: 1,
        loop: true,
        margin: 10,
        nav: false,
        autoplay: true,
        // navText: [<span>prev</span>, <span>prev</span>],
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 2,
            },
            1000: {
                items: 3,
            },
        },
    };
  return (
    <section className="tf-space flat-features">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="features-content wow fadeInDown" data-wow-delay="0ms" data-wow-duration="1500ms">
                                <div className="section-title">
                                    <h3 className="section-heading">Our Services</h3>
                                    <div className="features-content-left">
                                        <h2>Our Business <br /> consulting services</h2>
                                    </div>
                                </div>
                                <div className="features-content-right">
                                    <p>“Expertise, Reliability & Trust”</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="swiper-container carousel">
                                <div className="swiper-wrapper home_services">
                                    <OwlCarousel className='owl-theme' {...options}>
                                        {servicesData?.map((el, ind) => (
                                            <div className="swiper-slide" key={ind}>
                                                <div className="features-box wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1000ms">
                                                    <div className="item-shape item-shape1"></div>
                                                    <div className="item-shape item-shape2"></div>
                                                    <div className="icon-features">
                                                    </div>
                                                    <div className="features-box-inner">
                                                        <h3 className="headline">{el?.title}</h3>
                                                    </div>
                                                    <div className="box-content">
                                                        <div className="content-post">
                                                            <img src={el?.image} alt="images" />
                                                            <div className="post-readmore">
                                                                <Link to={'/sub-servicess'} state={el} className="read-more"><span className="text">Read
                                                                    More</span></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}

                                    </OwlCarousel>

                                </div>
                                <div className="swiper-pagination features-bullet"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
  )
}

export default OurServices
