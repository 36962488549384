import React from 'react'
import Footer from '../common/Footer'
import Header from '../common/Header'
import SubBanner from '../common/SubBanner'

const BlogDetail = () => {
    return (
        <div>
            <Header />
            <SubBanner pageName='Blog Details' title='Blog Details' image='' />
            <section className="flat-blog blog-single">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <article className="post-content">
                                <h1 className="post-title">Committed Printing Available Text
                                    Available Market Industry.</h1>
                                <div className="post-author">
                                    <a href="blog.html" className="btn-author section-14px-regular font-weight-500">Graphics
                                        Design</a>
                                    <div className="post-author-inner">
                                        <img src="assets/images/image-box/author-post.jpg" alt="images"/>
                                            <div className="author-content link-style-2">
                                                <a href="blog.html">D. jhon shikon</a><br/>
                                                    <span>December 7, 8.00</span>
                                            </div>
                                    </div>
                                </div>
                                <p>Lorem available market standard dummy text available market industry Lorem Ipsum
                                    simply dummy text of free available market.There are many variations of passages of
                                    Lorem Ipsum available, but the majority have suffered alteration in some form,</p>
                                <p>Lorem available market standard dummy text available market industry Lorem Ipsum
                                    simply dummy text of free available market.There are many variations of passages of
                                    Lorem Ipsum available, but the majority have suffered alteration in some form, by
                                    injected humour, or</p>
                                <div className="post-media">
                                    <img src="assets/images/image-box/blog-single-post%402x.jpg" alt="images"/>
                                        <blockquote>
                                            <h4>by D. jhon shikon milon</h4>
                                            <p>Lorem Ipsum simply dummy text of free available market the typesetting
                                                industry.</p>
                                        </blockquote>
                                </div>
                                <h2 className="post-title">Committed Toproviding</h2>
                                <p>Lorem available market standard dummy text available market industry Lorem Ipsum
                                    simply dummy text of free available market.There are many variations of passages of
                                    Lorem Ipsum available, but the majority have suffered alteration in some form.</p>
                                <ul className="post-list-quotes">
                                    <li>
                                        <p>Lorem available market</p>
                                    </li>
                                    <li>
                                        <p>injected humour words which</p>
                                    </li>
                                    <li>
                                        <p>Available market</p>
                                    </li>
                                </ul>
                                <p>Lorem available market standard dummy text available market industry Lorem Ipsum
                                    simply dummy text of free available market.There are many variations of passages of
                                    Lorem Ipsum available, but the majority have suffered alteration in some form, by
                                    injected humour, or</p>
                                <div className="post-media-box">
                                    <div className="media">
                                        <img src="assets/images/image-box/blog-single-post-2%402x.jpg" alt="images"/>
                                    </div>
                                    <div className="media">
                                        <img src="assets/images/image-box/blog-single-post-3%402x.jpg" alt="images"/>
                                    </div>
                                    <div className="media">
                                        <img src="assets/images/image-box/blog-single-post-4%402x.jpg" alt="images"/>
                                    </div>
                                </div>
                                <p>Lorem available market standard dummy available market industry Lorem Ipsum simply
                                    dummy text available market.There are many variations of passages of Lorem Ipsum
                                    available, but the majority have suffered alteration in some form,</p>
                                <div className="post-tags">
                                    <div className="inner-left">
                                        <span>Tag:</span>
                                        <a href="blog.html">design</a>
                                        <a href="blog.html">ui/ux design</a>
                                        <a href="blog.html">graphics</a>
                                        <a href="blog.html">icon</a>
                                    </div>
                                    <div className="inner-right">
                                        <ul className="widget-socials link-style-2">
                                            <li><a  className="linked-in"></a></li>
                                            <li><a to="" className="twitter"></a></li>
                                            <li><a to="" className="instagram"></a></li>
                                            <li><a to="" className="facebook"></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </article>
                           
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <aside className="side-bar">

                                <div className="widget widget-category">
                                    <h3 className="widget-title section-24px-barlow">
                                        Categories
                                    </h3>
                                    <ul>
                                        <li className="cat-item active"><a href="blog.html">Graphics
                                            Design</a><span>36</span></li>
                                        <li className="cat-item"><a href="blog.html">ui/ux brand design</a><span>32</span>
                                        </li>
                                        <li className="cat-item"><a href="blog.html">web devolopment</a><span>26</span></li>
                                        <li className="cat-item"><a href="blog.html">branding design</a><span>16</span></li>
                                        <li className="cat-item"><a href="blog.html">ui/ux brand design</a><span>32</span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="widget widget-recent-post">
                                    <h3 className="widget-title section-24px-barlow">
                                        Recent Post
                                    </h3>
                                    <ul className="recent-news link-style-2">
                                        <li>
                                            <div className="thumb"><img src="assets/images/image-box/recent-post-1.jpg" alt="images"/></div>
                                            <div className="texts">
                                                <h3><a href="blog-details.html">Lorem Ipsum market the printing
                                                    typesetting.</a></h3>
                                                <span className="post-date">december 7, 2021</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="thumb"><img src="assets/images/image-box/recent-post-2.jpg" alt="images"/></div>
                                            <div className="texts">
                                                <h3><a href="blog-details.html">Lorem Ipsum market the printing
                                                    typesetting.</a></h3>
                                                <span className="post-date">december 7, 2021</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="thumb"><img src="assets/images/image-box/recent-post-3.jpg" alt="images"/></div>
                                            <div className="texts">
                                                <h3><a href="blog-details.html">Lorem Ipsum market the printing
                                                    typesetting.</a></h3>
                                                <span className="post-date">december 7, 2021</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                            </aside>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </div>
    )
}

export default BlogDetail
