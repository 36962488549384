import React from 'react';
import { Table } from 'react-bootstrap';
import { AiFillStar } from "react-icons/ai";
import { ImCheckmark } from "react-icons/im";
import avatar from '../../../../components/assert/images/management/men.png';


const All = () => {

    return (
        <div>
            <div className='attendance_section_head'>
                <div>
                    <div className="dash-fm-group attentance_field_dateMonth flex-alc add_list_employee">
                        <label className="dash-fm-label mr-4" htmlFor='year-select'>
                            Select Employee
                        </label>
                        <select className='dash-fm-input'   >
                            <option value='self'>Self</option>
                            <option value='all'>All</option>
                            <option value='subordinate'>Subordinate</option>
                        </select>
                    </div>
                </div>
            </div>
            {
                [...Array(10)]?.map(() => (
                    <div className='attendance_section_body'>
                        <div class="attendance_list_sec">
                            <div className='atten_profile'>
                                <img src={avatar} alt='' />
                                <h6>Karpagamurugan</h6>
                            </div>
                            <div class="table-responsive">
                                <Table bordered responsive>
                                    <thead>
                                        <tr>
                                            {/* <th className='w-10'>Employee</th> */}
                                            <th>1</th>
                                            <th>2</th>
                                            <th>3</th>
                                            <th>4</th>
                                            <th>5</th>
                                            <th>6</th>
                                            <th>7</th>
                                            <th>8</th>
                                            <th>9</th>
                                            <th>10</th>
                                            <th>11</th>
                                            <th>12</th>
                                            <th>13</th>
                                            <th>14</th>
                                            <th>15</th>
                                            <th>16</th>
                                            <th>17</th>
                                            <th>18</th>
                                            <th>19</th>
                                            <th>20</th>
                                            <th>21</th>
                                            <th>22</th>
                                            <th>23</th>
                                            <th>24</th>
                                            <th>25</th>
                                            <th>26</th>
                                            <th>27</th>
                                            <th>28</th>
                                            <th>29</th>
                                            <th>30</th>
                                            <th>31</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            {/* <td>
                    <button class="attendance_user_profile">
                      <div>
                        <h4>BWE Admin</h4>
                      </div>
                    </button>
                  </td> */}
                                            <td><button class="att_btn_eml"> - </button></td>
                                            <td><button class="att_btn_eml"> - </button></td>
                                            <td>
                                                <div class="d-flex">
                                                    <button class="btn-none">
                                                        <ImCheckmark className='attendance_present_icon' />
                                                    </button>
                                                </div>
                                            </td>
                                            <td><button class="att_btn_eml"> - </button></td>
                                            <td><button class="att_btn_eml"> - </button></td>
                                            <td><button class="att_btn_eml"> - </button></td>
                                            <td>
                                                <div class="attendance_holiday">
                                                    <AiFillStar className="attendance_holiday_icon" />
                                                    <div class="attendance_holiday_details">Sunday</div>
                                                </div>
                                            </td>
                                            <td><button class="att_btn_eml"> - </button></td>
                                            <td><button class="att_btn_eml"> - </button></td>
                                            <td><button class="att_btn_eml"> - </button></td>
                                            <td><button class="att_btn_eml"> - </button></td>
                                            <td><button class="att_btn_eml"> - </button></td>
                                            <td>
                                                <div class="attendance_holiday">
                                                    <AiFillStar className="attendance_holiday_icon" />
                                                    <div class="attendance_holiday_details">Saturday</div>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="attendance_holiday">
                                                    <AiFillStar className="attendance_holiday_icon" />
                                                    <div class="attendance_holiday_details">Sunday</div>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="d-flex">
                                                    <button class="btn-none">
                                                        <ImCheckmark className='attendance_present_icon' />
                                                    </button>
                                                </div>
                                            </td>
                                            <td><button class="att_btn_eml"> - </button></td>
                                            <td><button class="att_btn_eml"> - </button></td>
                                            <td><button class="att_btn_eml"> - </button></td>
                                            <td><button class="att_btn_eml"> - </button></td>
                                            <td><button class="att_btn_eml"> - </button></td>
                                            <td>
                                                <div class="attendance_holiday">
                                                    <AiFillStar className="attendance_holiday_icon" />
                                                    <div class="attendance_holiday_details">Sunday</div>
                                                </div>
                                            </td>
                                            <td><button class="att_btn_eml"> - </button></td>
                                            <td><button class="att_btn_eml"> - </button></td>
                                            <td><button class="att_btn_eml"> - </button></td>
                                            <td><button class="att_btn_eml"> - </button></td>
                                            <td><button class="att_btn_eml"> - </button></td>
                                            <td>
                                                <div class="attendance_holiday">
                                                    <AiFillStar className="attendance_holiday_icon" />
                                                    <div class="attendance_holiday_details">Saturday</div>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="attendance_holiday">
                                                    <AiFillStar className="attendance_holiday_icon" />
                                                    <div class="attendance_holiday_details">Sunday</div>
                                                </div>
                                            </td>
                                            <td><button class="att_btn_eml"> - </button></td>
                                            <td><button class="att_btn_eml"> - </button></td>
                                            <td><button class="att_btn_eml"> - </button></td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default All
