import assuranceImg from '../assert/images/services/Assurance.jpg'
import ForensicServices from '../assert/images/services/ForensicServices.jpg'
import ITConsulting from '../assert/images/services/ITConsulting.png'
import ConcurrentAudit from '../assert/images/services/assurance/ConcurrentAudit.jpg'
import ContractCompliance from '../assert/images/services/assurance/ContractCompliance.jpg'
import ControlSelfAssessment from '../assert/images/services/assurance/ControlSelfAssessment.jpeg'
import ERPAudit from '../assert/images/services/assurance/ERPAudit.jpg'
import FixedAssetsAssurance from '../assert/images/services/assurance/FixedAssetsAssurance.webp'
import FoodSafetyCompliance from '../assert/images/services/assurance/FoodSafetyCompliance.webp'
import InformationSecurityService from '../assert/images/services/assurance/InformationSecurityService.webp'
import InternalAudit from '../assert/images/services/assurance/InternalAudit.jpg'
import StockAudit from '../assert/images/services/assurance/StockAudit.jpg'
import Outsourcing from '../assert/images/services/outsourcing.jpg'

import ERP from '../assert/images/services/ITConsulting/ERP.jpg'
import EmployeeEngagementSurveys from '../assert/images/services/ITConsulting/EmployeeEngagementSurveys.jpg'
import ISOImplementation from '../assert/images/services/ITConsulting/ISOImplementation.jpg'
import OrganisationStructureRolesResponsibilities from '../assert/images/services/ITConsulting/OrganisationStructure.jpg'
import { default as PerformanceManagementSystems } from '../assert/images/services/ITConsulting/PerformanceManagementSystems.webp'
import TextileSolutions from '../assert/images/services/ITConsulting/TextileSolutions.jpg'

import BookKeeping from '../assert/images/services/outsourcing/BookKeeping.jpg'
import Payroll from '../assert/images/services/outsourcing/Payroll.jpg'
import SharedCFO from '../assert/images/services/outsourcing/SharedCFO.jpeg'

import ForensicAudit from '../assert/images/services/ForensicServices/ForensicAudit.webp'
import RevenueAssuranceAudit from '../assert/images/services/ForensicServices/RevenueAssuranceAudit.webp'
import VendorDueDiligence from '../assert/images/services/ForensicServices/VendorDueDiligence.jpg'
import WhistleBlowerProgram from '../assert/images/services/ForensicServices/WhistleBlowerProgram.webp'
import RoboticProcessAutomation from '../assert/images/services/ITConsulting/RoboticProcessAutomation.jpg'


import HRConsulting from '../assert/images/services/HRConsulting.jpg'
import VisualStories from '../assert/images/services/ITConsulting/VisualStoriesBusiness.png'
import WebsiteDevelopment from '../assert/images/services/ITConsulting/WebsiteDesignDevelopment.jpg'
import InvestmentBanking from '../assert/images/services/InvestmentBanking.webp'
import DealAdvisory from '../assert/images/services/InvestmentBanking/DealAdvisory.jpg'
import FundRasise from '../assert/images/services/InvestmentBanking/FundRasise.jpg'
import ValuationServices from '../assert/images/services/InvestmentBanking/ValuationServices.jpg'
import DraftingSOPImg from '../assert/images/services/ProcessConsulting/DraftingSOP.png'
import Subsidies from '../assert/images/services/Subsidies.jpg'
import GrantsSubsidies from '../assert/images/services/Subsidies/GrantsSubsidies.jpeg'
import ProcessConsulting from '../assert/images/services/process-consulting.webp'

const servicesData = [
    {
        title: 'Assurance',
        image: assuranceImg,
        subServices: [
            {
                title: "Internal Audit",
                image: InternalAudit,
                description: [],
                descriptionList: [
                    {
                        title: 'Risk Assessment and Management:',
                        description: `Identify and assess risks across various business processes,Develop risk management strategies to mitigate potential threats. `
                    },
                    {
                        title: 'Compliance Audits',
                        description: `Ensure adherence to regulatory requirements and internal policies, Evaluate the effectiveness of compliance programs and controls. `
                    },
                    {
                        title: 'Operational Audits',
                        description: `Analyze business operations for efficiency, effectiveness, and economy., Provide recommendations to improve operational performance.`
                    },
                    {
                        title: 'Financial Audits',
                        description: `Review financial statements and related controls to ensure accuracy and reliability. ,Verify the integrity of financial reporting and safeguard assets. `
                    },
                    {
                        title: 'IT Audits',
                        description: `Assess the security, reliability, and integrity of IT systems. , Review IT governance, risk management, and compliance with industry standards.`
                    },
                    {
                        title: 'Internal Control Reviews',
                        description: `Evaluate the effectiveness of internal controls and recommend improvements. ,Ensure that controls are aligned with organizational objectives. `
                    },
                    {
                        title: 'Process Improvement',
                        description: `Identify opportunities for process optimization. ,Provide guidance on implementing best practices and continuous improvement. `
                    },
                ],
                subServices: [
                    {
                        title: 'Benefits  ',
                        image: InternalAudit,
                        description: [
                            {
                                title: 'Enhanced Risk Management',
                                content: `Proactively identify and mitigate risks, ensuring business continuity and resilience.`
                            },
                            {
                                title: 'Regulatory Compliance',
                                content: `Maintain compliance with laws and regulations, avoiding penalties and reputational damage.`
                            },
                            {
                                title: 'Operational Efficiency',
                                content: `Streamline processes, reduce waste, and improve overall productivity.`
                            },
                            {
                                title: 'Financial Integrity',
                                content: `Ensure accurate financial reporting, protecting the organization’s financial health.`
                            },
                            {
                                title: 'IT Security and Reliability',
                                content: `Safeguard sensitive data and ensure IT systems are robust and secure.`
                            },
                            {
                                title: 'Improved Internal Controls',
                                content: `Strengthen internal controls to prevent errors and fraud, ensuring reliable operations.`
                            },
                            {
                                title: 'Continuous Improvement',
                                content: `Foster a culture of continuous improvement, driving long-term success and growth.`
                            },
                        ],
                        descriptionList: [],
                    },
                    {
                        title: 'Why Choose Us',
                        image: InternalAudit,
                        description: [],
                        descriptionList: [
                            {
                                title: 'Expertise and Experience',
                                description: ` Our team comprises seasoned auditors with extensive industry knowledge and expertise. Proven track record of delivering comprehensive and insightful audit services. `
                            },
                            {
                                title: 'Customized Solutions',
                                description: `Tailored audit strategies that align with your specific business needs and objectives.
Flexible approach to accommodate your unique challenges and opportunities. `
                            },
                            {
                                title: 'Advanced Tools and Techniques',
                                description: ` Utilize state-of-the-art audit tools and methodologies for accurate and efficient assessments.
Stay abreast of the latest industry trends and regulatory changes.`
                            },
                            {
                                title: 'Commitment to Quality',
                                description: ` Rigorous quality assurance processes to ensure the highest standards of audit integrity.
Continuous improvement of our audit practices to deliver exceptional value.`
                            },
                            {
                                title: 'Collaborative Approach',
                                description: ` Partner with your team to understand your business and provide actionable insights.
Focus on building long-term relationships based on trust and transparency.`
                            },
                            {
                                title: 'Proactive Risk Management',
                                description: ` Emphasis on identifying potential risks before they materialize.
Strategic recommendations to enhance your risk management framework.`
                            },
                            {
                                title: 'Confidentiality and Integrity',
                                description: ` Strict adherence to confidentiality and ethical standards.
Commitment to maintaining the integrity and trustworthiness of our audit services.`
                            },
                            {
                                title: 'Client-Centric Focus',
                                description: `Dedicated to understanding and meeting your unique needs and expectations.
Aim to exceed client expectations through personalized and responsive service. `
                            },
                        ],
                    },

                ]
            },
            {
                title: "Concurrent Audit",
                image: ConcurrentAudit,
                description: [],
                descriptionList: [
                    {
                        title: 'Transaction Verification',
                        description: `Real-time verification of financial and operational transactions.
Ensure transactions are recorded accurately and promptly. `
                    },
                    {
                        title: 'Internal Control Assessment',
                        description: `Evaluate the effectiveness of internal controls.
Recommend improvements to enhance control mechanisms. `
                    },
                    {
                        title: 'Compliance Monitoring',
                        description: ` Monitor adherence to regulatory requirements, internal policies, and procedures.
Identify non-compliance issues and suggest corrective actions.`
                    },
                    {
                        title: 'Risk Management',
                        description: `Identify and assess potential risks in real-time.
Implement risk mitigation strategies to prevent financial losses and operational disruptions. `
                    },
                    {
                        title: 'Process Review',
                        description: `Continuously review and analyze business processes for efficiency and effectiveness.
Recommend process improvements to enhance productivity. `
                    },
                    {
                        title: 'Financial Review',
                        description: ` Conduct ongoing reviews of financial statements and records.
Ensure financial accuracy and integrity.`
                    },
                    {
                        title: 'Fraud Detection and Prevention',
                        description: `Monitor for signs of fraud and irregularities in real-time.
Implement measures to prevent and detect fraudulent activities. `
                    },
                    {
                        title: 'Reporting and Feedback',
                        description: ` Provide regular, timely reports on audit findings and recommendations.
Offer actionable feedback to management for continuous improvement.`
                    },
                ],
                subServices: [
                    {
                        title: 'Benefits  ',
                        image: InternalAudit,
                        description: [
                            {
                                title: 'Timely Detection and Correction',
                                content: `Identify and address issues as they arise, minimizing potential impacts.`
                            },
                            {
                                title: 'Enhanced Internal Controls',
                                content: `Strengthen internal controls through continuous monitoring and assessment.`
                            },
                            {
                                title: 'Compliance Assurance',
                                content: `Ensure ongoing compliance with regulatory requirements and internal policies.`
                            },
                            {
                                title: 'Risk Mitigation',
                                content: `Proactively manage risks, reducing the likelihood of financial losses and operational disruptions.`
                            },
                            {
                                title: 'Improved Efficiency',
                                content: `Optimize business processes and improve operational efficiency.`
                            },
                            {
                                title: 'Financial Accuracy',
                                content: `Maintain accurate and reliable financial records through continuous review.`
                            },
                            {
                                title: 'Fraud Prevention',
                                content: `Detect and prevent fraudulent activities in real-time, safeguarding assets.`
                            },
                            {
                                title: 'Informed Decision-Making',
                                content: `Provide management with timely and relevant information for better decision-making.`
                            },
                        ],
                        descriptionList: [],
                    },
                    {
                        title: 'Why Choose Us',
                        image: InternalAudit,
                        description: [],
                        descriptionList: [
                            {
                                title: 'Experienced Auditors',
                                description: `Team of skilled auditors with extensive experience in concurrent auditing and real-time monitoring. `
                            },
                            {
                                title: 'Tailored Solutions',
                                description: ` Customized audit services to meet the specific needs and complexities of your business.`
                            },
                            {
                                title: 'Proactive Approach',
                                description: `Emphasis on real-time detection and correction of issues, ensuring minimal impact on operations. `
                            },
                            {
                                title: 'Advanced Technology',
                                description: `Utilize cutting-edge audit tools and technology for efficient and effective auditing. `
                            },
                            {
                                title: 'Commitment to Quality',
                                description: ` Adherence to the highest standards of audit quality and integrity.`
                            },
                            {
                                title: 'Comprehensive Coverage',
                                description: `Holistic approach covering all aspects of your business operations and financial transactions. `
                            },
                            {
                                title: 'Confidentiality and Integrity',
                                description: `Strict adherence to confidentiality and ethical standards in all engagements. `
                            },
                            {
                                title: 'Client-Centric Focus',
                                description: ` Personalized service with a commitment to understanding and meeting your specific needs.`
                            },
                        ],
                    },

                ]
            },
            {
                title: "Control Self Assessment",
                image: ControlSelfAssessment,
                description: [
                    //    {
                    //         title: '',
                    //         content: ``
                    //     },
                ],
                descriptionList: [
                    //    {
                    //         title: '',
                    //         description: ``
                    //     }, 

                ],
                subServices: [
                    {
                        title: 'Benefits  ',
                        image: InternalAudit,
                        description: [],
                        descriptionList: [
                            //    {
                            //         title: '',
                            //         description: ` `
                            //     },

                        ],
                    },
                    {
                        title: 'Why Choose Us',
                        image: InternalAudit,
                        description: [],
                        descriptionList: [
                            // {
                            //     title: '',
                            //     description: ` `
                            // },
                        ],
                    },

                ]
            },
            {
                title: "ERP Audit",
                image: ERPAudit,
                description: [],
                descriptionList: [
                    {
                        title: 'System Configuration Review',
                        description: `Assess the configuration of your ERP system to ensure it aligns with business processes and requirements.
Verify that system settings are optimized for performance and compliance. `
                    },
                    {
                        title: 'Data Integrity and Quality Check',
                        description: `Evaluate the accuracy, completeness, and reliability of data within the ERP system.
Identify and rectify data inconsistencies and errors. `
                    },
                    {
                        title: 'Access Controls and Security',
                        description: `Review user access controls to ensure appropriate levels of access and segregation of duties.
Assess the security measures in place to protect sensitive data from unauthorized access and breaches. `
                    },
                    {
                        title: 'Process and Workflow Analysis',
                        description: `Examine the efficiency and effectiveness of business processes and workflows managed by the ERP system.
Identify bottlenecks and recommend process improvements. `
                    },
                    {
                        title: 'Compliance and Regulatory Review',
                        description: `Ensure that the ERP system complies with relevant regulatory requirements and industry standards.
Verify adherence to internal policies and procedures. `
                    },
                    {
                        title: 'System Integration Assessment',
                        description: `Review the integration of the ERP system with other business applications and systems.
Ensure seamless data flow and interoperability between systems. `
                    },
                    {
                        title: 'Performance and Scalability Evaluation',
                        description: ` Assess the performance of the ERP system, including speed, responsiveness, and reliability.
Evaluate the system’s ability to scale with business growth and changing needs.`
                    },
                    {
                        title: 'Disaster Recovery and Business Continuity',
                        description: `Review disaster recovery plans and business continuity measures for the ERP system.
Ensure readiness to handle disruptions and data loss. `
                    },
                    {
                        title: 'User Training and Support',
                        description: `Evaluate the effectiveness of user training programs and support mechanisms.
Identify gaps in user knowledge and recommend training improvements.`
                    },
                    {
                        title: 'Reporting and Analytics',
                        description: `Assess the quality and usefulness of reports and analytics generated by the ERP system.
Ensure that reporting capabilities meet business requirements for decision-making. `
                    },
                ],
                subServices: [
                    {
                        title: 'Benefits  ',
                        image: InternalAudit,
                        description: [
                            {
                                title: ' Improved System Performance',
                                content: `Optimize ERP system performance for better efficiency and user experience. `,
                            },
                            {
                                title: 'Enhanced Data Accuracy ',
                                content: `Ensure data integrity and quality, leading to more reliable business insights. `,
                            },
                            {
                                title: ' Strengthened Security',
                                content: `Protect sensitive data with robust access controls and security measures. `,
                            },
                            {
                                title: ' Regulatory Compliance',
                                content: ` Maintain compliance with industry regulations and standards, avoiding penalties.`,
                            },
                            {
                                title: ' Process Optimization',
                                content: `Streamline business processes and workflows for greater efficiency and productivity. `,
                            },
                            {
                                title: ' Seamless Integration',
                                content: ` Ensure smooth integration with other business systems for better data flow and interoperability.`,
                            },
                            {
                                title: ' Scalability and Flexibility',
                                content: `Prepare your ERP system to scale with business growth and adapt to changing needs. `,
                            },
                            {
                                title: ' Risk Mitigation',
                                content: `Identify and address potential risks related to system configuration, data integrity, and security. `,
                            },
                            {
                                title: 'Business Continuity ',
                                content: `Ensure readiness to handle disruptions and maintain business operations during emergencies. `,
                            },
                            {
                                title: 'Informed Decision-Making ',
                                content: `Enhance decision-making with accurate data and effective reporting capabilities.`,
                            },
                        ],
                        descriptionList: [],
                    },
                    {
                        title: 'Why Choose Us',
                        image: InternalAudit,
                        description: [],
                        descriptionList: [
                            {
                                title: 'Experienced Professionals',
                                description: `Team of experts with extensive experience in ERP systems and auditing.
In-depth knowledge of various ERP platforms and industry best practices. `
                            },
                            {
                                title: 'Comprehensive Approach',
                                description: `Holistic audit covering all aspects of your ERP system, from configuration to user training. `
                            },
                            {
                                title: 'Customized Solutions',
                                description: `Tailored audit services to meet the specific needs and complexities of your business. `
                            },
                            {
                                title: 'Advanced Tools and Techniques',
                                description: `Utilize cutting-edge audit tools and methodologies for accurate and efficient assessments. `
                            },
                            {
                                title: 'Commitment to Quality',
                                description: `Rigorous quality assurance processes to ensure the highest standards of audit integrity. `
                            },
                            {
                                title: 'Proactive Recommendations',
                                description: `Practical and actionable recommendations to enhance your ERP system’s performance and security. `
                            },
                            {
                                title: 'Confidentiality and Integrity',
                                description: `Strict adherence to confidentiality and ethical standards in all engagements.`
                            },
                            {
                                title: 'Client-Centric Focus',
                                description: ` Personalized service with a commitment to understanding and meeting your specific needs.`
                            },
                        ],
                    },

                ]
            },
            {
                title: "Stock Audit",
                image: StockAudit,
                description: [],
                descriptionList: [
                    {
                        title: 'Physical Inventory Verification',
                        description: `Conduct comprehensive physical counts of inventory.
Compare physical counts with recorded inventory data. `
                    },
                    {
                        title: 'Inventory Valuation',
                        description: `Assess the value of inventory based on cost and market value.
Identify discrepancies between book value and actual value. `
                    },
                    {
                        title: 'Stock Movement Analysis',
                        description: ` Analyze stock movement to identify patterns and irregularities.
Review stock turnover rates and identify slow-moving or obsolete inventory.`
                    },
                    {
                        title: 'Internal Controls Assessment',
                        description: `Evaluate the effectiveness of internal controls over inventory management.
Recommend improvements to strengthen controls. `
                    },
                    {
                        title: 'Documentation and Record Keeping',
                        description: `Verify accuracy and completeness of inventory records and documentation.
Ensure compliance with regulatory and accounting standards. `
                    },
                    {
                        title: 'Discrepancy Resolution',
                        description: ` Identify and investigate discrepancies between physical and recorded inventory.
Provide actionable recommendations to address and resolve issues.`
                    },
                    {
                        title: 'Compliance Audits',
                        description: `Ensure adherence to internal policies and external regulations regarding inventory management.
Verify compliance with industry standards and best practices. `
                    },
                    {
                        title: 'Reporting and Recommendations',
                        description: ` Provide detailed audit reports highlighting findings and recommendations.
Offer insights for optimizing inventory management processes.`
                    },
                ],
                subServices: [
                    {
                        title: 'Benefits  ',
                        image: InternalAudit,
                        description: [
                            {
                                title: 'Accurate Inventory Records',
                                content: `Ensure accuracy of inventory records, reducing errors and discrepancies.`
                            },
                            {
                                title: 'Enhanced Inventory Management',
                                content: `Improve inventory control and management practices.`
                            },
                            {
                                title: 'Cost Savings    ',
                                content: `Identify and eliminate excess or obsolete inventory, reducing holding costs.`
                            },
                            {
                                title: 'Operational Efficiency',
                                content: `Streamline inventory processes, improving overall efficiency.`
                            },
                            {
                                title: 'Risk Mitigation',
                                content: `Minimize the risk of theft, fraud, and stockouts through effective controls.`
                            },
                            {
                                title: 'Compliance Assurance',
                                content: `Ensure compliance with regulatory requirements and internal policies.`
                            },
                            {
                                title: 'Informed Decision-Making',
                                content: `Provide accurate inventory data to support strategic business decisions.`
                            },
                            {
                                title: 'Improved Financial Reporting',
                                content: `Enhance the accuracy of financial statements through reliable inventory valuation.`
                            },
                        ],
                        descriptionList: [],
                    },
                    {
                        title: 'Why Choose Us',
                        image: InternalAudit,
                        description: [],
                        descriptionList: [
                            {
                                title: 'Experienced Auditors',
                                description: `Team of skilled auditors with extensive experience in inventory management and auditing. `
                            },
                            {
                                title: 'Customized Approach',
                                description: `Tailored audit services to meet the unique needs of your business. `
                            },
                            {
                                title: 'Advanced Techniques',
                                description: `Utilize the latest audit methodologies and technology for accurate results. `
                            },
                            {
                                title: 'Commitment to Quality',
                                description: `Adherence to the highest standards of audit quality and integrity. `
                            },
                            {
                                title: 'Thorough Analysis',
                                description: ` Detailed analysis and comprehensive reports providing valuable insights`
                            },
                            {
                                title: 'Proactive Recommendations',
                                description: ` Practical and actionable recommendations to improve inventory management.`
                            },
                            {
                                title: 'Confidentiality and Trust',
                                description: `Strict confidentiality and ethical standards in all audit engagements. `
                            },
                            {
                                title: 'Client-Centric Focus',
                                description: `Personalized service with a commitment to understanding and meeting your specific needs. `
                            },
                        ],
                    },

                ]
            },
            {
                title: "Fixed Assets Assurance",
                image: FixedAssetsAssurance,
                description: [],
                descriptionList: [
                    {
                        title: 'Asset Verification',
                        description: `Conduct physical verification of fixed assets to ensure they exist and are in the stated location.
Match physical assets with recorded assets in the fixed asset register. `
                    },
                    {
                        title: 'Asset Valuation',
                        description: ` Assess the value of fixed assets based on current market value and depreciation.
Ensure accurate reflection of asset values in financial statements.`
                    },
                    {
                        title: 'Depreciation Analysis',
                        description: `Review and analyze depreciation schedules and methods.
Ensure appropriate and consistent application of depreciation policies. `
                    },
                    {
                        title: 'Asset Tagging and Labeling',
                        description: `Implement or review asset tagging systems for easy identification and tracking.
Ensure all assets are properly labeled and recorded. `
                    },
                    {
                        title: 'Policy and Procedure Review',
                        description: ` Evaluate existing fixed asset management policies and procedures.
Recommend improvements to strengthen asset management controls.`
                    },
                    {
                        title: 'Compliance Audits',
                        description: `Ensure compliance with relevant accounting standards and regulatory requirements.
Verify adherence to internal policies for fixed asset management. `
                    },
                    {
                        title: 'Disposal and Retirement Review',
                        description: `Review processes for asset disposal and retirement.
Ensure proper documentation and accounting for asset disposals. `
                    },
                    {
                        title: 'Reporting and Recommendations',
                        description: ` Provide detailed audit reports with findings and actionable recommendations.
Offer insights for optimizing fixed asset management processes.`
                    },
                ],
                subServices: [
                    {
                        title: 'Benefits  ',
                        image: InternalAudit,
                        description: [
                            {
                                title: 'Accurate Asset Records',
                                content: `Maintain accurate records of fixed assets, reducing errors and discrepancies.`
                            },
                            {
                                title: 'Improved Asset Utilization',
                                content: `Optimize the use of fixed assets, enhancing operational efficiency.`
                            },
                            {
                                title: 'Cost Savings',
                                content: `Identify underutilized or obsolete assets, reducing unnecessary holding costs.`
                            },
                            {
                                title: 'Enhanced Financial Reporting',
                                content: `Ensure accurate valuation and reporting of fixed assets in financial statements.`
                            },
                            {
                                title: 'Risk Mitigation',
                                content: `Minimize risks related to asset misappropriation, loss, and fraud.`
                            },
                            {
                                title: 'Regulatory Compliance',
                                content: `Ensure compliance with accounting standards and regulatory requirements.`
                            },
                            {
                                title: 'Informed Decision-Making',
                                content: `Provide reliable data to support strategic decisions related to asset management.`
                            },
                            {
                                title: 'Operational Efficiency',
                                content: `Streamline fixed asset management processes, improving overall efficiency.`
                            },
                        ],
                        descriptionList: [],
                    },
                    {
                        title: 'Why Choose Us',
                        image: InternalAudit,
                        description: [],
                        descriptionList: [
                            {
                                title: 'Experienced Professionals',
                                description: `Team of skilled auditors with extensive experience in fixed asset assurance and management. `
                            },
                            {
                                title: 'Tailored Solutions',
                                description: `Customized audit services to meet the unique needs of your business. `
                            },
                            {
                                title: 'Advanced Methodologies',
                                description: ` Use of cutting-edge audit techniques and technology for accurate and efficient assessments.`
                            },
                            {
                                title: 'Commitment to Quality',
                                description: `Adherence to the highest standards of audit quality and integrity.`
                            },
                            {
                                title: 'Comprehensive Analysis',
                                description: ` Detailed analysis and comprehensive reports providing valuable insights.`
                            },
                            {
                                title: 'Proactive Recommendations',
                                description: ` Practical and actionable recommendations to improve fixed asset management.`
                            },
                            {
                                title: 'Confidentiality and Integrity',
                                description: `Strict adherence to confidentiality and ethical standards in all engagements. `
                            },
                            {
                                title: 'Client-Centric Focus',
                                description: `Personalized service with a commitment to understanding and meeting your specific needs.`
                            },
                        ],
                    },

                ]
            },
            {
                title: "Contract Compliance",
                image: ContractCompliance,
                description: [],
                descriptionList: [
                    {
                        title: 'Contract Review and Analysis',
                        description: `Detailed examination of contract terms and conditions.
Verify adherence to agreed-upon contractual obligations. `
                    },
                    {
                        title: 'Financial Compliance',
                        description: `Assess financial transactions to ensure they align with contract terms.
Verify accuracy of payments, invoicing, and financial reporting. `
                    },
                    {
                        title: 'Performance Evaluation',
                        description: `Evaluate the performance of all parties against contractual requirements.
Identify deviations from agreed performance metrics and standards.   `
                    },
                    {
                        title: 'Risk Assessment',
                        description: `Identify and assess potential risks associated with contract execution.
Recommend strategies to mitigate identified risks. `
                    },
                    {
                        title: 'Regulatory and Legal Compliance',
                        description: `Ensure compliance with relevant laws, regulations, and industry standards.
Verify that contractual practices adhere to legal requirements. `
                    },
                    {
                        title: 'Documentation and Record Keeping',
                        description: `Review and assess the completeness and accuracy of contract-related documentation.
Ensure proper record-keeping practices for accountability and traceability. `
                    },
                    {
                        title: 'Dispute Resolution and Issue Identification',
                        description: ` Identify areas of non-compliance and potential disputes.
Provide recommendations for resolving identified issues.`
                    },
                    {
                        title: 'Reporting and Recommendations',
                        description: `Deliver comprehensive audit reports outlining findings and compliance status.
Offer actionable recommendations to improve contract management and compliance. `
                    },
                ],
                subServices: [
                    {
                        title: 'Benefits  ',
                        image: InternalAudit,
                        description: [
                            {
                                title: 'Enhanced Compliance',
                                content: `Ensure adherence to contract terms, reducing the risk of breaches and disputes.`
                            },
                            {
                                title: 'Financial Accuracy',
                                content: `Verify financial transactions, preventing overpayments, underpayments, and financial discrepancies.`
                            },
                            {
                                title: 'Performance Improvement',
                                content: `Monitor and improve performance against contractual obligations.`
                            },
                            {
                                title: 'Risk Mitigation',
                                content: `Identify and address potential risks, ensuring smoother contract execution.`
                            },
                            {
                                title: 'Regulatory Adherence',
                                content: `Ensure compliance with legal and regulatory requirements, avoiding penalties and legal issues.`
                            },
                            {
                                title: 'Cost Savings',
                                content: `Prevent financial losses due to non-compliance, overpayments, and inefficiencies.`
                            },
                            {
                                title: 'Improved Documentation',
                                content: `Maintain accurate and complete contract records for accountability and transparency.`
                            },
                            {
                                title: 'Dispute Prevention',
                                content: `Identify and resolve issues early, reducing the likelihood of disputes and litigation.`
                            },
                        ],
                        descriptionList: [],
                    },
                    {
                        title: 'Why Choose Us',
                        image: InternalAudit,
                        description: [],
                        descriptionList: [
                            {
                                title: 'Experienced Auditors',
                                description: ` Team of skilled auditors with extensive experience in contract compliance and management.`
                            },
                            {
                                title: 'Customized Solutions',
                                description: ` Tailored audit services to meet the specific needs and complexities of your contracts.`
                            },
                            {
                                title: 'Comprehensive Approach',
                                description: ` Holistic evaluation covering all aspects of contract compliance, from financial accuracy to performance assessment.`
                            },
                            {
                                title: 'Advanced Techniques',
                                description: `Use of cutting-edge audit methodologies and technology for precise and efficient assessments. `
                            },
                            {
                                title: 'Commitment to Quality',
                                description: ` Adherence to the highest standards of audit quality and integrity.`
                            },
                            {
                                title: 'Proactive Recommendations',
                                description: `Practical and actionable recommendations to enhance contract compliance and management.`
                            },
                            {
                                title: 'Confidentiality and Integrity',
                                description: `Strict adherence to confidentiality and ethical standards in all engagements. `
                            },
                            {
                                title: 'Client-Centric Focus',
                                description: `Personalized service with a commitment to understanding and meeting your specific needs. `
                            },
                        ],
                    },

                ]
            },
            {
                title: "Food Safety Compliance",
                image: FoodSafetyCompliance,
                description: [],
                descriptionList: [
                    {
                        title: 'Regulatory Compliance Assessment',
                        description: `Evaluate adherence to local, national, and international food safety regulations.
Ensure compliance with standards such as FSSAI, ISO 22000, and other relevant regulations. `
                    },
                    {
                        title: 'Facility and Equipment Inspection',
                        description: `Conduct thorough inspections of food processing, storage, and handling facilities.
Assess the condition and cleanliness of equipment and premises. `
                    },
                    {
                        title: 'Process and Procedure Review',
                        description: `Review food safety processes and procedures from raw material sourcing to final product distribution.
Identify potential hazards and critical control points (CCPs). `
                    },
                    {
                        title: 'Documentation and Record Keeping',
                        description: `Evaluate the accuracy and completeness of food safety documentation.
Ensure proper record-keeping practices for traceability and accountability. `
                    },
                    {
                        title: 'Employee Training and Hygiene',
                        description: `Assess the effectiveness of employee training programs on food safety practices.
Review personal hygiene practices and compliance with food safety protocols. `
                    },
                    {
                        title: 'Pest Control Management',
                        description: `Examine pest control measures and their effectiveness.
Ensure compliance with pest management standards. `
                    },
                    {
                        title: 'Product Testing and Analysis',
                        description: `Conduct microbiological, chemical, and physical testing of food products.
Verify compliance with food safety standards and specifications. `
                    },
                    {
                        title: 'Reporting and Recommendations',
                        description: `Provide detailed audit reports highlighting findings and non-compliance areas.
Offer actionable recommendations to address deficiencies and improve food safety practices. `
                    },
                ],
                subServices: [
                    {
                        title: 'Benefits  ',
                        image: InternalAudit,
                        description: [
                            {
                                title: 'Regulatory Compliance',
                                content: `Ensure compliance with food safety regulations, avoiding penalties and legal issues.`
                            },
                            {
                                title: 'Enhanced Food Safety',
                                content: `Improve food safety practices, reducing the risk of contamination and foodborne illnesses.`
                            },
                            {
                                title: 'Consumer Trust',
                                content: `Build consumer confidence in your products by demonstrating a commitment to food safety.  `
                            },
                            {
                                title: 'Operational Efficiency',
                                content: `Streamline food safety processes, improving overall efficiency and productivity.`
                            },
                            {
                                title: 'Risk Mitigation',
                                content: `Identify and address potential food safety hazards before they become critical issues.`
                            },
                            {
                                title: 'Improved Quality Control',
                                content: `Enhance the quality and consistency of food products through rigorous safety measures.`
                            },
                            {
                                title: 'Cost Savings',
                                content: `Reduce costs associated with food recalls, waste, and non-compliance penalties.`
                            },
                            {
                                title: 'Market Access',
                                content: `Ensure compliance with international standards, facilitating access to global markets.`
                            },
                        ],
                        descriptionList: [],
                    },
                    {
                        title: 'Why Choose Us',
                        image: InternalAudit,
                        description: [],
                        descriptionList: [
                            {
                                title: 'Expert Auditors',
                                description: `Team of experienced auditors with extensive knowledge of food safety regulations and standards. `
                            },
                            {
                                title: 'Tailored Solutions',
                                description: ` Customized audit services to meet the unique needs of your business and industry.`
                            },
                            {
                                title: 'Comprehensive Approach',
                                description: ` Holistic evaluation of all aspects of food safety, from facility inspection to process review.`
                            },
                            {
                                title: 'Advanced Techniques',
                                description: ` Use of state-of-the-art audit methodologies and technology for accurate and efficient assessments.`
                            },
                            {
                                title: 'Commitment to Quality',
                                description: ` Rigorous quality control processes to ensure the highest standards of audit integrity.`
                            },
                            {
                                title: 'Proactive Recommendations',
                                description: `Practical and actionable recommendations to enhance food safety practices. `
                            },
                            {
                                title: 'Confidentiality and Integrity',
                                description: `Strict adherence to confidentiality and ethical standards in all engagements. `
                            },
                            {
                                title: 'Client-Centric Focus',
                                description: `Personalized service with a commitment to understanding and meeting your specific needs. `
                            },
                        ],
                    },

                ]
            },
            {
                title: "Information Security Service",
                image: InformationSecurityService,
                description: [
                    {
                        title: 'Comprehensive Risk Assessment',
                        content: ` `,
                        descriptionList: [
                            {
                                title: 'Identification of Vulnerabilities ',
                                description: ` Conduct thorough assessments to identify potential security vulnerabilities in your systems and networks.`,
                            },
                            {
                                title: 'Threat Analysis ',
                                description: ` Analyze current and emerging threats that could impact your organization.`,
                            },
                        ],
                    },
                    {
                        title: ' Policy and Procedure Review',
                        content: ` `,
                        descriptionList: [
                            {
                                title: 'Evaluation of Security Policies ',
                                description: `Review existing security policies and procedures to ensure they are up-to-date and effective. `,
                            },
                            {
                                title: ' Compliance Check',
                                description: `Ensure compliance with relevant regulations and standards (e.g., GDPR, HIPAA, ISO 27001). `,
                            },
                        ],
                    },
                    {
                        title: 'Technical Security Testing',
                        content: ` `,
                        descriptionList: [
                            {
                                title: 'Penetration Testing ',
                                description: `Simulate cyber-attacks to test the defenses of your systems. `,
                            },
                            {
                                title: 'Vulnerability Scanning ',
                                description: `Use advanced tools to scan for known vulnerabilities in your infrastructure. `,
                            },
                        ],
                    },
                    {
                        title: 'Access Control Assessment',
                        content: ` `,
                        descriptionList: [
                            {
                                title: 'User Access Review ',
                                description: ` Examine user access controls to ensure that only authorized personnel have access to sensitive information.`,
                            },
                            {
                                title: 'Privilege Management ',
                                description: `Assess how privileges are assigned and managed within your organization. `,
                            },
                        ],
                    },
                    {
                        title: 'Network Security Evaluation',
                        content: ` `,
                        descriptionList: [
                            {
                                title: 'Firewall and Intrusion Detection ',
                                description: `  Evaluate the effectiveness of firewalls, intrusion detection, and prevention systems.`,
                            },
                            {
                                title: 'Network Architecture Review ',
                                description: ` Analyze network design and configuration for security weaknesses. `,
                            },
                        ],
                    },
                    {
                        title: 'Physical Security Review',
                        content: ` `,
                        descriptionList: [
                            {
                                title: 'Facility Security ',
                                description: ` Assess the physical security measures in place at your facilities.`,
                            },
                            {
                                title: 'Data Center Security ',
                                description: `Evaluate the security of data centers and other critical infrastructure. `,
                            },
                        ],
                    },
                    {
                        title: ' Incident Response Preparedness',
                        content: ` `,
                        descriptionList: [
                            {
                                title: ' Incident Response Plan Review',
                                description: ` Assess the effectiveness of your incident response plan. `,
                            },
                            {
                                title: ' Simulation and Training',
                                description: ` Conduct incident response simulations and training for your staff.`,
                            },
                        ],
                    },
                    {
                        title: 'Reporting and Recommendations',
                        content: ` `,
                        descriptionList: [
                            {
                                title: 'Detailed Findings Report ',
                                description: `Provide a comprehensive report detailing findings, risks, and vulnerabilities. `,
                            },
                            {
                                title: 'Actionable Recommendations ',
                                description: `Offer practical recommendations to mitigate identified risks and improve overall security posture. `,
                            },
                        ],
                    },

                ],
                descriptionList: [],
                subServices: [

                    {
                        title: 'Why Choose Us',
                        image: InternalAudit,
                        description: [],
                        descriptionList: [
                            {
                                title: 'Experienced Professionals',
                                description: `Our team consists of certified information security experts with years of experience in conducting comprehensive security audits across various industries. `
                            },
                            {
                                title: 'Customized Approach',
                                description: ` We understand that every organization is unique, and we tailor our audit processes to meet the specific needs and challenges of your business.`
                            },
                            {
                                title: 'State-of-the-Art Tools',
                                description: `We utilize the latest tools and technologies to perform thorough and accurate assessments, ensuring no vulnerabilities are overlooked. `
                            },
                            {
                                title: 'Proven Track Record',
                                description: `Our clients trust us for our proven track record in enhancing their security postures and ensuring compliance with industry standards.     `
                            },
                            {
                                title: 'Continuous Support',
                                description: `Beyond the audit, we offer continuous support and guidance to help you implement our recommendations and maintain a robust security framework. `
                            },
                            {
                                title: 'Confidentiality and Integrity',
                                description: ` We prioritize your privacy and ensure that all findings and recommendations are handled with the highest level of confidentiality and integrity.`
                            },

                        ],
                    },

                ]
            }]
    },
    {
        title: 'IT Consulting',
        image: ITConsulting,
        subServices: [
            {
                title: "ERP Consulting",
                image: ERP,
                description: [
                    {
                        title: 'Service Overview',
                        content: `Our ERP (Enterprise Resource Planning) consulting services are designed to help businesses streamline their operations, improve efficiency, and achieve their strategic goals. We offer comprehensive ERP solutions tailored to meet the unique needs of each client, ensuring seamless integration and optimal performance. `,
                        descriptionList: [
                            {
                                title: 'ERP Needs Assessment and Strategy Development ',
                                description: ` Conduct thorough analysis of your current systems and processes.
Identify gaps and areas for improvement.
Develop a customized ERP strategy aligned with your business objectives.`,
                            },
                            {
                                title: ' ERP Selection and Implementation',
                                description: `Assist in selecting the most suitable ERP solution for your business.
Plan and execute the implementation process, including data migration, system configuration, and integration.
Provide project management to ensure timely and budget-friendly implementation. `,
                            },
                            {
                                title: 'Customization and Integration',
                                description: `Customize ERP modules to meet your specific business requirements.
Integrate ERP with existing systems and third-party applications to ensure a cohesive IT environment. `,
                            },
                            {
                                title: 'Training and Support ',
                                description: `Offer comprehensive training programs for your staff to ensure efficient use of the new ERP system.
Provide ongoing support and maintenance to address any issues and keep your system running smoothly. `,
                            },
                            {
                                title: ' Performance Optimization and Upgrades',
                                description: `Continuously monitor and optimize ERP system performance.
Manage system upgrades and enhancements to keep your ERP solution up-to-date with the latest features and technologies. `,
                            },
                        ],
                    },
                ],
                descriptionList: [],
                subServices: [
                    {
                        title: 'Benefits',
                        image: InternalAudit,
                        description: [
                            {
                                title: 'Improved Efficiency',
                                content: `Streamline business processes and reduce manual efforts.
Enhance data accuracy and accessibility across departments.`
                            },
                            {
                                title: 'Cost Savings',
                                content: `Reduce operational costs by eliminating redundant systems and processes.
Minimize downtime and errors with a robust and reliable ERP system.`
                            },
                            {
                                title: 'Better Decision-Making',
                                content: `Gain real-time insights into business performance with comprehensive reporting and analytics.
Make informed decisions with accurate and up-to-date information.`
                            },
                            {
                                title: 'Scalability and Flexibility',
                                content: `Adapt to changing business needs with scalable ERP solutions.
Easily integrate new modules and functionalities as your business grows.`
                            },
                            {
                                title: 'Enhanced Collaboration',
                                content: `Improve communication and collaboration across departments with a unified system.
Foster a culture of transparency and accountability within the organization.`
                            },
                        ],
                        descriptionList: [],
                    },
                    {
                        title: 'Why Choose Us',
                        image: InternalAudit,
                        description: [],
                        descriptionList: [
                            {
                                title: 'Expertise and Experience',
                                description: `Our team comprises seasoned ERP consultants with extensive industry experience.
Proven track record of successful ERP implementations across various sectors. `
                            },
                            {
                                title: 'Customized Solutions',
                                description: `We understand that each business is unique, and we tailor our solutions to meet your specific needs.
Flexible and scalable ERP solutions that grow with your business. `
                            },
                            {
                                title: 'Customer-Centric Approach',
                                description: ` We prioritize our clients' success and work closely with them to achieve their goals.
Dedicated support and ongoing assistance to ensure long-term success.`
                            },
                            {
                                title: 'Cutting-Edge Technology',
                                description: `Leverage the latest ERP technologies and best practices to deliver high-quality solutions.
Continuous innovation to keep your business ahead of the curve. `
                            },
                            {
                                title: 'Comprehensive Service Offering',
                                description: `End-to-end ERP consulting services from needs assessment to post-implementation support.
One-stop-shop for all your ERP needs, ensuring a seamless and hassle-free experience `
                            },
                        ],
                    },

                ]
            },
            {
                title: "ISO 27001 (ISMS) implementation",
                image: ISOImplementation,
                description: [
                    {
                        title: 'Service Overview',
                        content: ` Our Information Security Management System (ISMS) implementation services are designed to help organizations protect their information assets, comply with regulatory requirements, and mitigate risks. We provide a comprehensive approach to developing, implementing, and maintaining an ISMS based on ISO/IEC 27001 standards, ensuring your business is secure and resilient.`,
                        descriptionList: [
                            {
                                title: 'ISMS Assessment and Gap Analysis ',
                                description: `Conduct a thorough assessment of your current information security practices.
Identify gaps and areas of non-compliance with ISO/IEC 27001 standards.
Provide a detailed report with recommendations for improvement. `,
                            },
                            {
                                title: 'ISMS Policy and Procedure Development ',
                                description: ` Develop tailored information security policies and procedures to meet your organization’s specific needs.
Ensure alignment with ISO/IEC 27001 requirements and best practices.`,
                            },
                            {
                                title: ' Risk Assessment and Management',
                                description: `Identify and assess information security risks.
Develop and implement risk treatment plans to mitigate identified risks.
Establish a risk management framework to continually monitor and address new risks. `,
                            },
                            {
                                title: ' ISMS Implementation and Integration',
                                description: ` Plan and execute the implementation of your ISMS.
Integrate ISMS with existing processes and systems to ensure a seamless transition.
Provide project management to ensure timely and effective implementation.`,
                            },
                            {
                                title: 'Training and Awareness Programs ',
                                description: `Develop and deliver comprehensive training programs to educate your staff on ISMS policies and procedures.
Foster a culture of security awareness within your organization. `,
                            },
                            {
                                title: 'Internal Audit and Compliance Support ',
                                description: `Conduct internal audits to ensure compliance with ISO/IEC 27001 standards.
Provide support during external audits and certification processes.
Develop and implement corrective actions to address audit findings. `,
                            },
                            {
                                title: 'Continuous Improvement and Maintenance ',
                                description: `Establish processes for continuous monitoring and improvement of your ISMS.
Provide ongoing support and maintenance to ensure sustained compliance and effectiveness. `,
                            },
                        ],
                    },
                ],
                descriptionList: [],
                subServices: [
                    {
                        title: 'Benefits',
                        image: InternalAudit,
                        description: [
                            {
                                title: 'Enhanced Security Posture',
                                content: `Protect your information assets from threats and vulnerabilities.
Implement robust security controls to safeguard sensitive data.`
                            },
                            {
                                title: 'Regulatory Compliance',
                                content: `Achieve compliance with ISO/IEC 27001 standards and other relevant regulations.
Avoid fines and penalties associated with non-compliance.`
                            },
                            {
                                title: 'Risk Mitigation',
                                content: `Identify and address potential security risks before they become critical issues.
Reduce the likelihood and impact of security incidents.`
                            },
                            {
                                title: 'Improved Business Continuity',
                                content: `Ensure business continuity and resilience in the face of security threats.
Develop and implement incident response and recovery plans.`
                            },
                            {
                                title: 'Increased Trust and Reputation',
                                content: `Demonstrate your commitment to information security to customers, partners, and stakeholders.
Enhance your organization’s reputation and competitive advantage.`
                            },
                        ],
                        descriptionList: [],
                    },
                    {
                        title: 'Why Choose Us',
                        image: InternalAudit,
                        description: [],
                        descriptionList: [
                            {
                                title: 'Expertise and Experience',
                                description: ` Our team comprises certified ISMS consultants with extensive experience in information security.
Proven track record of successful ISMS implementations across various industries.`
                            },
                            {
                                title: 'Customized Solutions',
                                description: `We tailor our ISMS implementation services to meet your organization’s unique needs and challenges.
Flexible and scalable solutions that grow with your business. `
                            },
                            {
                                title: 'Customer-Centric Approach',
                                description: `We prioritize our clients’ success and work closely with them to achieve their security objectives.
Dedicated support and ongoing assistance to ensure long-term success. `
                            },
                            {
                                title: 'Cutting-Edge Practices',
                                description: `Leverage the latest information security standards, technologies, and best practices.
Continuous innovation to keep your organization secure and compliant. `
                            },
                            {
                                title: 'Comprehensive Service Offering',
                                description: ` End-to-end ISMS implementation services from assessment to post-implementation support.
One-stop-shop for all your information security needs, ensuring a seamless and hassle-free experience.`
                            },
                        ],
                    },

                ]
            },
            {
                title: "Robotic Process Automation (RPA)",
                image: RoboticProcessAutomation,
                description: [
                    {
                        title: 'Service Overview',
                        content: ` Our Robotic Process Automation (RPA) consulting services are designed to help businesses automate repetitive, time-consuming tasks, thereby improving efficiency, reducing costs, and freeing up valuable resources for more strategic activities. We offer comprehensive RPA solutions tailored to meet the unique needs of each client, ensuring seamless integration and optimal performance.`,
                        descriptionList: [
                            {
                                title: 'RPA Readiness Assessment ',
                                description: `Evaluate your current processes and identify opportunities for automation.
Assess the readiness of your organization for RPA implementation.
Provide a detailed report with recommendations and a roadmap for RPA adoption. `,
                            },
                            {
                                title: 'Process Identification and Selection ',
                                description: ` Identify and prioritize business processes that are suitable for automation.
Develop a business case for RPA, including potential benefits and ROI.`,
                            },
                            {
                                title: ' RPA Strategy and Roadmap Development',
                                description: ` Develop a customized RPA strategy aligned with your business goals.
Create a detailed implementation roadmap, outlining key milestones and timelines.`,
                            },
                            {
                                title: 'RPA Tool Selection and Proof of Concept ',
                                description: `Assist in selecting the most suitable RPA tools and technologies for your business.
Conduct proof of concept (POC) projects to validate the feasibility and benefits of RPA. `,
                            },
                            {
                                title: 'RPA Implementation and Integration ',
                                description: `Plan and execute the implementation of RPA solutions, including bot development, testing, and deployment.
Integrate RPA with existing systems and applications to ensure seamless operation. `,
                            },
                            {
                                title: ' Training and Change Management',
                                description: `Develop and deliver training programs to educate your staff on RPA tools and processes.
Manage change within your organization to ensure smooth adoption and acceptance of RPA. `,
                            },
                            {
                                title: 'Monitoring, Optimization, and Support ',
                                description: ` Continuously monitor the performance of RPA bots and optimize them for efficiency.
Provide ongoing support and maintenance to address any issues and ensure optimal performance.`,
                            },
                        ],
                    },
                ],
                descriptionList: [],
                subServices: [
                    {
                        title: 'Benefits',
                        image: InternalAudit,
                        description: [
                            {
                                title: 'Increased Efficiency',
                                content: `Automate repetitive tasks to free up human resources for more strategic activities.
Accelerate process execution and reduce turnaround times.`
                            },
                            {
                                title: 'Cost Savings',
                                content: `Reduce operational costs by minimizing manual efforts and errors.
Achieve significant ROI through improved productivity and efficiency.`
                            },
                            {
                                title: 'Enhanced Accuracy',
                                content: `Eliminate human errors by automating data entry and other repetitive tasks.
Ensure consistent and accurate process execution.`
                            },
                            {
                                title: 'Scalability and Flexibility',
                                content: `Easily scale RPA solutions to handle increased workloads and new processes.
Adapt to changing business needs with flexible and configurable bots.`
                            },
                            {
                                title: 'Improved Compliance',
                                content: `Ensure adherence to regulatory requirements with standardized and auditable processes.
Reduce the risk of non-compliance and associated penalties.`
                            },
                        ],
                        descriptionList: [],
                    },
                    {
                        title: 'Why Choose Us',
                        image: InternalAudit,
                        description: [],
                        descriptionList: [
                            {
                                title: 'Expertise and Experience',
                                description: `Our team comprises seasoned RPA consultants with extensive industry experience.
Proven track record of successful RPA implementations across various sectors. `
                            },
                            {
                                title: 'Customized Solutions',
                                description: `We understand that each business is unique, and we tailor our RPA solutions to meet your specific needs.
Flexible and scalable RPA solutions that grow with your business. `
                            },
                            {
                                title: 'Customer-Centric Approach',
                                description: ` We prioritize our clients' success and work closely with them to achieve their automation goals.
Dedicated support and ongoing assistance to ensure long-term success.`
                            },
                            {
                                title: 'Cutting-Edge Technology',
                                description: ` Leverage the latest RPA tools and best practices to deliver high-quality solutions.
Continuous innovation to keep your business ahead of the curve.`
                            },
                            {
                                title: 'Comprehensive Service Offering',
                                description: `End-to-end RPA consulting services from readiness assessment to post-implementation support.
One-stop-shop for all your RPA needs, ensuring a seamless and hassle-free experience. `
                            },
                        ],
                    },

                ]
            },
            {
                title: "Website Design & Development",
                image: WebsiteDevelopment,
                description: [
                    {
                        title: '',
                        content: `In today's digital age, a professional, user-friendly website is essential for any business looking to thrive online. At Astral Consulting Limited, we specialize in creating custom websites that not only look stunning but also deliver exceptional user experiences. Our comprehensive web development services are designed to meet the unique needs of your business, helping you to stand out in a crowded marketplace.`
                    },
                    {
                        title: '',
                        content: `Our team of experienced designers and developers work closely with you to create a website that reflects your brand and meets your business goals. Whether you need a simple landing page or a complex e-commerce platform, we’ve got you covered.`
                    }
                ],
                descriptionList: [
                    {
                        title: 'Responsive Design',
                        description: `Ensuring your website looks great on all devices`
                    },
                    {
                        title: 'User Experience (UX) Optimization',
                        description: `Creating intuitive and engaging user journeys`
                    },
                    {
                        title: 'SEO-Friendly',
                        description: `Building websites that are optimized for search engines from the`
                    },
                ],
                subServices: [
                    {
                        title: 'Benefits  ',
                        image: InternalAudit,
                        description: [],
                        descriptionList: [

                            {
                                title: 'Enhanced Online Presence',
                                description: `A professionally developed website increases your visibility, making it easier for potential customers to find and learn about your business online.`
                            },
                            {
                                title: 'Improved User Experience',
                                description: `Custom website design ensures a user-friendly interface and seamless navigation, leading to higher user satisfaction and retention rates.`
                            },
                            {
                                title: 'Increased Credibility and Trust',
                                description: `A well-designed website conveys professionalism and reliability, enhancing your brand’s credibility and building trust with your audience.`
                            },
                            {
                                title: 'Greater Accessibility and Reach',
                                description: `Websites provide 24/7 access to your business information and services, reaching a global audience and expanding your market reach.`
                            },
                            {
                                title: 'Effective Marketing and Sales Tool',
                                description: `Websites integrate with various digital marketing strategies, such as SEO, social media, and email campaigns, to drive traffic and boost sales.`
                            },
                            {
                                title: 'Scalability and Flexibility',
                                description: `Websites can be easily updated and scaled to accommodate business growth, allowing you to add new features, services, or products as needed.`
                            },
                            {
                                title: '',
                                description: `These benefits demonstrate how Benefits  can play a crucial role in the growth and success of your business in the digital landscape.`
                            },


                        ],
                    },
                    {
                        title: 'Why Choose Us',
                        image: InternalAudit,
                        description: [
                            {
                                title: 'Experienced Team',
                                content: `Our developers have years of experience and a track record of successful projects.`
                            },
                            {
                                title: 'Customer-Centric Approach',
                                content: `We prioritize your needs and work closely with you to deliver the best results.`
                            },
                            {
                                title: 'Transparent Process',
                                content: `Clear communication and regular updates throughout the development process.`
                            },
                            {
                                title: 'Affordable Pricing',
                                content: `Competitive rates without compromising on quality`
                            },
                        ],
                        descriptionList: [],
                    },

                ]
            },
            {
                title: "Visual Stories for Your Business",
                image: VisualStories,
                description: [
                    {
                        title: '',
                        content: `At Astral Consulting Limited, we specialize in producing high-quality corporate films that capture the essence of your brand and convey your message with impact. Whether you need a corporate video, promotional film, training video, our experienced team is dedicated to bringing your vision to life through engaging and professional storytelling.`
                    },
                    {
                        title: 'Corporate Videos',
                        content: `Enhance your corporate identity with visually stunning videos that highlight your company’s values, culture, and achievements. Perfect for company overviews, executive messages, and client testimonials.`,
                        descriptionList: [
                            {
                                title: 'Company Profiles',
                                description: `Showcasing your mission, vision, and achievements.`,
                            },
                            {
                                title: 'Executive Messages',
                                description: `Communicating directly from your leadership.`,
                            },
                            {
                                title: 'Client Testimonials',
                                description: `Building trust through customer success stories.`,
                            },
                        ],
                    },
                    {
                        title: 'Promotional Films',
                        content: `Drive your marketing campaigns with compelling promotional films that captivate your audience and drive engagement. Ideal for product launches, brand promotions, and marketing campaigns.`,
                        descriptionList: [
                            {
                                title: 'Product Launch Videos',
                                description: `Creating buzz around new products.`,
                            },
                            {
                                title: 'Brand Promotion',
                                description: `Enhancing brand awareness and loyalty.`,
                            },
                            {
                                title: 'Marketing Campaigns',
                                description: `Engaging content for online and offline use.`,
                            },
                        ],
                    },
                    {
                        title: 'Training & Educational Videos',
                        content: `Deliver clear, effective training and educational content to your employees or customers. Our training videos are designed to simplify complex information and improve learning outcomes.`,
                        descriptionList: [
                            {
                                title: 'Employee Training',
                                description: `Standardizing onboarding and skill development.`,
                            },
                            {
                                title: 'Customer Tutorials',
                                description: `Helping customers get the most out of your products.`,
                            },
                            {
                                title: 'Educational Content',
                                description: `Simplifying complex topics for easy understanding.`,
                            },
                        ],
                    },
                ],
                descriptionList: [],
                subServices: [
                    {
                        title: 'Benefits  ',
                        image: InternalAudit,
                        description: [],
                        descriptionList: [
                            {
                                title: 'Enhanced Brand Awareness and Recognition',
                                description: `Corporate videos effectively showcase your brand’s personality, values, and offerings, helping to increase brand awareness and recognition among your target audience. `
                            },
                            {
                                title: 'Improved Communication and Engagement',
                                description: ` Videos convey messages more effectively than text alone, capturing and maintaining viewer attention while delivering information in a compelling and engaging manner.`
                            },
                            {
                                title: 'Increased Conversion Rates and Sales',
                                description: ` Engaging videos can influence purchasing decisions by effectively showcasing products or services, leading to higher conversion rates and increased sales.`
                            },
                            {
                                title: 'Versatility and Accessibility',
                                description: ` Corporate videos can be used across various platforms and channels, including websites, social media, email marketing, and presentations, maximizing their reach and impact.`
                            },
                            {
                                title: 'Demonstration of Company Culture and Values',
                                description: `Videos can provide an inside look into your company culture, highlighting your values, workplace environment, and team dynamics, which can resonate with both customers and potential employees. `
                            },
                            {
                                title: 'Cost-Effective Marketing Tool',
                                description: ` Compared to traditional advertising methods, corporate videos offer a high return on investment (ROI) by effectively delivering your message to a wide audience and generating long-term value.`
                            },
                        ],
                    },
                    {
                        title: 'Why Choose Us',
                        image: InternalAudit,
                        description: [
                            {
                                title: 'Experienced Team',
                                content: ` Our talented filmmakers have a proven track record in producing impactful corporate films.`
                            },
                            {
                                title: 'Custom Solutions',
                                content: ` We tailor our services to meet your specific needs and objectives.`
                            },
                            {
                                title: 'High Production Value',
                                content: `Utilizing state-of-the-art equipment and techniques for superior quality. `
                            },
                            {
                                title: 'Client-Centric Approach',
                                content: `We prioritize your vision and ensure clear communication throughout the project. `
                            },
                            {
                                title: 'Timely Delivery',
                                content: ` Meeting deadlines without compromising on quality.`
                            },
                        ],
                        descriptionList: [],
                    },

                ]
            },
        ]
    },
    {
        title: 'HR Consulting',
        image: HRConsulting,
        subServices: [
            {
                title: "Defining Roles & Responsibilities",
                image: OrganisationStructureRolesResponsibilities,
                description: [
                    {
                        title: 'Clarify Your Team’s Roles for Greater Efficiency and Success',
                        content: `A well-defined structure within your organization is crucial for ensuring efficiency, accountability, and productivity. At Astral Consulting Limited, we specialize in helping businesses clearly define roles and responsibilities, fostering a harmonious and effective working environment. Our comprehensive services ensure that each team member understands their duties and how they contribute to the overall success of the organization. `
                    },
                    {
                        title: 'Role Definition',
                        content: `Clearly defining each role within your organization helps eliminate confusion and overlap, ensuring that every team member knows exactly what is expected of them. `,
                        descriptionList: [
                            {
                                title: 'Job Descriptions ',
                                description: `Detailed and precise descriptions for each position. `,
                            },
                            {
                                title: 'Role Clarity ',
                                description: `Ensuring every employee understands their specific duties and responsibilities. `,
                            },
                            {
                                title: ' Competency Frameworks',
                                description: ` Defining the skills and attributes required for each role.`,
                            },
                        ],
                    },
                    {
                        title: 'Responsibility Assignment',
                        content: `Assigning specific responsibilities to the appropriate roles enhances accountability and efficiency, making sure tasks are completed by the right people.`,
                        descriptionList: [
                            {
                                title: ' Responsibility Mapping',
                                description: ` Identifying and allocating key responsibilities.`,
                            },
                            {
                                title: 'Task Allocation ',
                                description: ` Ensuring tasks are distributed based on skills and capabilities.`,
                            },
                            {
                                title: 'Accountability Structures ',
                                description: ` Establishing clear lines of accountability.`,
                            },
                        ],
                    },
                    {
                        title: 'Organizational Structure Design',
                        content: `Creating a clear organizational structure helps streamline operations and improves communication across different levels of the organization. `,
                        descriptionList: [
                            {
                                title: ' Hierarchy Development',
                                description: ` Designing an effective reporting structure.`,
                            },
                            {
                                title: 'Team Structuring ',
                                description: `Organizing teams based on function, project, or geography. `,
                            },
                            {
                                title: 'Workflow Optimization ',
                                description: `Streamlining processes to enhance productivity. `,
                            },
                        ],
                    },
                    {
                        title: 'Performance Metrics',
                        content: ` Defining clear performance metrics ensures that each role has measurable objectives, helping to track progress and identify areas for improvement.`,
                        descriptionList: [
                            {
                                title: ' Key Performance Indicators (KPIs)',
                                description: `Establishing relevant and measurable KPIs for each role. `,
                            },
                            {
                                title: 'Performance Reviews ',
                                description: `Setting up regular review processes to assess performance. `,
                            },
                            {
                                title: 'Continuous Improvement ',
                                description: ` Identifying areas for development and growth. `,
                            },
                        ],
                    },
                ],
                descriptionList: [],
                subServices: [
                    {
                        title: 'Benefits  ',
                        image: InternalAudit,
                        description: [
                            {
                                title: 'Clarity and Alignment',
                                content: `Clearly defined roles ensure that everyone in the organization understands their responsibilities and how they contribute to the overall goals, reducing confusion and aligning efforts.`
                            },
                            {
                                title: 'Improved Efficiency and Productivity',
                                content: `Defined roles streamline workflows and processes, minimizing duplication of effort and improving task prioritization, which leads to increased efficiency and productivity.`
                            },
                            {
                                title: 'Enhanced Accountability',
                                content: `Assigning specific responsibilities increases accountability among employees, as they know what is expected of them and are more likely to take ownership of their tasks and outcomes.`
                            },
                            {
                                title: 'Better Team Collaboration',
                                content: `Clear roles foster better collaboration by establishing clear expectations and reducing conflicts over responsibilities, allowing teams to work together more effectively towards common objectives.`
                            },
                            {
                                title: 'Supports Employee Development',
                                content: `Role clarity enables targeted training and development opportunities, helping employees to acquire and enhance the skills necessary to perform their roles effectively.`
                            },
                            {
                                title: 'Scalability and Adaptability',
                                content: `Defined roles facilitate organizational scalability by providing a structured framework that can accommodate growth and change, ensuring that roles evolve with the organization’s needs.`
                            },
                        ],
                    },
                    {
                        title: 'Why Choose Us',
                        image: InternalAudit,
                        descriptionList: [
                            {
                                title: 'Experienced Consultants',
                                description: `Our team has extensive experience in organizational development and human resources. `
                            },
                            {
                                title: 'Customized Solutions',
                                description: ` Tailored approaches to meet the unique needs of your business.`
                            },
                            {
                                title: 'Proven Methodologies',
                                description: `Utilizing best practices and proven frameworks for effective role and responsibility definition. `
                            },
                            {
                                title: 'Employee Engagement',
                                description: ` Ensuring buy-in and engagement from all levels of the organization.`
                            },
                            {
                                title: 'Sustainable Results',
                                description: `Focusing on long-term success and continuous improvement.`
                            },
                        ],
                    },

                ]
            },
            {
                title: "Employee Engagement Surveys, 360 Surveys",
                image: EmployeeEngagementSurveys,
                description: [
                    {
                        title: 'Empower Your Workforce with Insights into Engagement',
                        content: `Understanding your employees’ engagement levels is crucial for fostering a productive, satisfied, and committed workforce. At Astral Consulting Limited, we offer comprehensive employee engagement survey services designed to provide actionable insights that can drive positive change and enhance your workplace culture.`
                    },
                    {
                        title: 'Customizable Surveys',
                        content: ` We design surveys tailored to your organization’s unique needs, ensuring you gather relevant and meaningful data.`,
                        descriptionList: [
                            {
                                title: 'Tailored Questions ',
                                description: `Customized to address specific areas of interest or concern `,
                            },
                            {
                                title: 'Multiple Formats',
                                description: `Online, mobile, and paper-based options available. `,
                            },
                            {
                                title: 'Confidential & Anonymous ',
                                description: `Ensuring honest and candid feedback from employees. `,
                            },
                        ],
                    },
                    {
                        title: ' Comprehensive Analysis',
                        content: ` Our detailed analysis provides deep insights into employee engagement levels, helping you identify strengths and areas for improvement.`,
                        descriptionList: [
                            {
                                title: 'Data Collection & Processing ',
                                description: `Efficient handling of survey responses to ensure accuracy. `,
                            },
                            {
                                title: 'Statistical Analysis ',
                                description: ` Using advanced techniques to uncover trends and patterns.`,
                            },
                            {
                                title: 'Benchmarking ',
                                description: ` Comparing your results with industry standards and best practices.`,
                            },
                        ],
                    },
                    {
                        title: 'Actionable Insights',
                        content: `We transform survey data into actionable insights, providing you with clear recommendations for enhancing employee engagement. `,
                        descriptionList: [
                            {
                                title: ' Detailed Reports',
                                description: `Comprehensive reports with easy-to-understand visuals and key findings. `,
                            },
                            {
                                title: ' Executive Summaries',
                                description: ` High-level overviews for senior leadership.`,
                            },
                            {
                                title: 'Action Plans ',
                                description: `Practical recommendations and strategies to address identified issues. `,
                            },
                        ],
                    },
                    {
                        title: 'Continuous Improvement',
                        content: ` Engagement is an ongoing journey. Our services support continuous improvement by helping you implement changes and track progress over time.`,
                        descriptionList: [
                            {
                                title: ' Follow-Up Surveys',
                                description: `Regularly scheduled surveys to monitor progress. `,
                            },
                            {
                                title: 'Pulse Surveys ',
                                description: ` Short, frequent surveys to capture real-time feedback.`,
                            },
                            {
                                title: 'Consultation & Support ',
                                description: ` Ongoing support to help you implement and sustain engagement initiatives.`,
                            },
                        ],
                    },
                ],
                descriptionList: [],
                subServices: [
                    {
                        title: 'Benefits  ',
                        image: InternalAudit,
                        description: [
                            {
                                title: 'Enhanced Productivity',
                                content: `Engaged employees are more productive, motivated, and committed to their work.`
                            },
                            {
                                title: 'Reduced Turnover',
                                content: `Understanding and addressing engagement issues can lead to lower employee turnover.`
                            },
                            {
                                title: 'Improved Morale',
                                content: ` Fostering a positive work environment boosts employee morale and satisfaction.`
                            },
                            {
                                title: 'Better Communication',
                                content: ` Surveys provide a platform for employees to voice their opinions and concerns.`
                            },
                            {
                                title: 'Informed Decision-Making',
                                content: `Data-driven insights help you make informed decisions about HR and management practices.`
                            },
                        ],
                        descriptionList: [],
                    },
                    {
                        title: 'Why Choose Us',
                        image: InternalAudit,
                        description: [],
                        descriptionList: [
                            {
                                title: 'Expertise',
                                description: `Our team has extensive experience in designing and analyzing employee engagement surveys. `
                            },
                            {
                                title: 'Customization',
                                description: `We tailor our surveys and services to meet the specific needs of your organization. `
                            },
                            {
                                title: 'Confidentiality',
                                description: `We ensure the anonymity and confidentiality of all survey responses to promote honest feedback `
                            },
                            {
                                title: 'Action Oriented',
                                description: `We focus on providing actionable insights that can drive meaningful improvements. `
                            },
                            {
                                title: 'Support',
                                description: `From survey design to action planning, we support you every step of the way. `
                            },
                        ],
                    },

                ]
            },

        ]
    },
    {
        title: 'Process Consulting',
        image: ProcessConsulting,
        subServices: [
            {
                title: "Performance Management Systems",
                image: PerformanceManagementSystems,
                description: [
                    {
                        title: 'Service Overview',
                        content: `Our Performance Management System (PMS) services are designed to help organizations enhance their employee performance, align individual goals with organizational objectives, and foster a culture of continuous improvement. We offer comprehensive solutions tailored to meet the unique needs of each client, ensuring effective performance management and optimal workforce productivity. `,
                        descriptionList: [
                            {
                                title: ' PMS Assessment and Gap Analysis',
                                description: `Conduct a thorough assessment of your current performance management practices.
Identify gaps and areas for improvement in your existing PMS.
Provide a detailed report with recommendations and a roadmap for enhancement. `,
                            },
                            {
                                title: 'PMS Strategy and Framework Development ',
                                description: `Develop a customized performance management strategy aligned with your business goals.
Design a robust PMS framework, including performance metrics, KPIs, and evaluation criteria. `,
                            },
                            {
                                title: ' Goal Setting and Alignment',
                                description: `Assist in setting clear, measurable, and achievable goals for employees at all levels.
Ensure alignment of individual goals with organizational objectives to drive overall performance. `,
                            },
                            {
                                title: 'Performance Appraisal and Feedback Systems ',
                                description: ` Design and implement effective performance appraisal systems, including 360-degree feedback mechanisms.
Develop processes for regular, constructive feedback and performance reviews.`,
                            },
                            {
                                title: 'Training and Development Programs ',
                                description: ` Create tailored training and development plans to address skill gaps and enhance employee competencies.
Provide coaching and mentoring programs to support employee growth and career progression.`,
                            },
                            {
                                title: 'Performance Monitoring and Reporting ',
                                description: `Implement tools and systems for continuous monitoring of employee performance.
Develop comprehensive reporting mechanisms to track progress and identify areas for improvement. `,
                            },
                            {
                                title: 'Reward and Recognition Programs ',
                                description: `Design and implement reward and recognition programs to motivate and retain top talent.
Establish fair and transparent processes for performance-based incentives and promotions. `,
                            },
                        ],
                    },
                ],
                descriptionList: [],
                subServices: [
                    {
                        title: 'Benefits',
                        image: InternalAudit,
                        description: [
                            {
                                title: 'Improved Employee Performance',
                                content: `Enhance individual and team performance through clear goals and regular feedback.
Foster a culture of continuous improvement and accountability.`
                            },
                            {
                                title: 'Increased Employee Engagement',
                                content: `Boost employee morale and motivation with effective performance management practices.
Promote a positive work environment where employees feel valued and recognized.`
                            },
                            {
                                title: 'Better Alignment with Organizational Goals',
                                content: `Ensure that individual efforts contribute to the overall success of the organization.
Drive organizational performance through aligned and focused efforts.`
                            },
                            {
                                title: 'Enhanced Skill Development',
                                content: `Identify skill gaps and provide targeted training and development opportunities.
Support employee growth and career advancement within the organization.`
                            },
                            {
                                title: 'Data-Driven Decision Making',
                                content: `Leverage performance data to make informed decisions about talent management.
Identify high performers and areas needing improvement with accurate performance metrics.`
                            },

                        ],
                        descriptionList: [],
                    },
                    {
                        title: 'Why Choose Us',
                        image: InternalAudit,
                        description: [],
                        descriptionList: [
                            {
                                title: 'Expertise and Experience',
                                description: ` Our team comprises seasoned PMS consultants with extensive experience in performance management.
Proven track record of successful PMS implementations across various industries.`
                            },
                            {
                                title: 'Customized Solutions',
                                description: `We understand that each organization is unique, and we tailor our PMS solutions to meet your specific needs.
Flexible and scalable PMS solutions that grow with your business. `
                            },
                            {
                                title: 'Customer-Centric Approach',
                                description: `We prioritize our clients' success and work closely with them to achieve their performance management goals.
Dedicated support and ongoing assistance to ensure long-term success. `
                            },
                            {
                                title: 'Cutting-Edge Practices',
                                description: `Leverage the latest performance management tools, technologies, and best practices.
Continuous innovation to keep your organization ahead of the curve. `
                            },
                            {
                                title: 'Comprehensive Service Offering',
                                description: ` End-to-end PMS consulting services from assessment to post-implementation support.
One-stop-shop for all your performance management needs, ensuring a seamless and hassle-free experience.`
                            },
                        ],
                    },

                ]
            },
            {
                title: "Drafting SOP",
                image: DraftingSOPImg,
                description: [
                    {
                        title: 'Overview',
                        content: ` Standard Operating Procedures (SOPs) are essential for ensuring consistency, efficiency, and compliance within any organization. Our SOP Drafting Services provide meticulously crafted procedures tailored to your unique business processes, helping you maintain high standards, improve operational efficiency, and adhere to regulatory requirements.`
                    },
                    {
                        title: 'Custom SOP Development',
                        content: ` `,
                        descriptionList: [
                            {
                                title: 'Service ',
                                description: `We work closely with your team to understand your specific processes and requirements, drafting customized SOPs that reflect your operational needs and standards. `,
                            },
                            {
                                title: 'Benefits ',
                                description: ` Enhanced clarity and consistency in daily operations.
Tailored procedures that fit your unique business environment.
Improved training and onboarding for new employees.`,
                            },
                        ],
                    },
                    {
                        title: 'Compliance and Regulatory SOPs',
                        content: ` `,
                        descriptionList: [
                            {
                                title: 'Service ',
                                description: ` We ensure your SOPs comply with industry regulations and standards, helping you avoid legal issues and fines. `,
                            },
                            {
                                title: ' Benefits',
                                description: `Assurance of compliance with all relevant regulations.
Reduced risk of legal and financial penalties.
Improved reputation and trust with stakeholders. `,
                            },
                        ],
                    },
                    {
                        title: 'Process Optimization and Efficiency',
                        content: ` `,
                        descriptionList: [
                            {
                                title: 'Service ',
                                description: `Our experts analyze your existing procedures and identify areas for improvement, creating SOPs that enhance efficiency and productivity. `,
                            },
                            {
                                title: 'Benefits ',
                                description: `Streamlined operations and reduced waste.
Increased productivity and cost savings.
Continuous improvement in operational performance. `,
                            },
                        ],
                    },
                    {
                        title: 'Documentation and Training Materials',
                        content: ` `,
                        descriptionList: [
                            {
                                title: 'Service ',
                                description: `We provide comprehensive documentation and training materials to ensure your team understands and adheres to the new SOPs. `,
                            },
                            {
                                title: 'Benefits',
                                description: ` Easy-to-understand guides for employees.
Enhanced employee performance and adherence to standards.
Smoother implementation of new procedures.`,
                            },
                        ],
                    },
                ],
                descriptionList: [],
                subServices: [
                    // {
                    //     title: 'Benefits',
                    //     image: InternalAudit,
                    //     description: [
                    //         {
                    //             title: '',
                    //             content: ``
                    //         },
                    //     ],
                    //     descriptionList: [
                    //         {
                    //             title: ' ',
                    //             description: ` `,
                    //         },
                    //     ],
                    // },
                    {
                        title: 'Why Choose Us',
                        image: InternalAudit,
                        description: [],
                        descriptionList: [
                            {
                                title: 'Expertise',
                                description: `  Our team comprises seasoned professionals with extensive experience in drafting and implementing SOPs across various industries.`
                            },
                            {
                                title: 'Customized Solutions',
                                description: ` We offer tailored SOPs that address your specific business needs and challenges, ensuring relevance and effectiveness.`
                            },
                            {
                                title: 'Comprehensive Approach',
                                description: `We take a holistic approach, considering all aspects of your operations to create detailed and practical SOPs `
                            },
                            {
                                title: 'Proven Track Record',
                                description: ` Our successful track record in helping organizations improve their operations through effective SOPs underscores our reliability and expertise.`
                            },
                            {
                                title: 'Regulatory Knowledge',
                                description: `We stay updated with industry regulations and standards, ensuring your SOPs are compliant and up to date. `
                            },
                        ],
                    },

                ]
            },
            {
                title: "Textile Solutions",
                image: TextileSolutions,
                description: [
                    //    {
                    //         title: '',
                    //         content: ``
                    //     },
                ],
                descriptionList: [
                    //    {
                    //         title: '',
                    //         description: ``
                    //     }, 

                ],
                subServices: [
                    {
                        title: 'Benefits  ',
                        image: InternalAudit,
                        description: [],
                        descriptionList: [
                            //    {
                            //         title: '',
                            //         description: ` `
                            //     },

                        ],
                    },
                    {
                        title: 'Why Choose Us',
                        image: InternalAudit,
                        description: [],
                        descriptionList: [
                            // {
                            //     title: '',
                            //     description: ` `
                            // },
                        ],
                    },

                ]
            },

        ]
    },
    {
        title: 'Forensic Services',
        image: ForensicServices,
        subServices: [
            {
                title: "Forensic Audit",
                image: ForensicAudit,
                description: [
                    {
                        title: 'Service Overview',
                        content: `Our Forensic Audit Services are designed to help businesses uncover and address financial discrepancies, fraud, and other irregularities. We provide comprehensive forensic auditing solutions to ensure the integrity of your financial processes, identify any fraudulent activities, and support legal proceedings if necessary. `,
                        descriptionList: [
                            {
                                title: 'Fraud Investigation ',
                                description: `Conduct thorough investigations to identify and analyze fraudulent activities.
Use advanced forensic techniques to gather evidence and trace financial discrepancies.
Interview key personnel and stakeholders to gather relevant information. `,
                            },
                            {
                                title: 'Financial Statement Analysis ',
                                description: `Examine financial statements for signs of irregularities, misstatements, or manipulation.
Identify and assess the impact of any discrepancies on the financial health of the organization. `,
                            },
                            {
                                title: 'Litigation Support ',
                                description: `Provide expert testimony and support during legal proceedings.
Prepare comprehensive reports and documentation to support legal cases.
Collaborate with legal teams to develop effective litigation strategies. `,
                            },
                            {
                                title: 'Internal Control Review ',
                                description: `Assess the effectiveness of your organization’s internal controls.
Identify weaknesses and recommend improvements to prevent future fraud or irregularities. `,
                            },
                            {
                                title: 'Digital Forensics ',
                                description: `Utilize digital forensic techniques to recover and analyze electronic evidence.
Investigate cybercrimes, data breaches, and unauthorized access to financial systems. `,
                            },
                            {
                                title: ' Compliance and Regulatory Investigation',
                                description: `Ensure compliance with relevant laws, regulations, and industry standards.
Conduct investigations into potential regulatory violations and non-compliance issues. `,
                            },

                        ],
                    },
                ],
                descriptionList: [],
                subServices: [
                    {
                        title: 'Benefits',
                        image: InternalAudit,
                        description: [
                            {
                                title: 'Fraud Detection and Prevention',
                                content: `Identify and address fraudulent activities and financial discrepancies.
Implement measures to prevent future fraud and protect your organization’s assets.`
                            },
                            {
                                title: 'Legal Support',
                                content: `Provide robust evidence and expert testimony to support legal proceedings.
Enhance your organization’s ability to pursue legal action and recover losses.`
                            },
                            {
                                title: 'Enhanced Financial Integrity',
                                content: `Ensure the accuracy and reliability of your financial statements.
Maintain stakeholder confidence and trust in your financial reporting.`
                            },
                            {
                                title: 'Improved Internal Controls',
                                content: `Strengthen your internal controls to prevent fraud and irregularities.
Reduce the risk of financial mismanagement and regulatory violations.`
                            },
                            {
                                title: 'Comprehensive Insights',
                                content: `Gain a detailed understanding of the nature and extent of financial discrepancies.
Make informed decisions based on thorough forensic analysis and reporting.`
                            },
                        ],
                        descriptionList: [],
                    },
                    {
                        title: 'Why Choose Us',
                        image: InternalAudit,
                        description: [],
                        descriptionList: [
                            {
                                title: 'Expertise and Experience',
                                description: `Our team comprises certified forensic auditors and fraud examiners with extensive experience.
Proven track record of successful forensic audits and fraud investigations across various industries. `
                            },
                            {
                                title: 'Customized Solutions',
                                description: `We understand that each case is unique, and we tailor our forensic audit services to meet your specific needs.
Flexible and scalable solutions to address complex and varied forensic audit requirements. `
                            },
                            {
                                title: 'Customer-Centric Approach',
                                description: `We prioritize our clients' success and work closely with them to achieve their forensic audit objectives.
Dedicated support and ongoing assistance to ensure comprehensive and effective investigations. `
                            },
                            {
                                title: 'Cutting-Edge Techniques',
                                description: `Utilize the latest forensic audit tools, technologies, and methodologies for accurate and efficient investigations.
Continuous innovation to stay ahead of emerging fraud trends and techniques. `
                            },
                            {
                                title: 'Comprehensive Service Offering',
                                description: `End-to-end forensic audit services from investigation to litigation support.
One-stop-shop for all your forensic audit needs, ensuring a seamless and thorough experience. `
                            },
                        ],
                    },

                ]

            },
            {
                title: "Vendor Due Diligence",
                image: VendorDueDiligence,
                description: [
                    {
                        title: 'Service Overview',
                        content: ` Our Vendor Due Diligence Services are designed to help businesses evaluate and manage risks associated with third-party vendors. We offer comprehensive due diligence solutions to ensure that your vendors meet your standards for quality, compliance, and reliability, thereby safeguarding your business operations and reputation.`,
                        descriptionList: [
                            {
                                title: 'Vendor Risk Assessment ',
                                description: `Conduct thorough assessments of potential and existing vendors to identify risks.
Evaluate financial stability, operational capabilities, and compliance with industry standards. `,
                            },
                            {
                                title: ' Compliance and Regulatory Review',
                                description: `Ensure that vendors comply with relevant laws, regulations, and industry standards.
Conduct checks for compliance with anti-bribery, anti-corruption, and other regulatory requirements. `,
                            },
                            {
                                title: 'Background Checks and Investigations ',
                                description: `Perform comprehensive background checks on vendors and key personnel.
Investigate any legal, financial, or reputational issues associated with the vendor. `,
                            },
                            {
                                title: ' Contract and SLA Review',
                                description: `Review vendor contracts and service level agreements (SLAs) to ensure they align with your business requirements.
Identify and mitigate potential risks and gaps in contractual agreements. `,
                            },
                            {
                                title: ' Financial Analysis',
                                description: `Analyze the financial health of vendors to assess their long-term viability.
Evaluate financial statements, credit reports, and other financial indicators. `,
                            },
                            {
                                title: ' Operational and IT Security Assessment',
                                description: ` Assess the vendor’s operational processes and IT security measures.
Ensure that vendors have robust cybersecurity practices and data protection measures in place.`,
                            },
                            {
                                title: 'Ongoing Monitoring and Reporting ',
                                description: ` Continuously monitor vendor performance and compliance.
Provide regular reports and updates on vendor risk status and any emerging issues.`,
                            },
                        ],
                    },
                ],
                descriptionList: [],
                subServices: [
                    {
                        title: 'Benefits',
                        image: InternalAudit,
                        description: [
                            {
                                title: 'Risk Mitigation',
                                content: `Identify and mitigate risks associated with third-party vendors.
Protect your business from potential legal, financial, and reputational damage.`
                            },
                            {
                                title: 'Compliance Assurance',
                                content: `Ensure that your vendors comply with relevant laws and regulations.
Reduce the risk of regulatory fines and penalties.`
                            },
                            {
                                title: 'Improved Vendor Performance',
                                content: `Select vendors that meet your quality and performance standards.
Enhance operational efficiency and reliability through better vendor management.`
                            },
                            {
                                title: 'Financial Stability',
                                content: `Partner with financially stable vendors to ensure long-term business continuity.
Avoid disruptions caused by vendor insolvency or financial instability.`
                            },
                            {
                                title: 'Enhanced Decision-Making',
                                content: `Make informed decisions based on comprehensive due diligence findings.
Select vendors that align with your strategic goals and risk tolerance.`
                            },
                        ],
                        descriptionList: [],
                    },
                    {
                        title: 'Why Choose Us',
                        image: InternalAudit,
                        description: [],
                        descriptionList: [
                            {
                                title: 'Expertise and Experience',
                                description: ` Our team comprises seasoned due diligence professionals with extensive experience in vendor risk management.
Proven track record of successful vendor due diligence projects across various industries.`
                            },
                            {
                                title: 'Customized Solutions',
                                description: `We understand that each business is unique, and we tailor our due diligence services to meet your specific needs.
Flexible and scalable solutions that adapt to your business requirements. `
                            },
                            {
                                title: 'Customer-Centric Approach',
                                description: `We prioritize our clients' success and work closely with them to achieve their vendor management goals.
Dedicated support and ongoing assistance to ensure long-term success. `
                            },
                            {
                                title: 'Comprehensive Analysis',
                                description: `Utilize the latest tools, technologies, and methodologies for thorough and accurate due diligence.
Continuous innovation to stay ahead of emerging risks and industry trends. `
                            },
                            {
                                title: 'End-to-End Service Offering',
                                description: `End-to-end vendor due diligence services from initial assessment to ongoing monitoring.
One-stop-shop for all your vendor risk management needs, ensuring a seamless and hassle-free experience. `
                            },
                        ],
                    },

                ]
            },
            {
                title: "Whistle blower program",
                image: WhistleBlowerProgram,
                description: [
                    {
                        title: 'Service Overview',
                        content: `Our Whistleblower Program Implementation Services are designed to help organizations establish a robust and effective whistleblower system that encourages ethical behavior, promotes transparency, and ensures compliance with regulatory requirements. We provide comprehensive solutions to create, implement, and manage whistleblower programs tailored to your organization’s specific needs. `,
                        descriptionList: [
                            {
                                title: 'Program Design and Development ',
                                description: `Assess your organization’s current reporting mechanisms and identify areas for improvement.
Design a customized whistleblower program that aligns with your organizational culture and industry standards.
Develop clear policies and procedures for reporting and handling whistleblower complaints. `,
                            },
                            {
                                title: ' Policy and Procedure Creation',
                                description: `Draft comprehensive whistleblower policies that outline the rights and protections for whistleblowers.
Create detailed procedures for reporting, investigating, and resolving complaints. `,
                            },
                            {
                                title: 'Communication and Awareness Campaigns ',
                                description: `Develop and implement communication strategies to promote the whistleblower program within your organization.
Conduct awareness campaigns to educate employees about the importance of reporting unethical behavior and the protections available to them. `,
                            },
                            {
                                title: ' Confidential Reporting Channels',
                                description: ` Set up secure and confidential reporting channels, such as hotlines, web portals, and email.
Ensure that employees can report concerns anonymously if they choose.`,
                            },
                            {
                                title: 'Training and Education ',
                                description: ` Provide training programs for employees, management, and the board on the whistleblower program and their roles and responsibilities.
Conduct specialized training for those handling whistleblower complaints to ensure proper and fair investigations.`,
                            },
                            {
                                title: 'Investigation and Response Framework ',
                                description: `Establish a framework for investigating and responding to whistleblower complaints.
Develop protocols for conducting thorough and impartial investigations and for taking appropriate corrective actions. `,
                            },
                            {
                                title: ' Monitoring and Reporting',
                                description: ` Implement systems to monitor the effectiveness of the whistleblower program.
Provide regular reports and updates to management and the board on the status and outcomes of whistleblower complaints.`,
                            },
                            {
                                title: 'Continuous Improvement ',
                                description: ` Periodically review and update the whistleblower program to ensure it remains effective and compliant with changing regulations.
Gather feedback from employees and stakeholders to identify areas for enhancement.`,
                            },
                        ],
                    },
                ],
                descriptionList: [],
                subServices: [
                    {
                        title: 'Benefits',
                        image: InternalAudit,
                        description: [
                            {
                                title: 'Enhanced Ethical Culture',
                                content: `Foster a culture of integrity and accountability within your organization.
Encourage employees to report unethical behavior without fear of retaliation.`
                            },
                            {
                                title: 'Risk Mitigation',
                                content: `Identify and address potential issues before they escalate into major problems.
Reduce the risk of financial losses, legal penalties, and reputational damage.`
                            },
                            {
                                title: 'Regulatory Compliance',
                                content: `Ensure compliance with relevant laws and regulations regarding whistleblower protections.
Avoid fines and sanctions associated with non-compliance.`
                            },
                            {
                                title: 'Increased Transparency',
                                content: `Promote transparency and trust within your organization by demonstrating a commitment to ethical practices.
Build confidence among employees, customers, investors, and other stakeholders.`
                            },
                            {
                                title: 'Improved Employee Morale',
                                content: `Provide employees with a safe and confidential way to report concerns.
Enhance employee morale and engagement by showing that their voices are heard and valued.`
                            },
                        ],
                        descriptionList: [],
                    },
                    {
                        title: 'Why Choose Us',
                        image: InternalAudit,
                        description: [],
                        descriptionList: [
                            {
                                title: 'Expertise and Experience',
                                description: `Our team comprises seasoned professionals with extensive experience in implementing whistleblower programs.
Proven track record of successful implementations across various industries. `
                            },
                            {
                                title: 'Customized Solutions',
                                description: ` We understand that each organization is unique, and we tailor our services to meet your specific needs and challenges.
Flexible and scalable solutions that grow with your organization.`
                            },
                            {
                                title: 'Customer-Centric Approach',
                                description: ` We prioritize our clients' success and work closely with them to achieve their goals.
Dedicated support and ongoing assistance to ensure the long-term effectiveness of your whistleblower program.`
                            },
                            {
                                title: 'Confidentiality and Security',
                                description: `We implement secure and confidential reporting channels to protect whistleblowers’ identities.
Ensure that all information is handled with the utmost confidentiality and security. `
                            },
                            {
                                title: 'Comprehensive Service Offering',
                                description: ` End-to-end whistleblower program implementation services from design to continuous improvement.
One-stop-shop for all your whistleblower program needs, ensuring a seamless and hassle-free experience.`
                            },
                        ],
                    },

                ]
            },
            {
                title: "Revenue Assurance Audit",
                image: RevenueAssuranceAudit,
                description: [
                    {
                        title: 'Service Overview',
                        content: `Our Revenue Assurance Audit Services are designed to help organizations identify and rectify revenue leakage, optimize revenue processes, and ensure accurate financial reporting. We provide comprehensive solutions to safeguard your revenue streams, improve operational efficiency, and enhance profitability. `,
                        descriptionList: [
                            {
                                title: 'Revenue Process Assessment ',
                                description: `Conduct a thorough assessment of your existing revenue processes.
Identify gaps, inefficiencies, and areas prone to revenue leakage.
Provide detailed recommendations for process improvements. `,
                            },
                            {
                                title: ' Data Reconciliation and Validation',
                                description: `Perform data reconciliation to ensure consistency and accuracy across all revenue-related systems.
Validate billing, invoicing, and payment data to identify discrepancies and errors. `,
                            },
                            {
                                title: 'Contract and Agreement Review ',
                                description: `Review customer contracts and agreements to ensure compliance with revenue recognition policies.
Identify and rectify any contractual terms that may lead to revenue leakage. `,
                            },
                            {
                                title: 'Revenue Leakage Detection ',
                                description: `Use advanced analytics and forensic techniques to detect revenue leakage.
Analyze transaction data, customer accounts, and billing records to identify discrepancies. `,
                            },
                            {
                                title: ' Billing and Collection Audit',
                                description: `Audit billing systems and processes to ensure accurate and timely invoicing.
Assess the effectiveness of your collections process and recommend improvements. `,
                            },
                            {
                                title: 'Compliance and Regulatory Review ',
                                description: `Ensure adherence to relevant accounting standards and regulatory requirements.
Review revenue recognition practices to ensure compliance with ASC 606/IFRS 15. `,
                            },
                            {
                                title: 'Fraud Detection and Prevention ',
                                description: `Identify potential fraud risks related to revenue processes.
Implement controls and monitoring mechanisms to prevent revenue fraud. `,
                            },
                            {
                                title: ' Revenue Reporting and Analytics',
                                description: `Provide detailed reports and dashboards to monitor revenue performance.
Analyze revenue trends and provide insights for strategic decision-making. `,
                            },
                            {
                                title: 'Continuous Improvement and Monitoring ',
                                description: `Implement continuous monitoring systems to track revenue assurance metrics.
Regularly review and update revenue assurance processes to adapt to changing business environments. `,
                            },

                        ],
                    },
                ],
                descriptionList: [],
                subServices: [
                    {
                        title: 'Benefits',
                        image: InternalAudit,
                        description: [
                            {
                                title: 'Revenue Optimization',
                                content: `Identify and recover lost revenue through thorough audits and reconciliations.
Optimize revenue processes to ensure accurate and timely revenue recognition.`
                            },
                            {
                                title: 'Improved Financial Accuracy',
                                content: `Enhance the accuracy and reliability of financial reporting.
Reduce discrepancies and errors in revenue-related data.`
                            },
                            {
                                title: 'Risk Mitigation',
                                content: `Detect and mitigate risks related to revenue leakage and fraud.
Ensure compliance with accounting standards and regulatory requirements.`
                            },
                            {
                                title: 'Enhanced Operational Efficiency',
                                content: `Streamline revenue processes and eliminate inefficiencies.
Improve billing and collection processes for better cash flow management.`
                            },
                            {
                                title: 'Strategic Insights',
                                content: `Gain valuable insights into revenue trends and performance.
Make informed decisions based on accurate and comprehensive revenue data.`
                            },
                        ],
                        descriptionList: [],
                    },
                    {
                        title: 'Why Choose Us',
                        image: InternalAudit,
                        description: [],
                        descriptionList: [
                            {
                                title: 'Expertise and Experience',
                                description: `Our team comprises seasoned revenue assurance professionals with extensive experience in various industries.
Proven track record of successful revenue assurance audits and process improvements. `
                            },
                            {
                                title: 'Customized Solutions',
                                description: `We understand that each organization is unique, and we tailor our services to meet your specific needs and challenges.
Flexible and scalable solutions that adapt to your business requirements. `
                            },
                            {
                                title: 'Customer-Centric Approach',
                                description: ` We prioritize our clients' success and work closely with them to achieve their revenue assurance goals.
Dedicated support and ongoing assistance to ensure the long-term effectiveness of our solutions.`
                            },
                            {
                                title: 'Advanced Tools and Techniques',
                                description: `Utilize the latest tools, technologies, and methodologies for thorough and accurate revenue assurance audits.
Continuous innovation to stay ahead of emerging risks and industry trends. `
                            },
                            {
                                title: 'Comprehensive Service Offering',
                                description: `End-to-end revenue assurance audit services from assessment to continuous monitoring.
One-stop-shop for all your revenue assurance needs, ensuring a seamless and hassle-free experience.
Partner with Astral for your revenue assurance audit needs and safeguard your revenue streams, enhance financial accuracy, and drive profitability with confidence. `
                            },
                        ],
                    },

                ]
            }
        ]

    },
    {
        title: 'Outsourcing',
        image: Outsourcing,
        subServices: [
            {
                title: "Accounts Outsourcing Services",
                image: BookKeeping,
                description: [],
                descriptionList: [
                    {
                        title: 'Bookkeeping and Accounting',
                        description: `Maintain accurate financial records and manage daily transactions.
Prepare financial statements and reports. `
                    },
                    {
                        title: 'Payroll Management',
                        description: ` Handle payroll processing, tax withholdings, and employee benefits.`
                    },
                    {
                        title: 'Accounts Payable and Receivable',
                        description: `Manage invoices, payments, and collections efficiently. `
                    },
                    {
                        title: 'Tax Preparation and Filing',
                        description: ` Prepare and file tax returns, ensuring compliance with tax regulations.`
                    },
                    {
                        title: 'Financial Planning and Analysis',
                        description: ` Provide insights and analysis to support strategic decision-making.`
                    },
                    {
                        title: 'Cash Flow Management',
                        description: `Monitor and manage cash flow to ensure liquidity and financial stability. `
                    },
                    {
                        title: 'Budgeting and Forecasting',
                        description: `Develop budgets and financial forecasts to guide business planning. `
                    },
                    {
                        title: 'Compliance and Reporting',
                        description: ` Ensure adherence to financial regulations and prepare necessary reports.`
                    },
                ],
                subServices: [
                    {
                        title: 'Benefits  ',
                        image: InternalAudit,
                        description: [
                            {
                                title: 'Cost Savings',
                                content: `Reduce overhead costs associated with hiring and training in-house staff.`
                            },
                            {
                                title: 'Access to Expertise',
                                content: `Leverage the skills of experienced accounting professionals.`
                            },
                            {
                                title: 'Scalability ',
                                content: `Easily scale services up or down based on business needs.`
                            },
                            {
                                title: 'Focus on Core Business',
                                content: `Free up internal resources to focus on core business activities.`
                            },
                            {
                                title: 'Enhanced Accuracy',
                                content: `Minimize errors and ensure accurate financial records and reporting.`
                            },
                            {
                                title: 'Timely Compliance',
                                content: `Stay compliant with ever-changing tax laws and regulations.`
                            },
                            {
                                title: 'Improved Cash Flow Management',
                                content: `Optimize cash flow through efficient management of receivables and payables.`
                            },
                            {
                                title: 'Strategic Insights',
                                content: `Gain valuable insights from financial analysis to support growth and profitability.`
                            },
                        ],
                        descriptionList: [],
                    },
                    {
                        title: 'Why Choose Us',
                        image: InternalAudit,
                        description: [],
                        descriptionList: [
                            {
                                title: 'Experienced Professionals',
                                description: `Team of seasoned accountants with deep industry knowledge. `
                            },
                            {
                                title: 'Customized Solutions',
                                description: `Tailored accounting services to meet your specific business needs. `
                            },
                            {
                                title: 'Advanced Technology',
                                description: ` Use of cutting-edge accounting software for efficiency and accuracy.`
                            },
                            {
                                title: 'Commitment to Quality',
                                description: `Rigorous quality control processes to ensure high standards. `
                            },
                            {
                                title: 'Confidentiality and Security',
                                description: ` Strict adherence to confidentiality and data security protocols.`
                            },
                            {
                                title: 'Proactive Approach  ',
                                description: `Anticipate and address potential financial challenges before they arise. `
                            },
                            {
                                title: 'Client-Centric Focus',
                                description: ` Personalized service with a focus on building long-term relationships.`
                            },
                            {
                                title: 'Reliable Support',
                                description: `Consistent and responsive support to address your accounting needs promptly. `
                            },
                        ],
                    },

                ]
            },
            {
                title: "Shared CFO",
                image: SharedCFO,
                description: [
                    {
                        title: 'Service Overview',
                        content: `Our Shared CFO Services offer businesses the expertise of a Chief Financial Officer without the full-time cost. This service is designed to provide strategic financial leadership and support to organizations that may not need or cannot afford a full-time CFO. We help businesses navigate financial challenges, optimize performance, and achieve their financial goals through tailored and flexible CFO services. `,
                        descriptionList: [
                            {
                                title: ' Strategic Financial Planning',
                                description: `Develop and implement comprehensive financial strategies to support your business goals.
Conduct financial forecasting, budgeting, and long-term planning. `,
                            },
                            {
                                title: 'Financial Reporting and Analysis ',
                                description: `Prepare accurate and timely financial statements and reports.
Analyze financial performance and provide insights to improve profitability and growth. `,
                            },
                            {
                                title: ' Cash Flow Management',
                                description: ` Monitor and manage cash flow to ensure liquidity and financial stability.
Develop strategies for optimizing working capital and managing debt.`,
                            },
                            {
                                title: 'Cost Control and Optimization ',
                                description: ` Identify cost-saving opportunities and implement cost-control measures.
Analyze expense trends and recommend ways to improve efficiency.`,
                            },
                            {
                                title: 'Risk Management and Compliance ',
                                description: ` Assess and mitigate financial risks to protect your business.
Ensure compliance with regulatory requirements and industry standards.`,
                            },
                            {
                                title: 'Financial Decision Support ',
                                description: `Provide expert advice on financial decisions, such as investments, acquisitions, and expansions.
Conduct financial due diligence for potential business opportunities. `,
                            },
                            {
                                title: ' Performance Measurement',
                                description: ` Develop and track key performance indicators (KPIs) to measure financial health.
Provide regular performance reviews and actionable insights.`,
                            },
                            {
                                title: 'Financial Systems and Processes ',
                                description: ` Evaluate and improve financial systems and processes for better efficiency and accuracy.
Implement best practices for financial management and reporting.`,
                            },
                            {
                                title: 'Interim CFO Services ',
                                description: `Provide temporary CFO support during transitions, such as mergers, acquisitions, or executive turnover.
Ensure continuity and stability in financial leadership. `,
                            },
                        ],
                    },
                ],
                descriptionList: [],
                subServices: [
                    {
                        title: 'Benefits',
                        image: InternalAudit,
                        description: [
                            {
                                title: 'Cost-Effective Expertise',
                                content: `Access the expertise of a seasoned CFO without the expense of a full-time hire.
Flexible engagement models to fit your budget and business needs.`
                            },
                            {
                                title: 'Enhanced Financial Strategy',
                                content: `Benefit from strategic financial guidance to drive growth and profitability.
Improve financial planning and decision-making with expert insights.`
                            },
                            {
                                title: 'Improved Financial Performance',
                                content: `Optimize cash flow, reduce costs, and enhance operational efficiency.
Identify and capitalize on opportunities for financial improvement.`
                            },
                            {
                                title: 'Risk Mitigation',
                                content: `Proactively manage financial risks and ensure compliance with regulations.
Protect your business from potential financial pitfalls.`
                            },
                            {
                                title: 'Scalability and Flexibility',
                                content: `Scale CFO services up or down based on your business requirements.
Adapt to changing business needs with a flexible financial leadership solution.`
                            },
                        ],
                        descriptionList: [],
                    },
                    {
                        title: 'Why Choose Us',
                        image: InternalAudit,
                        description: [],
                        descriptionList: [
                            {
                                title: 'Expertise and Experience',
                                description: `Our team comprises highly experienced CFOs with a proven track record in various industries.
Extensive experience in financial management, strategic planning, and risk mitigation. `
                            },
                            {
                                title: 'Customized Solutions',
                                description: `We understand that each business is unique, and we tailor our CFO services to meet your specific needs.
Personalized financial strategies that align with your business goals. `
                            },
                            {
                                title: 'Customer-Centric Approach',
                                description: ` We prioritize our clients' success and work closely with them to achieve their financial objectives.
Dedicated support and ongoing assistance to ensure long-term success.`
                            },
                            {
                                title: 'Proven Methodologies',
                                description: `Utilize industry best practices and proven methodologies for financial management.
Continuous innovation to stay ahead of financial trends and challenges. `
                            },
                            {
                                title: 'Comprehensive Service Offering',
                                description: ` End-to-end CFO services from strategic planning to financial reporting and risk management.
One-stop-shop for all your financial leadership needs, ensuring a seamless and hassle-free experience.`
                            },

                        ],
                    },

                ]
            },
            {
                title: "Payroll Outsourcing",
                image: Payroll,
                description: [
                    {
                        title: 'Service Overview',
                        content: `Our Payroll Outsourcing Services are designed to help businesses streamline their payroll processes, ensure compliance with regulatory requirements, and enhance overall efficiency. By outsourcing your payroll to us, you can focus on your core business activities while we handle the complexities of payroll management. `,
                        descriptionList: [
                            {
                                title: 'Payroll Processing and Management ',
                                description: ` Accurate and timely processing of employee payroll.
Calculation of wages, deductions, bonuses, and overtime.
Generation and distribution of pay slips.`,
                            },
                            {
                                title: 'Tax Compliance and Reporting ',
                                description: ` Ensure compliance with federal, state, and local tax regulations.
Calculate and withhold payroll taxes accurately.
Prepare and file payroll tax returns on time.`,
                            },
                            {
                                title: ' Benefits Administration',
                                description: `Manage employee benefits, including health insurance, retirement plans, and other perks.
Handle benefit deductions and contributions. `,
                            },
                            {
                                title: 'Time and Attendance Management ',
                                description: `Track employee hours, attendance, and leave balances.
Integrate time and attendance data with payroll processing.`,
                            },
                            {
                                title: 'Direct Deposit and Payment Solutions ',
                                description: ` Facilitate direct deposit of employee salaries into their bank accounts.
Offer alternative payment methods, such as checks or prepaid cards.`,
                            },
                            {
                                title: ' Employee Self-Service Portal',
                                description: ` Provide employees with access to a secure online portal.
Allow employees to view pay slips, tax forms, and manage personal information.`,
                            },
                            {
                                title: ' Payroll Reporting and Analytics',
                                description: `Generate detailed payroll reports and analytics for management.
Provide insights into labor costs, overtime, and other payroll metrics. `,
                            },
                            {
                                title: 'Compliance and Audit Support ',
                                description: `Ensure adherence to all payroll-related laws and regulations.
Prepare for and support audits by regulatory authorities. `,
                            },
                        ],
                    },
                ],
                descriptionList: [],
                subServices: [
                    {
                        title: 'Benefits',
                        image: InternalAudit,
                        description: [
                            {
                                title: 'Cost Savings',
                                content: `Reduce operational costs by outsourcing payroll functions.
Eliminate the need for in-house payroll staff and infrastructure.`
                            },
                            {
                                title: 'Accuracy and Timeliness',
                                content: `Ensure accurate and timely payroll processing with our expert team.
Minimize errors and avoid penalties for late or incorrect filings.`
                            },
                            {
                                title: 'Compliance and Risk Management',
                                content: `Stay compliant with ever-changing payroll laws and regulations.
Reduce the risk of non-compliance and associated fines.`
                            },
                            {
                                title: 'Enhanced Efficiency',
                                content: `Streamline payroll processes with advanced technology and automation.
Free up internal resources to focus on strategic business activities.`
                            },
                            {
                                title: 'Improved Employee Satisfaction',
                                content: `Provide employees with timely and accurate pay.
Enhance employee experience with easy access to payroll information.`
                            },
                        ],
                        descriptionList: [],
                    },
                    {
                        title: 'Why Choose Us',
                        image: InternalAudit,
                        description: [],
                        descriptionList: [
                            {
                                title: 'Expertise and Experience',
                                description: ` Our team comprises seasoned payroll professionals with extensive experience in payroll management.
Proven track record of successful payroll outsourcing across various industries.`
                            },
                            {
                                title: 'Customized Solutions',
                                description: `We understand that each business is unique, and we tailor our payroll services to meet your specific needs.
Flexible and scalable payroll solutions that grow with your business. `
                            },
                            {
                                title: 'Customer-Centric Approach',
                                description: `We prioritize our clients' success and work closely with them to achieve their payroll goals.
Dedicated support and ongoing assistance to ensure smooth payroll operations. `
                            },
                            {
                                title: 'Cutting-Edge Technology',
                                description: `Leverage the latest payroll software and technology for efficient processing.
Continuous innovation to keep your payroll processes ahead of the curve. `
                            },
                            {
                                title: 'Comprehensive Service Offering',
                                description: `End-to-end payroll outsourcing services from processing to compliance support.
One-stop-shop for all your payroll needs, ensuring a seamless and hassle-free experience. `
                            },
                        ],
                    },

                ]
            }
        ]
    },
    {
        title: 'Investment Banking',
        image: InvestmentBanking,
        subServices: [
            {
                title: "Fund Rasise",
                image: FundRasise,
                description: [
                    {
                        title: 'Overview',
                        content: `Fund raising is a critical process for companies and organizations seeking to secure capital to fuel growth, expand operations, or achieve strategic goals. We offer comprehensive fund-raising services tailored to meet your specific needs, ensuring you have access to the right type of capital at the right time. Our expertise spans multiple fund-raising avenues, including Private Equity, Venture Capital, Angel Investment, Debt Financing, and Strategic Partnerships & Joint Ventures.   `,
                        descriptionList: [],
                    },
                    {
                        title: 'Private Equity (PE) or Venture Capital (VC)',
                        content: ``,
                        descriptionList: [
                            {
                                title: 'Service',
                                description: `We connect you with leading PE and VC firms that invest in private companies, providing the capital you need in exchange for a share of ownership.`,
                            },
                            {
                                listTitle: ' Benefits ',
                                list: [
                                    {
                                        content: ' Access to significant capital injections.'
                                    },
                                    {
                                        content: ' Strategic guidance and mentorship from experienced investors.',
                                    },
                                    {
                                        content: 'Potential for accelerated growth and market expansion. ',
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        title: 'Angel Investment ',
                        content: ``,
                        descriptionList: [
                            {
                                title: ' Service',
                                description: `We facilitate connections with angel investors, wealthy individuals who invest in early-stage companies in exchange for equity. `,
                            },
                            {
                                listTitle: ' Benefits ',
                                list: [
                                    {
                                        content: ' Early-stage funding to bring innovative ideas to market.'
                                    },
                                    {
                                        content: 'Networking opportunities with successful entrepreneurs and investors. ',
                                    },
                                    {
                                        content: 'Flexible investment terms tailored to your business needs.  ',
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        title: ' Debt Financing',
                        content: ``,
                        descriptionList: [
                            {
                                title: 'Service ',
                                description: `We assist in securing loans from banks, financial institutions, or private lenders, helping you raise funds without diluting ownership. `,
                            },
                            {
                                listTitle: ' Benefits ',
                                list: [
                                    {
                                        content: ' Retain full control of your company.'
                                    },
                                    {
                                        content: 'Structured repayment plans to suit your financial strategy. ',
                                    },
                                    {
                                        content: ' Potentially lower cost of capital compared to equity financing.',
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        title: ' Strategic Partnerships & Joint Ventures',
                        content: ``,
                        descriptionList: [
                            {
                                title: ' Service',
                                description: ` We identify and negotiate partnerships with other firms to share resources, expertise, and technology, working together to achieve common objectives.`,
                            },
                            {
                                listTitle: ' Benefits ',
                                list: [
                                    {
                                        content: ' Access to new markets and customer bases '
                                    },
                                    {
                                        content: ' Enhanced innovation and technological capabilities.',
                                    },
                                    {
                                        content: ' Shared risk and cost of development projects.',
                                    },
                                ]
                            },
                        ],
                    },
                ],
                descriptionList: [],
                subServices: [
                    // {
                    //     title: 'Benefits',
                    //     image: InternalAudit,
                    //     description: [
                    //         {
                    //             title: '',
                    //             content: ``
                    //         },
                    //     ],
                    //     descriptionList: [
                    //         {
                    //             title: ' ',
                    //             description: ` `,
                    //         },
                    //     ],
                    // },
                    {
                        title: 'Why Choose Us',
                        image: InternalAudit,
                        description: [],
                        descriptionList: [
                            {
                                title: 'Expertise',
                                description: `Our team has extensive experience in the financial and investment sectors, ensuring you receive knowledgeable and strategic guidance.`
                            },
                            {
                                title: 'Network',
                                description: `We have established relationships with top PE and VC firms, angel investors, financial institutions, and industry leaders, providing you with unparalleled access to capital. `
                            },
                            {
                                title: 'Customized Solutions',
                                description: ` We tailor our services to meet your unique needs, ensuring the best possible outcomes for your fund-raising efforts. `
                            },
                            {
                                title: 'Proven Track Record',
                                description: `We have successfully assisted numerous companies in securing the funds needed to achieve their growth and strategic objectives. `
                            },
                        ],
                    },

                ]
            },
            {
                title: "Deal Advisory",
                image: DealAdvisory,
                description: [
                    {
                        title: 'Overview',
                        content: `Our Deal Advisory Services are designed to provide comprehensive support for both buy-side and sell-side transactions. With professional expertise in corporate finance, M&A, valuation, and industry analysis, our experienced team works closely with clients to understand their specific needs and objectives, offering tailored advice and solutions to meet those objectives. We offer a full spectrum of services, including financial and tax due diligence, pre-deal support, end-to-end transaction management, and post-deal support. `
                    },
                    {
                        title: 'Financial and Tax Due Diligence',
                        content: ` `,
                        descriptionList: [
                            {
                                title: ' Financial Due Diligence',
                                description: `Assess the accuracy and completeness of the company's financial information, identify potential financial risks or issues, and evaluate the company's financial performance and growth prospects. `,
                            },
                            {
                                title: ' Benefits',
                                description: `Informed decision-making based on accurate financial data.
Identification of financial risks before transaction completion.
Enhanced confidence in investment decisions. `,
                            },
                            {
                                title: 'Tax Due Diligence ',
                                description: `Assess the company's tax risks and liabilities, identify potential tax issues, and evaluate compliance with tax laws and regulations. `,
                            },
                            {
                                title: ' Benefits',
                                description: `Mitigation of tax-related risks and liabilities.
Assurance of compliance with relevant tax regulations.
Optimization of tax strategies for future benefits. `,
                            },
                        ],
                    },
                    {
                        title: 'Pre-Deal Support',
                        content: ` `,
                        descriptionList: [
                            {
                                title: ' Financial Analysis & Building Financial Models',
                                description: ` Develop detailed financial models for accurate valuation.`,
                            },

                            {
                                title: 'Comprehensive Due Diligence ',
                                description: `Conduct thorough investigations to identify risks and opportunities. `,
                            },
                            {
                                title: ' Transaction Structuring & Negotiation',
                                description: `Structure transactions efficiently and negotiate favorable terms. `,
                            },
                            {
                                title: ' Tax Planning & Structuring',
                                description: `Plan and structure transactions to optimize tax outcomes. `,
                            },
                            {
                                title: ' Regulatory Compliance',
                                description: ` Holistic assessment of potential transactions.
Structuring deals to maximize value.
Minimized regulatory and tax risks.`,
                            },
                        ],
                    },
                    {
                        title: ' End-to-End Transaction Management',
                        content: `We provide comprehensive support throughout the entire transaction process, from initial strategy development to post-transaction integration and implementation. Key services include: `,
                        descriptionList: [
                            {
                                title: 'Strategic Advice ',
                                description: ` `,
                            },
                            {
                                title: 'Deal Structuring ',
                                description: ` `,
                            },
                            {
                                title: ' Valuation Analysis',
                                description: ` `,
                            },
                            {
                                title: 'Financial Modelling ',
                                description: ` `,
                            },
                            {
                                title: ' Contract Drafting & Review Support',
                                description: ` `,
                            },
                            {
                                title: 'Due Diligence Support ',
                                description: ` `,
                            },
                            {
                                title: ' Regulatory Support',
                                description: ` Seamless transaction execution.
Expert guidance at every stage.
Enhanced transaction value and success rate.`,
                            },

                        ],
                    },
                    {
                        title: 'Post-Deal Support',
                        content: ` Post-deal support is designed to help companies and investors manage the integration of the target company, ensuring that the transaction delivers the expected value. Key services rendered include:`,
                        descriptionList: [
                            {
                                title: 'Integration Planning & Execution ',
                                description: ` `,
                            },
                            {
                                title: 'Change Management ',
                                description: ` `,
                            },
                            {
                                title: 'Financial Analysis & Reporting ',
                                description: ` `,
                            },
                            {
                                title: ' Synergy Realization',
                                description: ` `,
                            },
                            {
                                title: ' Human Resource Support',
                                description: ` `,
                            },
                            {
                                title: ' Tax Planning Support',
                                description: ` `,
                            },
                            {
                                title: 'Regulatory Support ',
                                description: ` Smooth integration of acquired entities.
Realization of expected synergies and value.
Continuous support for ongoing success.`,
                            },
                        ],
                    },
                    {
                        title: 'Deal Structuring',
                        content: ` Deal structuring designs the financial and legal terms of a transaction such as a merger, acquisition, or financing. It involves creating a structure that is financially and operationally efficient, meets the objectives of the parties involved, and provides a solid foundation for the success of the transaction.`,
                        descriptionList: [
                            {
                                title: ' Benefits',
                                description: ` Customized transaction structures.
Optimized financial and operational efficiency.
Alignment with strategic objectives.`,
                            },
                        ],
                    },
                    {
                        title: 'Agreed Upon Procedures (AUP)',
                        content: `Our AUP services can be customized to meet the specific needs of the client, which may be financial or non-financial depending on the client-agreed scope. `,
                        descriptionList: [
                            {
                                title: 'Benefits ',
                                description: `Tailored procedures to meet specific transaction requirements.
Detailed insights based on client-defined scope.
Assurance and validation of critical transaction aspects. `,
                            },
                        ],
                    },
                ],
                descriptionList: [],
                subServices: [
                    // {
                    //     title: 'Benefits',
                    //     image: InternalAudit,
                    //     description: [
                    //         {
                    //             title: '',
                    //             content: ``
                    //         },
                    //     ],
                    //     descriptionList: [
                    //         {
                    //             title: ' ',
                    //             description: ` `,
                    //         },
                    //     ],
                    // },
                    {
                        title: 'Why Choose Us',
                        image: InternalAudit,
                        description: [],
                        descriptionList: [
                            {
                                title: 'Expertise',
                                description: `  Our team comprises seasoned professionals with deep knowledge in corporate finance, M&A, and industry analysis.`
                            },
                            {
                                title: 'Customized Solutions',
                                description: ` We offer tailored advice and solutions designed to meet the unique needs and objectives of each client.`
                            },
                            {
                                title: 'Comprehensive Support',
                                description: ` From pre-deal preparation to post-deal integration, we provide end-to-end support for seamless transactions.`
                            },
                            {
                                title: 'Proven Track Record',
                                description: `Our successful track record in managing complex transactions speaks to our capability and reliability.`
                            },
                            {
                                title: 'Strategic Partnerships',
                                description: `  We leverage strong relationships with industry leaders, financial institutions, and regulatory bodies to facilitate successful deals.`
                            },
                        ],
                    },

                ]
            },
            {
                title: "Valuation Services",
                image: ValuationServices,
                description: [
                    {
                        title: 'Overview',
                        content: ` Valuation services are crucial for companies and investors to make informed decisions regarding their investments and assets while ensuring compliance with regulatory and accounting standards. We offer comprehensive valuation services, including business valuation, intellectual property valuation, real estate valuation, and plant & machinery valuation, tailored to meet your specific needs.`,
                        descriptionList: [],
                    },

                ],
                descriptionList: [
                    {
                        title: 'Business Valuation',
                        description: `We provide detailed business valuation services to help you understand the worth of your business, whether for sale, merger, acquisition, or financial reporting purposes. `
                    },
                    {
                        title: 'Intellectual Property Valuation',
                        description: `Our intellectual property valuation services include assessing the value of patents, trademarks, copyrights, and other intangible assets. `
                    },
                    {
                        title: ' Real Estate Valuation',
                        description: `We offer comprehensive real estate valuation services for commercial, industrial, and residential properties, helping you determine market value for transactions, financing, or investment analysis. `
                    },
                    {
                        title: ' Plant & Machinery Valuation',
                        description: ` Our plant & machinery valuation services provide precise valuations for industrial equipment, machinery, and other physical assets.`
                    },
                ],
                subServices: [
                    {
                        title: 'Benefits',
                        image: InternalAudit,
                        description: [],
                        descriptionList: [
                            {
                                listTitle: '  Business Valuation',
                                list: [
                                    {
                                        content: 'Accurate assessment of business value. '
                                    },
                                    {
                                        content: ' Enhanced decision-making for mergers, acquisitions, and sales.',
                                    },
                                    {
                                        content: 'Compliance with financial reporting standards. ',
                                    },
                                ]
                            },
                            {
                                listTitle: ' Intellectual Property Valuation ',
                                list: [
                                    {
                                        content: ' Understanding the true value of intellectual property.'
                                    },
                                    {
                                        content: ' Leveraging IP for licensing, sale, or strategic partnerships.',
                                    },
                                    {
                                        content: 'Enhanced protection and management of IP assets. ',
                                    },
                                ]
                            },
                            {
                                listTitle: 'Real Estate Valuation  ',
                                list: [
                                    {
                                        content: 'Accurate market value assessment. '
                                    },
                                    {
                                        content: ' Informed real estate investment and financing decisions.',
                                    },
                                    {
                                        content: 'Compliance with regulatory and reporting standards. ',
                                    },
                                ]
                            },
                            {
                                listTitle: 'Plant & Machinery Valuation  ',
                                list: [
                                    {
                                        content: ' Accurate valuation for insurance, sale, or financing.'
                                    },
                                    {
                                        content: ' Enhanced asset management and financial planning.',
                                    },
                                    {
                                        content: 'Compliance with accounting and regulatory standards. ',
                                    },
                                ]
                            },

                        ],
                    },
                    {
                        title: 'Why Choose Us',
                        image: InternalAudit,
                        description: [],
                        descriptionList: [
                            {
                                title: 'Expertise',
                                description: `Our team of valuation experts possesses deep industry knowledge and extensive experience, ensuring accurate and reliable valuations. `
                            },
                            {
                                title: 'Tailored Solutions',
                                description: `We customize our valuation services to meet your specific needs and objectives, providing the most relevant and valuable insights. `
                            },
                            {
                                title: 'Comprehensive Approach',
                                description: `We use advanced methodologies and the latest market data to deliver precise and comprehensive valuations. `
                            },
                            {
                                title: 'Proven Track Record',
                                description: `Our successful track record in providing valuation services to diverse clients highlights our reliability and expertise. `
                            },
                            {
                                title: 'Regulatory Compliance',
                                description: ` We ensure all valuations comply with relevant regulatory and accounting standards, giving you confidence and peace of mind. `
                            },
                        ],
                    },

                ]
            },
        ]
    },
    {
        title: 'Subsidies',
        image: Subsidies,
        subServices: [
            {
                title: "Grants & Subsidies",
                image: GrantsSubsidies,
                description: [
                    {
                        title: 'Overview',
                        content: `Navigating the complex landscape of grants and subsidies can be challenging for many organizations. Our Grants and Subsidies Availment Services are designed to help you identify, apply for, and secure funding opportunities that can fuel your growth and development. We offer end-to-end support, from identifying suitable grants to application submission and post-award compliance. `
                    },
                    {
                        title: ' Identification of Funding Opportunities',
                        content: ` `,
                        descriptionList: [
                            {
                                title: 'Service ',
                                description: ` We conduct thorough research to identify relevant grants and subsidies that align with your organizational goals and projects.`,
                            },
                            {
                                listTitle: ' Benefits',
                                list: [
                                    {
                                        content: 'Access to a comprehensive database of funding opportunities'
                                    },
                                    {
                                        content: 'Identification of grants that best match your needs.',
                                    },
                                    {
                                        content: 'Increased chances of securing appropriate funding.',
                                    },
                                ]
                            }
                        ],
                    },
                    {
                        title: 'Application Preparation and Submission',
                        content: ` `,
                        descriptionList: [
                            {
                                title: 'Service ',
                                description: `Our team assists in preparing compelling grant applications, ensuring all required documentation is complete and submitted on time. `,
                            },
                            {
                                listTitle: ' Benefits ',
                                list: [
                                    {
                                        content: ' Expertly crafted applications that stand out to funding bodies.'
                                    },
                                    {
                                        content: 'Comprehensive support to meet all application requirements. ',
                                    },
                                    {
                                        content: ' Timely submission to meet deadlines and improve success rates. ',
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        title: 'Proposal Writing and Budget Development',
                        content: ` `,
                        descriptionList: [
                            {
                                title: 'Service ',
                                description: `We help you develop strong proposals and budgets that clearly outline your project’s objectives, expected outcomes, and financial needs. `,
                            },
                            {
                                listTitle: ' Benefits ',
                                list: [

                                    {
                                        content: ' Professionally written proposals that effectively communicate your project’s value. ',
                                    },
                                    {
                                        content: 'Well-structured budgets that justify your funding requests. ',
                                    },
                                    {
                                        content: 'Enhanced credibility and persuasiveness in your applications. '
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        title: 'Post-Award Compliance and Reporting',
                        content: ` `,
                        descriptionList: [
                            {
                                title: 'Service ',
                                description: `Our services extend beyond securing funding; we assist with compliance, reporting, and monitoring to ensure adherence to grant conditions. `,
                            },
                            {
                                listTitle: 'Benefits  ',
                                list: [
                                    {
                                        content: 'Ongoing support to ensure compliance with funding requirements. '
                                    },
                                    {
                                        content: ' Assistance with progress reports and financial documentation.',
                                    },
                                    {
                                        content: ' Sustained relationship with grantors for future funding opportunities.',
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        title: 'Training and Capacity Building',
                        content: ` `,
                        descriptionList: [
                            {
                                title: ' Service',
                                description: ` We offer training sessions and workshops to build your team’s capacity in grant writing and management. `,
                            },
                            {
                                listTitle: 'Benefits  ',
                                list: [
                                    {
                                        content: 'Enhanced internal skills in identifying and securing grants. '
                                    },
                                    {
                                        content: 'Long-term self-sufficiency in managing grant applications. ',
                                    },
                                    {
                                        content: ' Increased success rate in future grant endeavors.',
                                    },
                                ]
                            },
                        ],
                    },
                ],
                descriptionList: [],
                subServices: [
                    // {
                    //     title: 'Benefits',
                    //     image: InternalAudit,
                    //     description: [
                    //         {
                    //             title: '',
                    //             content: ``
                    //         },
                    //     ],
                    //     descriptionList: [
                    //         {
                    //             title: ' ',
                    //             description: ` `,
                    //         },
                    //     ],
                    // },
                    {
                        title: 'Why Choose Us',
                        image: InternalAudit,
                        description: [],
                        descriptionList: [
                            {
                                title: 'Expertise',
                                description: ` Our team consists of experienced grant writers and consultants with a proven track record of securing funding for diverse organizations. `
                            },
                            {
                                title: 'Customized Solutions',
                                description: ` We tailor our services to meet your specific needs, ensuring a strategic approach to securing grants and subsidies.`
                            },
                            {
                                title: 'Comprehensive Support',
                                description: ` From initial research to post-award management, we provide end-to-end support throughout the grant lifecycle.`
                            },
                            {
                                title: 'Proven Success',
                                description: ` Our successful history of helping clients secure grants and subsidies demonstrates our capability and reliability.`
                            },
                            {
                                title: 'Strategic Insight',
                                description: ` We offer valuable insights into funding trends and opportunities, helping you stay ahead in the competitive landscape of grants and subsidies.`
                            },
                        ],
                    },

                ]
            },
        ]

    },

]
export default servicesData