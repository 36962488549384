import React from 'react'
import aboutImg from '../assert/images/image/aboutCompany.jpg'

const About = () => {
    return (
        <section className="tf-space flat-about">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <div className="about-left-content wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                            <div className="section-title">
                            <h3 className="section-heading">About Astral Consulting</h3>
                                <h2>Doing The Right Thing At<br />
                                    The Right Time</h2>
                            </div>
                            <p>At Astral Consulting Limited, we are a leading audit and consulting firm with a proud history of providing high-quality services since 1992. We offer three distinct lines of service that cater to the unique needs of our clients: Audit & Assurance, Consulting, and Outsourcing.</p>
                            <p className="font-weight-500">At Astral Consulting Limited, we pride ourselves on delivering exceptional service and building long-lasting relationships with our clients. We are committed to providing the highest level of expertise and professionalism in all our engagements. Contact us today to learn more about how we can help your business thrive.</p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="about-right-content tf-counter wow fadeInRight" data-wow-delay="0ms"
                            data-wow-duration="1500ms">
                            <div className="about-post">
                                <img src={aboutImg} alt="images" />
                                <div className="about-counter-box wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="about-counter-inner">
                                        <div className="number-counter">
                                            {/* <span>0</span> */}
                                            <span className="number" data-speed="2000" data-to="30" data-inviewport="yes">30</span>
                                        </div>
                                        <div className="heading-count">
                                            <h3>YEARS<br />
                                                EXPERIENCE</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About
