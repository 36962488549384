import React, { useState } from 'react';
import { AiFillStar } from "react-icons/ai";
import { ImCheckmark } from "react-icons/im";
import { RiFlightTakeoffFill } from "react-icons/ri";
import All from './components/All';
import Self from './components/Self';
import Subordinate from './components/Subordinate';


const getLast20Years = () => {
  const currentYear = new Date().getFullYear();
  return Array.from({ length: 20 }, (_, i) => currentYear - i);
};


const Content = () => {
  const currentDate = new Date();
  const [year, setYear] = useState(currentDate.getFullYear());
  const [month, setMonth] = useState(currentDate.getMonth() + 1);
  const [selectAttType, setSelectAttType] = useState('self')

  let months = [
    { value: 1, label: "January" },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" }
  ];

  return (
    <div className='dash-content-grid'>
      <div className='attendance_section_head'>
        <div>
          <div className="dash-fm-group attentance_field_dateMonth">
            <label className="dash-fm-label" htmlFor='year-select'>
              Attendance Type
            </label>
            <select className='dash-fm-input' value={selectAttType} onChange={(e) => setSelectAttType(e?.target?.value)} >
              <option value='self'>Self</option>
              <option value='all'>All</option>
              <option value='subordinate'>Subordinate</option>
            </select>
          </div>
        </div>
        <div className='flex-alc'>
          <div className="dash-fm-group attentance_field_dateMonth">
            <label className="dash-fm-label" htmlFor='year-select'>
              Year
            </label>
            <select className='dash-fm-input' value={year} onChange={(e) => setYear(e?.target?.value)} >
              {getLast20Years().map(el =>
                <option key={el} value={el}>{el}</option>
              )}
            </select>
          </div>
          <div className="dash-fm-group attentance_field_dateMonth">
            <label className="dash-fm-label" htmlFor='month-select'>
              Months
            </label>
            <select className='dash-fm-input' value={month} onChange={(e) => setMonth(e?.target?.value)}>
              {months.map((el) => (
                <option key={el.value} value={el.value}>{el.label}</option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className='attendance_icon_note'>
        <ul>
          <li><b>Note : </b></li>
          <li><ImCheckmark className='attendance_present_icon' /> - Present</li>
          <li><RiFlightTakeoffFill className='attendance_leave_icon' /> - Leave</li>
          <li><AiFillStar className='attendance_holiday_icon' /> - Holiday</li>
        </ul>
      </div>
      {selectAttType === 'all' && <All />}
      {selectAttType === 'self' && <Self />}
      {selectAttType === 'subordinate' && <Subordinate />}
    </div>
  );
};

export default Content;
