import React, { useEffect } from 'react'
import { FaRegDotCircle } from "react-icons/fa"
import { FiThumbsUp } from "react-icons/fi"
import { MdVerified } from "react-icons/md"
import { useDispatch } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import BannerImg from '../../components/assert/images/subBanner/Services.jpg'
import CommonAction from '../../redex/Common/Actions'
import Experience from '../aboutUs/Experience'
import Footer from '../common/Footer'
import Header from '../common/Header'
import { ScrollToTop } from '../common/Helper'
import SubBanner from '../common/SubBanner'
import servicesData from './serviesContent'


const ServicesDetail = () => {
    const dispatch = useDispatch()
    ScrollToTop(0, 0);
    const location = useLocation();
    const { state } = location;
    useEffect(() => {
        dispatch({
            type: CommonAction.NAV_LINKS, payload: [
                { name: "Services", link: '/services' },
            ]
        })
    })

    
    return (
        <div>
            <Header />
            <SubBanner pageName={state?.title} title={state?.title} image={BannerImg} />
            <section className="tf-space flat-service-details ser_det_sec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 mb-5">
                            <article className="post-inner">
                                <h2 className="section-35px-barlow mtb-3">{state?.title}</h2>
                                {state?.description?.map((dis, disInd) => (
                                    <div>
                                        <div className='ser_des_content'>
                                            <h4>{dis?.title}</h4>
                                            <p key={disInd}>{dis?.content}</p>
                                        </div>
                                        <ul className="work-process service_details_list p-0 des_icon_box_sec row bg-grad mtb-5">
                                            {dis?.descriptionList?.map((val, indx) => val?.list ? (
                                                <div className='list_ser_sec col-lg-8 col-md-8 col-sm-12 col-12'>
                                                    <div className="des_list_td">
                                                        <h4>{val?.listTitle}</h4>
                                                    </div>
                                                    <ul key={indx} className='list'>
                                                        {
                                                            val?.list?.map((li, indx) => (
                                                                <li className="services_list_li">
                                                                    <FaRegDotCircle />
                                                                    <div className="icon-des">
                                                                        <p>{li?.content}</p>
                                                                    </div>
                                                                </li>
                                                            ))
                                                        }

                                                    </ul>
                                                </div>

                                            ) : (
                                                <li key={indx} className='d-g des_icon_box col-lg-4 col-md-4 col-sm-6 col-12'>
                                                    <div className="service_details_icon_card nested_list-services">
                                                        <MdVerified />
                                                        <div className="icon-des">
                                                            <h4>{val?.title}</h4>
                                                        </div>
                                                        <p>{val?.description}</p>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>

                                ))}

                                {state?.descriptionList?.length !== 0 && (
                                    <ul className="work-process service_details_list p-0 des_icon_box_sec row bg-grad mtb-5">
                                        {state?.descriptionList?.map((val, indx) => (
                                            <li key={indx} className='d-g des_icon_box col-lg-4 col-md-4 col-sm-6 col-12'>
                                                <div className="service_details_icon_card">
                                                    <MdVerified />
                                                    <div className="icon-des">
                                                        <h4>{val?.title}</h4>
                                                    </div>
                                                    <p>{val?.description}</p>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </article>
                            {state?.subServices?.map((el, ind) => (
                                <article className="post-inner" key={ind}>
                                    <h2 className="section-35px-barlow mtb-3">{el?.title}</h2>
                                    <div className='row'>
                                        {el?.description?.map((dis, disInd) => (
                                            // <div className='ser_des_content'>
                                            //     <h4>{dis?.title}</h4>
                                            //     <p key={disInd}>{dis?.content}</p>
                                            // </div>
                                            <div key={disInd} className='col-lg-4 col-md-4 col-sm-6 col-12'>
                                                <div class="card4 " href="#">
                                                    <FiThumbsUp />
                                                    <h3>{dis?.title}</h3>
                                                    <p class="small">{dis?.content}</p>
                                                    <div class="dimmer"></div>
                                                    {/* <div class="go-corner" href="#">
                                                        <div class="go-arrow">
                                                            →
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>

                                        ))}
                                    </div>


                                    {el?.descriptionList?.length !== 0 && (
                                        <div className="service_details_list row ">
                                            {el?.descriptionList?.map((val, indx) => val?.list ? (
                                                <div className='list_ser_sec p-3 col-lg-6 col-md-6 col-sm-12 col-12'>
                                                    <div className="des_list_td">
                                                        <h4>{val?.listTitle}</h4>
                                                    </div>
                                                    <ul key={indx} className='list'>
                                                        {
                                                            val?.list?.map((li, indx) => (
                                                                <li className="services_list_li">
                                                                    <FaRegDotCircle />
                                                                    <div className="icon-des">
                                                                        <p>{li?.content}</p>
                                                                    </div>
                                                                </li>
                                                            ))
                                                        }

                                                    </ul>
                                                </div>

                                            ) : (
                                                <div class="col-lg-6 col-md-6 col-sm-6 col-12" key={indx} >
                                                    <div className='card1 '>
                                                        {val?.title && <h3>{val?.title}</h3>}
                                                        <p class="small">{val?.description}</p>
                                                        <div class="go-corner">
                                                            <div class="go-arrow">
                                                                →
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </article>
                            ))}
                        </div>
                        <div className="col-lg-12 col-md-12">
                            <aside className="side-bar services_det_add_sec row">
                                <div className="col-lg-5 col-md-5 col-sm-12 col-12">
                                    <div class="widget widget-category">
                                        <h3 class="widget-title section-24px-barlow">
                                            Contact us
                                        </h3>
                                        <ul className="widget-list-contact link-style-7">
                                            <li>
                                                <span className="meta-address">
                                                    No.14-A, 4th floor,
                                                    Ramakrishna Nagar,
                                                    New Sidhaputhur,
                                                    Coimbatore, 641044
                                                </span>
                                            </li>
                                            <li>
                                                <a href="mailto:contact@astralconsultants.com" className="meta-mail">
                                                    contact@astralconsultants.com
                                                </a>
                                            </li>
                                            <li>
                                                <a href="tel:+0422 2312548" className="meta-phone">
                                                    +0422 2312548
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-5 col-sm-12 col-12">
                                    <div class="widget widget-category">
                                        <h3 class="widget-title section-24px-barlow">
                                            Our Services
                                        </h3>
                                        <ul>
                                            {servicesData?.map((el, idx) => (
                                                <li class="cat-item" key={idx} ><Link to={'/sub-servicess'} state={el}>{el?.title}</Link>
                                                    <span>{el?.subServices?.length}</span>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>

                            </aside>
                        </div>
                    </div>
                </div>
            </section>
            <Experience />
            <Footer />
        </div>
    );
};

export default ServicesDetail;
