import React, { useState } from 'react'
import BannerImg from '../components/assert/images/subBanner/CaseStudies.jpg'
import Details from '../components/caseStudies/Details'
import Footer from '../components/common/Footer'
import Header from '../components/common/Header'
import { ScrollToTop } from '../components/common/Helper'
import SubBanner from '../components/common/SubBanner'

const CaseStudies = () => {
    const [show, setShow] = useState(false);
    ScrollToTop(0, 0)
    const caseStudyList = [
        {
            title: 'Streamlining Financial Processes',
            content: 'Implemented automated workflows to streamline financial processes, resulting in a 30% reduction in processing time and increased accuracy.',
            author: 'Alice Johnson',
            designation: 'Financial Analyst'
        },
        {
            title: 'Enhancing Compliance Measures',
            content: 'Developed and implemented enhanced compliance measures to ensure adherence to industry regulations, leading to successful audits and regulatory approvals.',
            author: 'Bob Anderson',
            designation: 'Compliance Officer'
        },
        {
            title: 'Optimizing Supply Chain Operations',
            content: 'Optimized supply chain operations by identifying inefficiencies and implementing cost-saving measures, resulting in a 25% reduction in operational costs.',
            author: 'Catherine White',
            designation: 'Supply Chain Manager'
        },
        {
            title: 'Improving Customer Satisfaction',
            content: 'Implemented customer-centric initiatives leading to improved customer satisfaction scores and increased customer retention by 20%.',
            author: 'David Miller',
            designation: 'Customer Experience Manager'
        },
        {
            title: 'Strengthening Cybersecurity Measures',
            content: 'Enhanced cybersecurity measures by implementing advanced threat detection systems, ensuring data integrity and protecting sensitive information from potential threats.',
            author: 'Emily Brown',
            designation: 'Cybersecurity Analyst'
        },
        {
            title: 'Driving Operational Efficiency',
            content: 'Led initiatives to drive operational efficiency through the implementation of technology solutions, resulting in a 15% increase in overall productivity.',
            author: 'Frank Williams',
            designation: 'Operations Manager'
        },
        {
            title: 'Expanding Market Reach',
            content: 'Executed strategic market expansion plans, leading to increased market share and revenue growth in new geographic regions.',
            author: 'Grace Turner',
            designation: 'Market Expansion Manager'
        },
        {
            title: 'Revamping Employee Training Programs',
            content: 'Revamped employee training programs, enhancing employee skills and knowledge, resulting in improved performance and reduced turnover rates.',
            author: 'Henry Clark',
            designation: 'Training and Development Specialist'
        },
        {
            title: 'Innovating Product Development',
            content: 'Led innovative product development initiatives, introducing new products that gained widespread market acceptance and increased profitability.',
            author: 'Ivy Rodriguez',
            designation: 'Product Manager'
        },
    ];



    return (
        <div>
            <Header />
           <SubBanner title='Case Studies' pageName='Case Studies' image={BannerImg} /> 
            <div className='case_study_sec tf-space'>
                <div className='container'>
                    <div className='row'>
                        {
                            caseStudyList?.map((el, ind) => (
                                <div className='col-lg-4 col-md-4 col-sm-6 col-12' key={ind}>
                                    <div class="caseStudyCard" >
                                        <div class="card__head">
                                            <div class="card__image"></div>
                                            <div class="card__author">
                                                <div class="author_case">
                                                    <img src="https://s.gravatar.com/avatar/7ff9e93ff25e002bc49f4d69c0c3eac7?s=80" alt="Author of Tobias Reich" class="author__image" />
                                                    <div class="author__content">
                                                        <p class="author__header">{el?.author}</p>
                                                        <p class="author__subheader">{el?.designation}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card__body">
                                            <h2 class="card__headline">{el?.title}</h2>
                                            <p class="card__text">{el?.content?.slice(0, 200)}...</p>
                                        </div>
                                        <div class="card__border"></div>
                                        <button className='view_case_btn' onClick={() => setShow(ind)}>View</button>
                                        <Details show={show} setShow={setShow} data={el} index={ind} />
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div> 
            {/* <UnderMaintanance/> */}
            <Footer />
        </div>
    )
}

export default CaseStudies
