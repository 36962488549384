import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { FaBookOpen, FaRegFilePdf, FaUser } from 'react-icons/fa';
import { FaLocationDot } from "react-icons/fa6";
import { GrView } from "react-icons/gr";
import { IoCloudUploadOutline } from "react-icons/io5";
import { MdLabelImportant } from "react-icons/md";
import { PiOfficeChairFill } from "react-icons/pi";
import { RiComputerFill, RiDeleteBin6Line } from "react-icons/ri";
import { useDispatch } from 'react-redux';
import BannerImg from '../components/assert/images/subBanner/career.jpg';
import Footer from '../components/common/Footer';
import Header from '../components/common/Header';
import { ScrollToTop } from '../components/common/Helper';
import SubBanner from '../components/common/SubBanner';
import CommonAction from '../redex/Common/Actions';

const Careers = () => {
    const dispatch = useDispatch()
    const [careerOpening] = useState([
        {
            "position": "Junior Audit Associate",
            "description": "Responsible for conducting the audit assignments assigned to them. Ensure completion of audit in a timely and complete manner. Willingness to learn and do the tasks in a team work. Enthusiastic and proactive candidates preferred. Short Travels should not be a constraint.",
            "jobType": "Full Time",
            "qualifications": "Bachelors degrees with accounts and finance. Candidates pursuing CA, CMA, or Inter completed",
            "experience": "2 to 5 years of audit experience is preferred. ",
            "locations": [
                { "title": "Coimbatore" },
            ]
        },
        {
            "position": "Audit Manager",
            "description": `Responsible for conducting the audit assignments assigned to them along with the audit staff assigned to them. Ensure smooth planning, execution and completion of audit in a timely and proactive manner. Willingness to learn and lead the team. Experience in internal audit supervision is preferred. Travel for short duration should not be a constraint.`,
            "jobType": "Full Time",
            "qualifications": "Bachelors degrees with accounts and finance. Candidates pursuing CA, CMA, or Inter completed ",
            "experience": "5 to 10 years of audit experience along with the ability to lead the audit is preferred. ",
            "locations": [
                { "title": "Coimbatore" }
            ]
        },
        {
            "position": "Business Development Executive",
            "description": `Having experience in concept sales, software sales, & good communicati  on skills.    `,
            "jobType": "Full Time",
            "qualifications": "Any degree",
            "experience": " 1 to 3 years in concept sales ",
            "locations": [
                { "title": "Coimbatore" }
            ]
        },
    ]
    )
    
    const [upLoadedCV, setUpLoadedCV] = useState(null)
    const [show, setShow] = useState(false);
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        setError,
        formState: { errors },
    } = useForm()
    ScrollToTop(0, 0)


    const handleClose = () => setShow(false);
    const handleShow = (ind) => setShow(ind);


    const onViewUploadedCV = () => {
        const creatURL = URL.createObjectURL(upLoadedCV)
        window.open(creatURL, '_blank')
    }

    const onSubmit = async (data) => {
        try {
            const formData = new FormData();
            formData.append('to', 'karpagamurugan@bwefinserv.com');
            formData.append('Name', data?.name || '');
            formData.append('Mail', data?.mail || '');
            formData.append('Phone', data?.phone || '');
            formData.append('subject', 'Career Form');
            formData.append('Position', data?.position);
            formData.append('message', data?.summary || '');
            formData.append('Resume', data?.resume);

            const response = await fetch('https://astralconsultants.com/backend/send_email_career.php', {
                method: 'POST',
                body: formData
            });

            const result = await response.json();

            if (response.ok && result.success) {
                dispatch({ type: CommonAction.SET_TOASTER, payload: { open: true, message: 'Your CV has been submitted...', type: '' } })
                reset({
                    name: '',
                    mail: '',
                    phone: '',
                    position: '',
                    summary: ''
                });
                setUpLoadedCV(null)
            } else {
                dispatch({ type: CommonAction.SET_TOASTER, payload: { open: true, message: 'Mail Not Send...!', type: 'err' } })
            }
        } catch (error) {
            dispatch({ type: CommonAction.SET_TOASTER, payload: { open: true, message: 'Mail Not Send...!', type: 'err' } })
        }
    };
    return (
        <div>
            <Header />
            <SubBanner title='Careers' pageName='Careers' image={BannerImg} />
            <section className="flat-blog">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className='row'>
                                {
                                    careerOpening?.map((el, ind) => (
                                        <div key={ind} className='col-lg-6 col-md-6 col-sm-6 col-12'>
                                            <div className="wrapper-career ">
                                                <div className="orange-top orange-border"></div>
                                                <div className="orange-left orange-border"></div>
                                                <div className='career_content'>
                                                    <div className="white-card" >
                                                        {/* <img src="https://s3.amazonaws.com/levelup-assets/temp/design.png" alt="Design" /> */}
                                                        <p className="category">{el?.position}</p>
                                                        <span className="CTA c-p" onClick={() => handleShow(ind)}>View</span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <Modal size='lg' centered show={show === ind} onHide={handleClose}>
                                                        <Modal.Header closeButton>
                                                            <Modal.Title>{el?.position}</Modal.Title>
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            <div className='career_desc_det'>
                                                                <table class="table">
                                                                    <tbody>
                                                                        <tr>
                                                                            <th><FaUser /> Position </th>
                                                                            <td>{el?.position}</td>

                                                                        </tr>
                                                                        <tr>
                                                                            <th><FaBookOpen /> Qualifications</th>
                                                                            <td>{el?.qualifications}</td>

                                                                        </tr>
                                                                        <tr>
                                                                            <th><RiComputerFill /> Experience</th>
                                                                            <td>{el?.experience}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th><PiOfficeChairFill /> Job Type</th>
                                                                            <td>{el?.jobType}</td>

                                                                        </tr>
                                                                        <tr>
                                                                            <th><MdLabelImportant /> Description</th>
                                                                            <td>{el?.description}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th><FaLocationDot /> Locations</th>
                                                                            <td>
                                                                                <ul>
                                                                                    {
                                                                                        el?.locations?.map((loc, locInd) => (
                                                                                            <li key={locInd}>{loc?.title}</li>
                                                                                        ))
                                                                                    }
                                                                                </ul>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </Modal.Body>
                                                    </Modal>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <aside className="side-bar">
                                <div className="widget widget-category">
                                    <h3 className="widget-title section-24px-barlow">
                                        Apply for this position
                                    </h3>
                                    <div className="careers_apply_form contact-form">
                                        <form action="#">
                                            <div className="form-group">
                                                <input type="text" className="form-control"
                                                    placeholder="Enter Your Name...!"
                                                    {...register("name", {
                                                        required: 'Name is required',
                                                        pattern: {
                                                            value: /^[A-Za-z -]+$/,
                                                            message: "Invalid name format"
                                                        }
                                                    })}
                                                />
                                                {errors.name?.message && <p className='error-message'>{errors.name?.message}</p>}
                                            </div>
                                            <div className="form-group">
                                                <input type="text" className="form-control"
                                                    placeholder="Enter Your Phone Number...!"
                                                    {...register("phone", {
                                                        required: 'Phone Number is required',
                                                        pattern: {
                                                            value: /^[0-9]+$/,
                                                            message: "Only numbers are allowed"
                                                        },
                                                        maxLength: {
                                                            value: 10,
                                                            message: 'Phone number must be exactly 10 digits'
                                                        },
                                                        minLength: {
                                                            value: 10,
                                                            message: 'Phone number must be exactly 10 digits'
                                                        },
                                                    })}
                                                />
                                                {errors.phone?.message && <p className='error-message'>{errors.phone?.message}</p>}
                                            </div>
                                            <div className="form-group">
                                                <input type="email" className="form-control"
                                                    placeholder="Enter Your Mail...!"
                                                    {...register("mail", {
                                                        required: 'Mail is required',
                                                        pattern: {
                                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                            message: 'Invalid email address'
                                                        }
                                                    })}
                                                />
                                                {errors.mail?.message && <p className='error-message'>{errors.mail?.message}</p>}
                                            </div>
                                            <div className="form-group">
                                                <select type="select"
                                                    {...register("position", { required: 'Please Select the position' })}
                                                >
                                                    <option value=''>Select position</option>
                                                    {
                                                        careerOpening?.map((el, ind) => (
                                                            <option key={ind} value={el?.position}>{el?.position}</option>
                                                        ))
                                                    }
                                                </select>
                                                {errors.position?.message && <p className='error-message'>{errors.position?.message}</p>}
                                            </div>
                                            <div>
                                                {
                                                    upLoadedCV ?
                                                        <div className='uploaded_file'>
                                                            <h5 className='upload_file_td'>
                                                                <FaRegFilePdf />
                                                                {upLoadedCV?.name?.slice(0, 20)}{upLoadedCV?.name?.length > 20 && '...'}
                                                            </h5>
                                                            <div>
                                                                <GrView className='view_pdf' onClick={onViewUploadedCV} />
                                                                <RiDeleteBin6Line className='dlt_pdf' onClick={() => {
                                                                    setUpLoadedCV(null)
                                                                    setValue('resume', '')
                                                                }} />
                                                            </div>
                                                        </div> :
                                                        <div className='parse_input_statement'>
                                                            <div >
                                                                <input id="fileUpload"
                                                                    style={{ display: 'none' }} className="d-d-hide"
                                                                    {...register("resume", { required: 'Resume is required' })}
                                                                    type='file' accept='.pdf,.doc,.docx'
                                                                    onChange={((e) => {
                                                                        setUpLoadedCV(e?.target?.files[0])
                                                                        setValue('resume', e?.target?.files[0])
                                                                        setError('resume', null)
                                                                    })}
                                                                />
                                                                <label htmlFor="fileUpload" style={{ cursor: 'pointer' }}>
                                                                    <div>
                                                                        <IoCloudUploadOutline />
                                                                    </div>
                                                                    <span>
                                                                        Upload Your CV
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                }


                                                {errors.resume?.message && <p className='error-message'>{errors.resume?.message}</p>}
                                            </div>


                                            <div className="form-group">
                                                <textarea className="form-control form-message" rows="5"
                                                    type='text'
                                                    placeholder="Enter Your Name...!"
                                                    {...register("summary", { required: 'Summary is required' })}
                                                />
                                                {errors.summary?.message && <p className='error-message'>{errors.summary?.message}</p>}
                                            </div>
                                            <div className="form-group career_ap_btn">
                                                <button onClick={handleSubmit(onSubmit)}>Apply now</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Careers
