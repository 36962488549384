import Modal from 'react-bootstrap/Modal';
import { VscDebugBreakpointData } from "react-icons/vsc";


function Details({ show, setShow, index, data }) {

    const handleClose = () => setShow(false);

    return (
        <>
            <Modal show={show === index} onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName="modal-80w"
                className='case_study_details_sec'
            >
                <Modal.Header closeButton>
                    <Modal.Title>{data?.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <article class="post-inner">
                        <h2 class="section-35px-barlow">Investment Planning</h2>
                        <p>Dorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                            exercitation ullamco laboris nisi ut aliquip ex ea commodo consequDuis aute irure.
                            Dorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                        <p >Her extensive perceived may any sincerity extremity. Indeed
                            add rather may pretty see. Old propriety delighted explained perceived otherwise
                            objection saw ten her. Doubt merit sir the right these alone keeps. By sometimes
                            intention smallness he northward. Consisted we otherwise arranging commanded
                            discovery it explained. Does cold even song like two yet been. Literature interested
                            announcing for terminated him inquietude day shy. Himself he fertile chicken perhaps
                            waiting if highest no it. Continued promotion has consulted fat improving not way.
                        </p>
                        <h2 class="section-35px-barlow">Research &amp; Analysis</h2>
                        <p>Dorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                            exercitation ullamco laboris nisi ut aliquip ex ea commodo consequDuis aute irure.
                            Dorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                        <ul class="work-process">
                            <li>
                                <div class="icon-tick"><VscDebugBreakpointData /></div>
                                <h4>Dorem ipsum dolor sit amet, consectetur</h4>
                            </li>
                            <li>
                                <div class="icon-tick"><VscDebugBreakpointData /></div>
                                <h4>Dorem ipsum dolor sit amet, consectetur</h4>
                            </li>
                            <li>
                                <div class="icon-tick"><VscDebugBreakpointData /></div>
                                <h4>Dorem ipsum dolor sit amet, consectetur</h4>
                            </li>
                            <li>
                                <div class="icon-tick"><VscDebugBreakpointData /></div>
                                <h4>Dorem ipsum dolor sit amet, consectetur</h4>
                            </li>
                        </ul>
                        <h2 class="section-35px-barlow">Company Benefits</h2>
                        <p>Dorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                            exercitation ullamco laboris nisi ut aliquip ex ea commodo consequDuis aute irure.
                            Dorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                    </article>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Details;