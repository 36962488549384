import React from 'react'
import Jayalakshmi from '../assert/images/team/Jayalakshmi.png'
import Sathyaraj from '../assert/images/team/Sathyaraj.png'
import akil from '../assert/images/team/aghil.png'
import lakshmi from '../assert/images/team/lakshmi.png'
import Moorthi from '../assert/images/team/moorthi.png'
import Narayanan from '../assert/images/team/narayanan.png'
import priya from '../assert/images/team/priya.png'
import rajith from '../assert/images/team/ranjith.png'
import suresh from '../assert/images/team/suresh.png'

const Team = () => {

    const teamsList = [
        {
            "name": "Aghil Chendur S",
            // "position": "Software Engineer",
            "image": akil
        },
        {
            "name": "Jayalakshmi R",
            // "position": "Project Manager",
            "image": Jayalakshmi
        },
        {
            "name": "Lachmi Narayanan S",
            // "position": "Data Scientist",
            "image": lakshmi
        },
        {
            "name": "Moorthi D",
            // "position": "UX Designer",
            "image": Moorthi
        },
        {
            "name": "Narayanamoorthy B",
            // "position": "Financial Analyst",
            "image": Narayanan
        },
        {
            "name": "Padma Priyaa B",
            // "position": "Marketing Specialist",
            "image": priya
        },
        {
            "name": "Rajkumar D",
            // "position": "Marketing Specialist",
            "image": rajith
        },
        {
            "name": "Sathyaraj M",
            // "position": "Marketing Specialist",
            "image": Sathyaraj
        },
        {
            "name": "Suresh M",
            // "position": "Marketing Specialist",
            "image": suresh
        },
    ]

    return (
        <div class="flat-client">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="section-title text-center mb-5">
                            <h3 class="section-heading">Our Teams</h3>
                            <div class="features-content-left">
                                <h2>Our Expert People Will Help You</h2>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="row our_team_members justify-content-center">
                            {
                                teamsList?.map((el, ind) => (
                                    <div className='col-lg-3 col-md-3 col-sm-6 col-12 mb-4' key={ind}>
                                        <div class="client-content-box wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                            <div class="client-author">
                                                <img src={el?.image} alt="images" />
                                                <div class="mark-client"></div>
                                            </div>
                                            <h3 class="client-name">{el?.name}</h3>
                                            <h4 class="client-job">{el?.position}</h4>
                                            <div class="top item-shape item-shape1"></div>
                                            <div class="top item-shape item-shape2"></div>
                                            <div class="bottom item-shape item-shape1"></div>
                                            <div class="bottom item-shape item-shape2"></div>
                                        </div>
                                    </div>
                                ))
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Team
