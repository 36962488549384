import React from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AboutUs from "../../pages/AboutUs";
import Blog from "../../pages/Blog";
import Careers from "../../pages/Careers";
import CaseStudies from "../../pages/CaseStudies";
import Contact from "../../pages/Contact";
import Home from "../../pages/Home";
import Attendance from "../../pages/internalUser/Attendance";
import DashBoard from "../../pages/internalUser/DashBoard";
import Leaves from "../../pages/internalUser/Leaves";
import Projects from "../../pages/internalUser/Projects";
import Media from "../../pages/Media";
import Services from "../../pages/Services";
import BlogDetail from "../blog/BlogDetail";
import Login from "../dashBoard/auth/Login";
import ServicesDetail from "../servicess/ServicesDetail";
import SubServicess from "../servicess/SubServicess";
import PrivateRoute from "./PrivateRoute";

const RoutesList = () => {
    const isAuthenticated = true; // Replace with your actual authentication logic

    return (
        <BrowserRouter>
            <Routes>
                {/* public route */}
                <Route path="/" element={<Home />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/services" element={<Services />} />
                <Route path="/sub-servicess" element={<SubServicess />} />
                <Route path="/servicess-details" element={<ServicesDetail />} />
                <Route path="/case-studies" element={<CaseStudies />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/media" element={<Media />} />
                <Route path="/careers" element={<Careers />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/blog-detail" element={<BlogDetail />} />

                {/* admin route */}
                <Route path="/employee-login" element={<Login />} />

                {/* private route */}
                <Route path="/dash-board" element={<PrivateRoute isAuthenticated={isAuthenticated} element={DashBoard} />} />
                <Route path="/project" element={<PrivateRoute isAuthenticated={isAuthenticated} element={Projects} />} />
                <Route path="/attendance" element={<PrivateRoute isAuthenticated={isAuthenticated} element={Attendance} />} />
                <Route path="/leave" element={<PrivateRoute isAuthenticated={isAuthenticated} element={Leaves} />} />
            </Routes>
        </BrowserRouter>
    );
};

export default RoutesList;
