import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import createSagaMiddleware from "redux-saga";
import reducers from "./Reducers";
import rootSaga from "./Sagas";

// Create Redux Saga middleware
const sagaMiddleware = createSagaMiddleware();

// Combine reducers
const rootReducers = combineReducers({ ...reducers });

// Configure store with middleware
const store = configureStore({
  reducer: rootReducers,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
});

// Run root saga
sagaMiddleware.run(rootSaga);

export { store };
