import React from 'react'
import Header from '../components/common/Header'
import Footer from '../components/common/Footer'
import SubBanner from '../components/common/SubBanner'
import AboutCompany from '../components/aboutUs/AboutCompany'
import Experience from '../components/aboutUs/Experience'
import Team from '../components/aboutUs/Team'
import { ScrollToTop } from '../components/common/Helper'
import BannerImg from '../components/assert/images/subBanner/AboutUs.jpg'

const AboutUs = () => {
    ScrollToTop(0, 0)
    return (
        <div>
            <Header />
            <SubBanner title='About us' pageName='About' image={BannerImg} />
            <AboutCompany />
            <Experience />
            <section class="tf-space flat-client">
                <Team />
            </section>
            <Footer />
        </div>
    )
}

export default AboutUs