import React from 'react';
import { HiOutlineHome } from "react-icons/hi2";
import { Link } from 'react-router-dom';

const BrudCrumb = ({ title, navLinks }) => {
    return (
        <div className='dash_bredcrumb'>
            <h2>{title}</h2>
            <nav aria-label="breadcrumb" className='bredCrub_sec'>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link href="#"><HiOutlineHome /></Link></li>
                    <li className="breadcrumb-item"><Link href="#">{title}</Link></li>
                    {
                        navLinks?.map((el) => (
                            <li className="breadcrumb-item"><Link to={el?.url}>{el?.pageName}</Link></li>
                        ))
                    }
                </ol>
            </nav>
        </div>
    )
}

export default BrudCrumb
