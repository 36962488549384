import React, { useEffect, useState } from "react";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

const Pagination = ({ data, pageName, setData, perPage, total, setViewStackTrace }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(total / perPage);
  const maxPagesToShow = 5;
  const sidePages = Math.floor(maxPagesToShow / 2);

  const calculatePageNumbers = () => {
    let firstPageNumber = Math.max(1, currentPage - sidePages);
    let lastPageNumber = Math.min(totalPages, firstPageNumber + maxPagesToShow - 1);

    if (lastPageNumber - firstPageNumber < maxPagesToShow - 1) {
      firstPageNumber = Math.max(1, lastPageNumber - maxPagesToShow + 1);
    }

    const pageNumbers = [];
    for (let i = firstPageNumber; i <= lastPageNumber; i++) {
      pageNumbers.push(i);
    }

    if (firstPageNumber > 2) {
      pageNumbers.unshift("...");
      pageNumbers.unshift(1);
    } else {
      for (let i = 1; i < firstPageNumber; i++) {
        pageNumbers.unshift(i);
      }
    }

    if (lastPageNumber < totalPages - 1) {
      pageNumbers.push("...");
      pageNumbers.push(totalPages);
    } else {
      for (let i = lastPageNumber + 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    }

    return pageNumbers;
  };

  const handlePageChange = (pageNumber) => {
    if (pageNumber !== currentPage) {
      setCurrentPage(pageNumber);
      localStorage.setItem(pageName, pageNumber);
      if (setViewStackTrace) {
        setViewStackTrace(null);
      }
    }
  };

  useEffect(() => {
    const indexOfLastProduct = currentPage * perPage;
    const indexOfFirstProduct = indexOfLastProduct - perPage;
    const tempData = data.map((el, ind) => ({ ...el, sno: ind + 1 }));
    const currentProducts = tempData.slice(indexOfFirstProduct, indexOfLastProduct);
    setData(currentProducts);
  }, [currentPage, perPage, data, setData]);

  useEffect(() => {
    if (totalPages && currentPage > totalPages) {
      setCurrentPage(1);
    }
  }, [totalPages, currentPage]);

  useEffect(() => {
    const storedPage = parseFloat(localStorage.getItem(pageName));
    if (storedPage) {
      setCurrentPage(storedPage);
    }
  }, [pageName]);

  const pageNumbers = calculatePageNumbers();

  return (
    total > perPage && (
      <div className="mt-2 d-flex pagination_list_section">
        <button
          onClick={() => handlePageChange(Math.max(1, currentPage - 1))}
          className="table-pagination-left-right"
        >
          <MdKeyboardArrowLeft />
        </button>
        <nav>
          <ul className="table-pagination">
            {pageNumbers.map((number, index) => (
              <li key={index} className="page-item">
                {number === "..." ? (
                  <button className="disabled">...</button>
                ) : (
                  <button
                    onClick={() => handlePageChange(number)}
                    className={number === currentPage ? "active" : ""}
                  >
                    {number}
                  </button>
                )}
              </li>
            ))}
          </ul>
        </nav>
        <button
          onClick={() => handlePageChange(Math.min(totalPages, currentPage + 1))}
          className="table-pagination-left-right"
        >
          <MdKeyboardArrowRight />
        </button>
      </div>
    )
  );
};

export default Pagination;
