import React, { useEffect } from 'react';
// import banner from '../assert/images/banner/banner1.jpg'
// import banner2 from '../assert/images/banner/banner2.jpg'
import OwlCarousel from 'react-owl-carousel';
import { useDispatch, useSelector } from 'react-redux';
import CommonAction from '../../redex/Common/Actions';
import { API_URL } from '../../utils/Constant';



const Banner = () => {
    const dispatch = useDispatch()
    const { bannerList } = useSelector((state) => state.CommonReducer)

    const options = {
        items: 1,
        loop: true,
        margin: 10,
        nav: true,
        navText: [<span>prev</span>, <span>prev</span>],
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 1,
            },
            1000: {
                items: 1,
            },
        },
    };

    useEffect(() => {
        dispatch({ type: CommonAction.GET_BANNER })
    }, [dispatch])

    return (
        <div>

            <section className="flat-slider">
                <div className="container-fluid">
                    <div className="row">

                        <div className="swiper-container mainslider">
                            <div className="swiper-wrapper home_banner_slider">
                                <OwlCarousel className='home_banner owl-theme' {...options}>
                                    {bannerList?.map((el, ind) => (
                                        <div className="swiper-slide" key={ind}>
                                            <div className="slider-item" style={{
                                                backgroundImage: `url(${API_URL}${el?.banner_image})`,
                                                backgroundRepeat: 'no-repeat',
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'top center',
                                                position: 'relative'
                                            }}>
                                                <div className='overlay_banner'></div>
                                                <div className='container'>
                                                    <div className="slider-left">
                                                        <div className="slider-left-content">
                                                            <h3>{el?.sub_title}</h3>
                                                            <h2>{el?.main_title}</h2>
                                                            <p>{el?.description}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    ))}
                                </OwlCarousel>
                            </div>
                            {/* <div className="swiper-button-next btn-slide-next"></div>
                            <div className="swiper-button-prev btn-slide-prev active"></div> */}
                        </div>


                    </div>
                </div>
            </section>


        </div>
    )
}

export default Banner
