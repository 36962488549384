import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import InternalUserAction from '../../../redex/InternalUser/Actions';

const CheckIn = () => {
  const dispatch = useDispatch();
  const { checkInModel } = useSelector((state) => state.InternalUserReducer);

  const handleClose = () => {
    dispatch({ type: InternalUserAction.CHECK_IN_MODEL, payload: false });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleClose();
  };

  return (
    <>
      <Modal show={checkInModel?.open} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Check In</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="chckIn_form" onSubmit={handleSubmit}>
            <div className="mb-3">
              <label className="form-label">Notes</label>
              <input name="notes" placeholder="notes" className="form-control" />
            </div>
            <div className="icon_btn login_btn">
              <button type="submit">Submit</button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CheckIn;
