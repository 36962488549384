import React from 'react';
import BrudCrumb from '../../components/common/BrudCrumb';
import DashNav from "../../components/common/DashNav";
import Content from '../../components/dashBoard/leaves/content';

const Leaves = () => {
    return (
        <DashNav>
            <BrudCrumb title='Leave' />
            <Content />
        </DashNav>
    )
}

export default Leaves
