import React from 'react';
import BrudCrumb from '../../components/common/BrudCrumb';
import DashNav from "../../components/common/DashNav";
import Content from '../../components/dashBoard/dashboard/content';

const DashBoard = () => {
    return (
        <DashNav>
            <BrudCrumb title='Dash Board' />
            <Content />
        </DashNav>
    )
}

export default DashBoard
