import React from 'react';
import { BsPersonWorkspace } from "react-icons/bs";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { MdVerified } from "react-icons/md";
import { PiHandshakeFill } from "react-icons/pi";
import { Link } from 'react-router-dom';
import aboutUsImg from '../assert/images/image/aboutUs.jpg';


const AboutCompany = () => {
    return (
        <section class="tf-space flat-about-2">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <div class="about-2-content-left wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                            <div class="section-title st-2">
                                <h3 class="section-heading">Astral Consulting Limited</h3>
                                <div class="features-content-left">
                                    <h3>Your Trusted Partner<br />
                                        in Business Growth</h3>
                                </div>
                                <p>Business Growth
                                    Astral Consulting Limited is a leading Coimbatore, Tamil Nadu-based consulting company with over 30 years of experience empowering businesses across India and the globe.
                                </p>
                            </div>
                            <div class="about-our-company">
                                <div class="icon-tick"></div>
                                <div class="content-box">
                                    <h4>Business strategy growth</h4>
                                    <p>We are a team of nearly 30 dedicated consultants guided by 2 experienced Directors.</p>
                                </div>
                            </div>
                            <div class="about-our-company">
                                <div class="icon-tick"></div>
                                <div class="content-box">
                                    <h4>Finance valuable ideas</h4>
                                    <p>Quality, Ethics, People, and Society are the cornerstones of our approach. </p>
                                </div>
                            </div>
                            <div class="button-contact wow fadeInUp" data-wow-delay="500ms" data-wow-duration="1500ms">
                                <Link to="/contact" class="button readmore btn-style3">Contact us</Link>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="about-2-content-right">
                            <div class="about-2-post wow fadeInRight" data-wow-delay="0ms" data-wow-duration="1500ms">
                                <img className='abt_us_img' src={aboutUsImg} alt="images" />
                            </div>
                            <div class="about-counter-box tf-counter wow fadeInUp" data-wow-delay="500ms"
                                data-wow-duration="1500ms">
                                <div class="about-counter-inner">
                                    <div class="number-counter">
                                        <span class="number" data-speed="1000" data-to="30" data-inviewport="yes">30</span>
                                    </div>
                                    <div class="heading-count">
                                        <h3>YEARS<br />
                                            EXPERIENCE</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center mt-5">
                    <div class="col-md-12">
                        <div class="about-2-content-left wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                            <div class="section-title st-2 t-c">
                                <h3 class="section-heading">Our Expertise</h3>
                                <div class="features-content-left">
                                    <h3 className='bf-n'>A Holistic Suite of
                                        Consulting Services</h3>
                                </div>
                                <p>We offer a comprehensive range of services designed to address your specific needs and propel your organization forward. Our core competencies lie in four key areas</p>
                            </div>

                            <div className='row ptb-3'>
                                <div className='col-lg-6 col-md-6 col-sm-12 cpl-12'>
                                    <Link class="hover-card education">
                                        <div class="overlay"></div>
                                        <div class="circle">
                                            <MdVerified />
                                        </div>
                                        <p>Assurance</p>
                                        <span>Our assurance services encompass a wide array of solutions, including internal audits, risk management, and compliance assessments. We ensure the integrity of your financial processes and safeguard your organization's reputation.</span>
                                    </Link>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12 cpl-12'>
                                    <Link class="hover-card education">
                                        <div class="overlay"></div>
                                        <div class="circle">
                                            <PiHandshakeFill />
                                        </div>
                                        <p>Consulting</p>
                                        <span>We provide expert guidance across various disciplines, including IT consulting, performance management systems implementation, and organizational restructuring. We help you optimize operations, streamline processes, and achieve your strategic goals</span>
                                    </Link>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12 cpl-12'>
                                    <Link class="hover-card education">
                                        <div class="overlay"></div>
                                        <div class="circle">
                                            <HiOutlineBuildingOffice2 />
                                        </div>
                                        <p>Outsourcing</p>
                                        <span> Focus on your core business while we handle essential back-office tasks efficiently. Our outsourcing services include shared CFO solutions, bookkeeping, and payroll management.  </span>
                                    </Link>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12 cpl-12'>
                                    <Link class="hover-card education">
                                        <div class="overlay"></div>
                                        <div class="circle">
                                            <BsPersonWorkspace />
                                        </div>
                                        <p>Forensic Services</p>
                                        <span> Our team of specialists offers forensic audit, vendor due diligence, and whistleblower program implementation to mitigate risk and ensure transparency. </span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 sub_abt_con">
                        <div class="about-2-content-left wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                            <div class="section-title st-2">
                                <div class="features-content-left">
                                    <h3 class="section-heading  abt_hed">A Team of Seasoned Professionals</h3>
                                </div>
                                <p>Our team comprises highly qualified professionals, including Chartered Accountants, Certified Information Systems Auditors, Certified Internal Auditors, Certified Fraud Examiners, Engineers, and MBAs. This diverse pool of expertise allows us to cater to your unique requirements effectively</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 sub_abt_con">
                        <div class="about-2-content-left wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                            <div class="section-title st-2">
                                <div class="features-content-left">
                                    <h3 class="section-heading abt_hed">Global Reach, Industry-Specific Solutions</h3>
                                </div>
                                <p>We have a proven track record of conducting audits in over 15 countries. Our in-depth understanding spans various industries, including Manufacturing (Discrete & Process), Supply Chain Management, Trading, Services, Education, Software Development, Agriculture, and more.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 sub_abt_con">
                        <div class="about-2-content-left wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                            <div class="section-title st-2">
                                <div class="features-content-left">
                                    <h3 class="section-heading abt_hed">Choose Astral Consulting Limited: Your Path to Success</h3>
                                </div>
                                <p>Partner with Astral Consulting Limited to unlock your organization's full potential. We are committed to delivering exceptional service, fostering long-term relationships, and empowering your business to thrive.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutCompany
