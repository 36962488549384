import React from 'react'
import { Link } from 'react-router-dom'
import { ScrollToTop } from '../common/Helper'
import servicesData from './serviesContent'

const Services = () => {
    ScrollToTop(0, 0)
    return (
        <section class="tf-space flat-service">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <div class="section-title wow fadeInDown" data-wow-delay="0ms" data-wow-duration="1500ms">
                            <div class="features-content-left">
                                <h2>Our Business & Financial<br />
                                    Consulting Services</h2>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="service-content-right wow fadeInDown" data-wow-delay="0ms"
                            data-wow-duration="1500ms">
                            <p>Partner with Astral Consulting Limited to unlock your organization's full potential. We are committed to delivering exceptional service, fostering long-term relationships, and empowering your business to thrive.</p>
                        </div>
                    </div>
                    <div className='row j-c'>
                        {servicesData?.map((el, ind) => (
                            <Link key={ind} to={'/sub-servicess'} state={el} class="col-md-4">
                            <div>
                                <div class="service-box">
                                    <div class="services-post">
                                        <img src={el?.image} alt="images" />
                                    </div>
                                    <div class="service-content">
                                        <div class="inner">
                                            {/* <h4>Services</h4> */}
                                            <h3>{el?.title}</h3>
                                            {/* <hr /> */}
                                        </div>
                                        {/* <Link to={'/sub-servicess'} state={el} class="readmore">More</Link> */}
                                    </div>
                                </div>
                            </div>
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </section >
    )
}

export default Services
