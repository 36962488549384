import React from 'react'

const UnderMaintanance = () => {
    return (
        <div className='coming_soon_sec'>
            <div>
                <span>Astral Consulting</span>
                <h4>Under Construction...!</h4>
            </div>
        </div>
    )
}

export default UnderMaintanance
