import React from 'react'
import { Link } from 'react-router-dom'
import BannerImg from '../components/assert/images/subBanner/blog.jpg'
import Footer from '../components/common/Footer'
import Header from '../components/common/Header'
import { ScrollToTop } from '../components/common/Helper'
import SubBanner from '../components/common/SubBanner'
import UnderMaintanance from '../components/common/UnderMaintanance'

const Blog = () => {
    ScrollToTop(0, 0)
    return (
        <div>
            <Header />
            <SubBanner title='Blog' pageName='Blog' image={BannerImg}/>
            <section class="flat-blog">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8 col-md-12">
                            <div class="post-wrap">
                                <article class="post-content-archive grow-up-hover">
                                    <div class="post-media">
                                        <img src="assets/images/image-box/blog-post-1%402x.jpg" class="grow-up-hover"
                                            alt="images" />
                                        <div class="post-media-tags">
                                            <span>Creative</span>
                                        </div>
                                    </div>
                                    <div class="post-content-wrap">
                                        <div class="post-meta link-style-3">
                                            <span class="date section-14px-regular-rubik"><Link to="/blog-detail">December
                                                7,
                                                2021</Link></span>
                                            <span class="author section-14px-regular-rubik"><Link to="/blog-detail">by Jhon
                                                gray</Link></span>
                                        </div>
                                        <div class="post-title section-22px-barlow link-style-2"><Link
                                            to="/blog-detail">Top 15
                                            Secrets Of Digital Transformation</Link></div>
                                        <div class="post-readmore">
                                            <Link to="/blog-detail" class="read-more"></Link>
                                        </div>
                                    </div>
                                </article>
                                <article class="post-content-archive grow-up-hover">
                                    <div class="post-media">
                                        <img src="assets/images/image-box/blog-post-2%402x.jpg" class="grow-up-hover"
                                            alt="images" />
                                        <div class="post-media-tags">
                                            <span>Develop</span>
                                        </div>
                                    </div>
                                    <div class="post-content-wrap">
                                        <div class="post-meta link-style-3">
                                            <span class="date section-14px-regular-rubik"><Link to="/blog-detail">December
                                                7,
                                                2021</Link></span>
                                            <span class="author section-14px-regular-rubik"><Link to="/blog-detail">by Jhon
                                                gray</Link></span>
                                        </div>
                                        <div class="post-title section-22px-barlow link-style-2"><Link
                                            to="/blog-detail">Transforming Challenge into Opportunities</Link>
                                        </div>
                                        <div class="post-readmore">
                                            <Link to="/blog-detail" class="read-more"></Link>
                                        </div>
                                    </div>
                                </article>
                                <article class="post-content-archive grow-up-hover">
                                    <div class="post-media">
                                        <img src="assets/images/image-box/blog-post-3%402x.jpg" class="grow-up-hover"
                                            alt="images" />
                                        <div class="post-media-tags">
                                            <span>Marketing</span>
                                        </div>
                                    </div>
                                    <div class="post-content-wrap">
                                        <div class="post-meta link-style-3">
                                            <span class="date section-14px-regular-rubik"><Link to="/blog-detail">December
                                                7,
                                                2021</Link></span>
                                            <span class="author section-14px-regular-rubik"><Link to="/blog-detail">by Jhon
                                                gray</Link></span>
                                        </div>
                                        <div class="post-title section-22px-barlow link-style-2"><Link
                                            to="/blog-detail">How AI is
                                            Transforming the Healthcare System</Link></div>
                                        <div class="post-readmore">
                                            <Link to="/blog-detail" class="read-more"></Link>
                                        </div>
                                    </div>
                                </article>
                                <article class="post-content-archive grow-up-hover">
                                    <div class="post-media">
                                        <img src="assets/images/image-box/blog-post-4%402x.jpg" class="grow-up-hover"
                                            alt="images" />
                                        <div class="post-media-tags">
                                            <span>Digital</span>
                                        </div>
                                    </div>
                                    <div class="post-content-wrap">
                                        <div class="post-meta link-style-3">
                                            <span class="date section-14px-regular-rubik"><Link to="/blog-detail">December
                                                7,
                                                2021</Link></span>
                                            <span class="author section-14px-regular-rubik"><Link to="/blog-detail">by Jhon
                                                gray</Link></span>
                                        </div>
                                        <div class="post-title section-22px-barlow link-style-2"><Link
                                            to="/blog-detail">How to
                                            Build a Chatbot: Definition & Process</Link></div>
                                        <div class="post-readmore">
                                            <Link to="/blog-detail" class="read-more"></Link>
                                        </div>
                                    </div>
                                </article>
                                <article class="post-content-archive grow-up-hover">
                                    <div class="post-media">
                                        <img src="assets/images/image-box/blog-post-5%402x.jpg" class="grow-up-hover"
                                            alt="images" />
                                        <div class="post-media-tags">
                                            <span>Creative</span>
                                        </div>
                                    </div>
                                    <div class="post-content-wrap">
                                        <div class="post-meta link-style-3">
                                            <span class="date section-14px-regular-rubik"><Link to="/blog-detail">December
                                                7,
                                                2021</Link></span>
                                            <span class="author section-14px-regular-rubik"><Link to="/blog-detail">by Jhon
                                                gray</Link></span>
                                        </div>
                                        <div class="post-title section-22px-barlow link-style-2"><Link
                                            to="/blog-detail">Augmented
                                            Reality Platform By Google</Link></div>
                                        <div class="post-readmore">
                                            <Link to="/blog-detail" class="read-more"></Link>
                                        </div>
                                    </div>
                                </article>
                                <article class="post-content-archive grow-up-hover">
                                    <div class="post-media">
                                        <img src="assets/images/image-box/blog-post-6%402x.jpg" class="grow-up-hover"
                                            alt="images" />
                                        <div class="post-media-tags">
                                            <span>Creative</span>
                                        </div>
                                    </div>
                                    <div class="post-content-wrap">
                                        <div class="post-meta link-style-3">
                                            <span class="date section-14px-regular-rubik"><Link to="/blog-detail">December
                                                7,
                                                2021</Link></span>
                                            <span class="author section-14px-regular-rubik"><Link to="/blog-detail">by Jhon
                                                gray</Link></span>
                                        </div>
                                        <div class="post-title section-22px-barlow link-style-2"><Link
                                            to="/blog-detail">What to
                                            Expect from UX/UI Trends for 2020</Link></div>
                                        <div class="post-readmore">
                                            <Link to="/blog-detail" class="read-more"></Link>
                                        </div>
                                    </div>
                                </article>
                                <article class="post-content-archive grow-up-hover">
                                    <div class="post-media">
                                        <img src="assets/images/image-box/blog-post-7%402x.jpg" class="grow-up-hover"
                                            alt="images" />
                                        <div class="post-media-tags">
                                            <span>Maxpro</span>
                                        </div>
                                    </div>
                                    <div class="post-content-wrap">
                                        <div class="post-meta link-style-3">
                                            <span class="date section-14px-regular-rubik"><Link to="/blog-detail">December
                                                7,
                                                2021</Link></span>
                                            <span class="author section-14px-regular-rubik"><Link to="/blog-detail">by Jhon
                                                gray</Link></span>
                                        </div>
                                        <div class="post-title section-22px-barlow link-style-2"><Link
                                            to="/blog-detail">5 Tech
                                            Trends of 2020 Every CIO Needs to Know</Link></div>
                                        <div class="post-readmore">
                                            <Link to="/blog-detail" class="read-more"></Link>
                                        </div>
                                    </div>
                                </article>
                                <article class="post-content-archive grow-up-hover">
                                    <div class="post-media">
                                        <img src="assets/images/image-box/blog-post-1%402x.jpg" class="grow-up-hover"
                                            alt="images" />
                                        <div class="post-media-tags">
                                            <span>Sinzenta</span>
                                        </div>
                                    </div>
                                    <div class="post-content-wrap">
                                        <div class="post-meta link-style-3">
                                            <span class="date section-14px-regular-rubik"><Link to="/blog-detail">December
                                                7,
                                                2021</Link></span>
                                            <span class="author section-14px-regular-rubik"><Link to="/blog-detail">by Jhon
                                                gray</Link></span>
                                        </div>
                                        <div class="post-title section-22px-barlow link-style-2"><Link
                                            to="/blog-detail">12 Top
                                            Software Develop Methodologi</Link></div>
                                        <div class="post-readmore">
                                            <Link to="/blog-detail" class="read-more"></Link>
                                        </div>
                                    </div>
                                </article>
                                <div class="themesflat-pagination clearfix">
                                    <ul>
                                        <li><Link href="#" class="page-numbers">01</Link></li>
                                        <li><Link href="#" class="page-numbers">02</Link></li>
                                        <li><Link href="#" class="page-numbers current">03</Link></li>
                                        <li><Link href="#" class="page-numbers">04</Link></li>
                                        <li><Link href="#" class="page-numbers">05</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12">
                            <aside class="side-bar">

                                <div class="widget widget-category">
                                    <h3 class="widget-title section-24px-barlow">
                                        Categories
                                    </h3>
                                    <ul>
                                        <li class="cat-item active"><Link to="/blog-detail">Graphics
                                            Design</Link><span>36</span></li>
                                        <li class="cat-item"><Link to="/blog-detail">ui/ux brand design</Link><span>32</span>
                                        </li>
                                        <li class="cat-item"><Link to="/blog-detail">web devolopment</Link><span>26</span></li>
                                        <li class="cat-item"><Link to="/blog-detail">branding design</Link><span>16</span></li>
                                        <li class="cat-item"><Link to="/blog-detail">ui/ux brand design</Link><span>32</span>
                                        </li>
                                    </ul>
                                </div>
                                <div class="widget widget-recent-post">
                                    <h3 class="widget-title section-24px-barlow">
                                        Recent Post
                                    </h3>
                                    <ul class="recent-news link-style-2">
                                        <li>
                                            <div class="thumb"><img src="assets/images/image-box/recent-post-1.jpg"
                                                alt="images" /></div>
                                            <div class="texts">
                                                <h3><Link to="/blog-detail">Lorem Ipsum market the printing
                                                    typesetting.</Link></h3>
                                                <span class="post-date">december 7, 2021</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="thumb"><img src="assets/images/image-box/recent-post-2.jpg"
                                                alt="images" /></div>
                                            <div class="texts">
                                                <h3><Link to="/blog-detail">Lorem Ipsum market the printing
                                                    typesetting.</Link></h3>
                                                <span class="post-date">december 7, 2021</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="thumb"><img src="assets/images/image-box/recent-post-3.jpg"
                                                alt="images" /></div>
                                            <div class="texts">
                                                <h3><Link to="/blog-detail">Lorem Ipsum market the printing
                                                    typesetting.</Link></h3>
                                                <span class="post-date">december 7, 2021</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
            <UnderMaintanance/>
            <Footer />
        </div>
    )
}

export default Blog
