import React, { useState } from 'react'
import image5 from '../components/assert/images/media/1.jpeg'
import image4 from '../components/assert/images/media/2.jpg'
import image3 from '../components/assert/images/media/3.jpg'
import image2 from '../components/assert/images/media/4.jpg'
import image1 from '../components/assert/images/media/5.jpg'
import image6 from '../components/assert/images/media/6.jpg'
import image7 from '../components/assert/images/media/7.jpg'
import BannerImg from '../components/assert/images/subBanner/media.webp'
import backgroundImg from '../components/assert/images/wallbg.jpeg'
import Footer from '../components/common/Footer'
import Header from '../components/common/Header'
import { ScrollToTop } from '../components/common/Helper'
import SubBanner from '../components/common/SubBanner'

import { TbPlayerTrackNextFilled, TbPlayerTrackPrevFilled } from "react-icons/tb"
import image11 from '../components/assert/images/media/10.jpeg'
import image12 from '../components/assert/images/media/11.jpeg'
import image13 from '../components/assert/images/media/12.jpeg'
import image14 from '../components/assert/images/media/13.jpeg'
import image10 from '../components/assert/images/media/14.jpeg'
import image8 from '../components/assert/images/media/8.jpeg'
import image9 from '../components/assert/images/media/9.jpeg'

const Media = () => {
    ScrollToTop(0, 0)
    const [viewedImg, setViewedImg] = useState('')
    const media = [
        {
            image: image8,
            title: 'Annual Day Celebration'
        },
        {
            image: image9,
            title: 'Annual Day Celebration'
        },
        {
            image: image10,
            title: 'Annual Day Celebration'
        },
        {
            image: image11,
            title: 'Corporate Trip'
        },
        {
            image: image12,
            title: 'Corporate Trip'
        },
        {
            image: image13,
            title: 'Corporate Trip'
        },
        {
            image: image14,
            title: 'Corporate Trip'
        },
        {
            image: image6,
            title: 'Awards'
        },
        {
            image: image1,
            title: 'Annual Day Celebration'
        },
        {
            image: image2,
            title: 'Annual Day Celebration'
        },
        {
            image: image3,
            title: 'Annual Day Celebration'
        },
        {
            image: image4,
            title: 'Awards'
        },
        {
            image: image5,
            title: 'Corporate Trip'
        },
        {
            image: image7,
            title: 'Corporate Trip'
        },
    ]



    const OnPrev = (indx) => {
        if (indx > 0) {
            setViewedImg({ image: media[indx - 1]?.image, indx: indx - 1 })
        }
    }

    console.log(viewedImg)

    const OnNxt = (indx) => {
        if (indx <= media?.length) {
            setViewedImg({ image: media[indx + 1]?.image, indx: indx + 1 })
        }
    }

    return (
        <div>
            <Header />
            <SubBanner title='Media' pageName='Media' image={BannerImg} />
            <div className='media_sec p-r' style={{ backgroundImage: `url(${backgroundImg})` }}>
                <div className='container'>
                    <div id="gallery" className='tf-space '>
                        {
                            media?.map((el, ind) => (
                                <figure key={ind} className='c-p' onClick={() => setViewedImg({
                                    image: el?.image,
                                    indx: ind
                                })}>
                                    <img src={el?.image} alt='' />
                                    <figcaption>{el?.title}</figcaption>
                                </figure>
                            ))
                        }
                    </div>
                </div>
                {
                    viewedImg?.image &&
                    <div className='gallery_model'>
                        <button className='model_close' onClick={() => setViewedImg('')}>X</button>
                        <div className='model_bg_img'
                            style={{
                                backgroundImage: `url(${viewedImg?.image})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center center',
                                position: 'relative',
                            }}></div>
                        <div className='nxt_pr_arrow'>
                            <button onClick={() => OnPrev(viewedImg?.indx)}><TbPlayerTrackPrevFilled /></button>
                            <button onClick={() => OnNxt(viewedImg?.indx)}><TbPlayerTrackNextFilled /></button>
                        </div>

                    </div>
                }
            </div>

            <Footer />
        </div>
    )
}

export default Media
