import axios from "axios";
import { all, put, takeEvery } from "redux-saga/effects";
import { API_URL } from "../../utils/Constant";
import actions from './Actions';

function* CommonSaga() {
    yield all([
        yield takeEvery(actions.GET_BANNER, getBanner),
        yield takeEvery(actions.GET_MANAGEMENT, getManagement)
    ])
}

function* getBanner(data) {
    yield put({ type: actions.SET_LOADER, payload: true })
    try {
        const response = yield axios.get(`${API_URL}/banner_list.php`);
        if (response?.status === 200) {
            yield put({ type: actions.SET_BANNER, payload: response?.data })
            yield put({ type: actions.SET_LOADER, payload: false })
        } else {
            yield put({ type: actions.SET_BANNER, payload: [] })
            yield put({ type: actions.SET_LOADER, payload: false })
        }
    } catch (err) {
        yield put({ type: actions.SET_LOADER, payload: false })
    }
}

function* getManagement(data) {
    yield put({ type: actions.SET_LOADER, payload: true })
    try {
        const response = yield axios.get(`${API_URL}/mgmt_list.php`);
        if (response?.status === 200) {
            yield put({ type: actions.SET_MANAGEMENT, payload: response?.data })
            yield put({ type: actions.SET_LOADER, payload: false })
        } else {
            yield put({ type: actions.SET_MANAGEMENT, payload: [] })
            yield put({ type: actions.SET_LOADER, payload: false })
        }
    } catch (err) {
        yield put({ type: actions.SET_LOADER, payload: false })
    }
}

export default CommonSaga