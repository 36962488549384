import React from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import BannerImg from '../components/assert/images/subBanner/Contact.jpg'
import Footer from '../components/common/Footer'
import Header from '../components/common/Header'
import { ScrollToTop } from '../components/common/Helper'
import SubBanner from '../components/common/SubBanner'
import CommonAction from '../redex/Common/Actions'

const Contact = () => {
    const dispatch = useDispatch()
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm()
    ScrollToTop(0, 0)

    const onSubmit = async (data) => {
        try {
            const formData = new FormData();
            formData.append('Name', data?.name || '');
            formData.append('Mail', data?.mail || '');
            formData.append('subject', 'Contact Form');
            formData.append('message', data?.message || '');

            const response = await fetch('https://astralconsultants.com/backend/send_email_contact.php', {
                method: 'POST',
                body: formData
            });

            if (response.ok) {
                reset({
                    name: '',
                    mail: '',
                    message: ''
                });
                dispatch({ type: CommonAction.SET_TOASTER, payload: { open: true, message: 'Mail sent successfully!', type: '' } });
            } else {
                dispatch({ type: CommonAction.SET_TOASTER, payload: { open: true, message: 'Failed to send mail.', type: 'err' } });
            }
        } catch (error) {
            dispatch({ type: CommonAction.SET_TOASTER, payload: { open: true, message: 'Failed to send mail.', type: 'err' } });
        }
    }


    return (
        <div>
            <Header />
            <SubBanner title='Contact Us' pageName='Contact' image={BannerImg} />
            <section className="tf-space flat-contact">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="section-title link-style-2">
                                <div className="features-content-left">
                                    <h2>Speak With Our<br />
                                        Consultant</h2>
                                </div>
                                <div className="contact-icon-box wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="icon meta-address"></div>
                                    <div className="content w-70">
                                        <h4>No.14 - A, 4th floor , <br />Ramakrishna Nagar ,<br />New Sidhaputhur , <br />Coimbatore - 641044</h4>
                                    </div>
                                </div>
                                <div className="contact-icon-box wow fadeInUp" data-wow-delay="300ms" data-wow-duration="1500ms">
                                    <div className="icon meta-mail"></div>
                                    <div className="content w-70">
                                        <Link href="">
                                            <h4><span className="__cf_email__">contact@astralconsultants.com</span></h4>
                                        </Link>
                                    </div>
                                </div>
                                <div className="contact-icon-box wow fadeInUp" data-wow-delay="600ms" data-wow-duration="1500ms">
                                    <div className="icon meta-phone"></div>
                                    <div className="content w-70">
                                        <a href="tel:04222312548">
                                            <h4>0422 2312548</h4>
                                        </a>
                                        <a href="tel:04222312548">
                                            <h4>99949 12548</h4>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="title">
                                <h3>Don’t Hesitate<br />
                                    To Send Your Message To Us</h3>
                            </div>
                            <div className="contact-form wow fadeInRight" data-wow-delay="0ms" data-wow-duration="1500ms" id="comments">
                                <form id="contactform" className="comment-form form-submit">
                                    <div className="text-wrap clearfix row">
                                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                            <fieldset >
                                                <input type="text" className="tb-my-input" placeholder="Name*" size="32"
                                                    {...register("name", {
                                                        required: 'Name is required',
                                                        pattern: {
                                                            value: /^[A-Za-z -]+$/,
                                                            message: "Invalid name format"
                                                        }
                                                    })}
                                                />
                                            </fieldset>
                                            {errors.name?.message && <p className='error-message'>{errors.name?.message}</p>}
                                        </div>

                                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                            <fieldset >
                                                <input type="email" id="email" className="tb-my-input" placeholder="Email*" size="32"
                                                    {...register("mail", {
                                                        required: 'Mail is required',
                                                        pattern: {
                                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                            message: 'Invalid email address'
                                                        }
                                                    })}
                                                />
                                            </fieldset>
                                            {errors.mail?.message && <p className='error-message'>{errors.mail?.message}</p>}
                                        </div>
                                        <div className='col-lg-12'>
                                            <fieldset >
                                                <textarea id="comment-message" name="message" placeholder="write your comment"
                                                    cols="5" rows="5"
                                                    {...register("message", {
                                                        required: 'Message is required',
                                                    })}
                                                />
                                            </fieldset>
                                            {errors.message?.message && <p className='error-message'>{errors.message?.message}</p>}
                                        </div>

                                        <div className='col-lg-12'>
                                            <button onClick={handleSubmit(onSubmit)} className="button btn-style4 btn-submit-comment">Send Message</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="tf-map">
                <div className="container-fluid">
                    <div className="row">
                        <div className="inner">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3916.236130731885!2d76.9769876755738!3d11.020902189143115!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba859af9302ac01%3A0x9b990afe6c9ea013!2sAstral%20Consulting%20Ltd!5e0!3m2!1sen!2sin!4v1704803223490!5m2!1sen!2sin"
                                className="map-contact"
                                title="Map"
                                loading="lazy"
                            >
                            </iframe>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Contact
