import actions from './Actions'

const initialState = {
    checkInModel: { open: false, type: '' },
    editProfileModel: { open: false, type: '', data: '' },
}

const InternalUserReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.CHECK_IN_MODEL:
            return {
                ...state,
                checkInModel: action.payload
            }

        case actions.EDIT_PROFILE_MODEL:
            return {
                ...state,
                editProfileModel: action.payload
            }
        default:
            return state
    }
}

export default InternalUserReducer