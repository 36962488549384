import React, { useEffect } from 'react'
import Header from '../components/common/Header'
import Banner from '../components/Home/Banner'
import About from '../components/Home/About'
import WhatWeDo from '../components/Home/WhatWeDo'
import OurManagement from '../components/Home/OurManagement'
import Footer from '../components/common/Footer'
import OurServices from '../components/Home/OurServices'
import CommonAction from '../redex/Common/Actions'
import { useDispatch } from 'react-redux'
import { ScrollToTop } from '../components/common/Helper'

const Home = () => {
    ScrollToTop(0, 0)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch({ type: CommonAction.GET_BANNER })
    }, [dispatch])
    return (
        <div>
            <Header />
            <Banner />
            <About />
            <OurServices />
            <WhatWeDo />
            <div className='flat-client'>
                <OurManagement />
            </div>
            <Footer />
        </div>
    )
}

export default Home
