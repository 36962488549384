import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { BsCalendar4Week } from "react-icons/bs";
import { FaChartLine } from "react-icons/fa";
import { HiMenuAlt1 } from "react-icons/hi";
import { IoCalendarNumberOutline } from "react-icons/io5";
import { MdOutlineLogin, MdOutlineWorkOutline } from "react-icons/md";
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import fav from '../../components/assert/images/fav/favicon.ico';
import logo from '../../components/assert/images/log.png';
import Avatar from '../../components/assert/images/management/men.png';
import InternalUserAction from '../../redex/InternalUser/Actions';
import CheckIn from '../dashBoard/checkIn/CheckIn';

const DashNav = ({ children }) => {
    const location = useLocation()
    const dispatch = useDispatch()
    const currentRoute = location?.pathname
    const navigate = useNavigate()
    const [showSideMenu, setShowSideMenu] = useState(localStorage.getItem('menu') ? localStorage.getItem('menu') : 'open')

    console.log(showSideMenu)
    const togleMenu = () => {
        if (localStorage.getItem('menu') === 'open') {
            setShowSideMenu('close');
            localStorage.setItem('menu', 'close')
        } else {
            setShowSideMenu('open');
            localStorage.setItem('menu', 'open')
        }
    }

    useEffect(() => {
        if (!localStorage.getItem('menu')) {
            localStorage.setItem('menu', 'open')
        }
    }, [])

    return (
        <div className='dashboar_main'>
            <div className={`dash_menu  ${showSideMenu !== 'open' && 'close_dash_menu'}`}>
                <div className={`${showSideMenu !== 'open' && 'dash_brand'}`}>
                    {
                        showSideMenu !== 'open' ?
                            <img className='icnon_logo' src={fav} alt='' /> :
                            <img className='main_logo_dash' src={logo} alt='' />
                    }

                </div>
                <div className='dash_menus custom-scrollbar'>
                    <ul className={`${showSideMenu !== 'open' && 'menu_closed'}`}>
                        <li className={`${currentRoute === '/dash-board' && 'active-nav'}`} onClick={(e) => navigate('/dash-board')}><div className='icon'><FaChartLine /></div>{showSideMenu === 'open' && <div className='menu_name'>Dash Board</div>}</li>
                        <li className={`${currentRoute === '/project' && 'active-nav'}`} onClick={(e) => navigate('/project')}><div className='icon'><MdOutlineWorkOutline /></div>{showSideMenu === 'open' && <div className='menu_name'>Projects</div>}</li>
                        <li className={`${currentRoute === '/attendance' && 'active-nav'}`} onClick={(e) => navigate('/attendance')}><div className='icon'><IoCalendarNumberOutline /></div>{showSideMenu === 'open' && <div className='menu_name'>Attendance</div>}</li>
                        <li className={`${currentRoute === '/leave' && 'active-nav'}`} onClick={(e) => navigate('/leave')}><div className='icon'><BsCalendar4Week /></div>{showSideMenu === 'open' && <div className='menu_name'>Leave</div>}</li>

                    </ul>
                </div>
            </div>

            <div className={`dash_content_section ${showSideMenu !== 'open' && 'open_full'}`}>
                <div>
                    <div className='dash_header_sec'>
                        <div className='admin_humber'>
                            <HiMenuAlt1 onClick={() => togleMenu()} />
                        </div>
                        <div className='flex-alc'>
                            <div className='icon_btn login_btn'>
                                <button onClick={(() => dispatch({ type: InternalUserAction.CHECK_IN_MODEL, payload: { open: true, type: 'checkIn' } }))}>Check In <MdOutlineLogin /></button>
                            </div>
                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic " className='menu_drop'>
                                    <img src={Avatar} alt='' />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>

                    </div>
                    <main className='main_comp_sec'>
                        {children}
                    </main>
                </div>
            </div>
            <CheckIn />
        </div >
    )
}

export default DashNav
