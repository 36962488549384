import 'bootstrap/dist/css/bootstrap.min.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import React from "react";
import { Provider } from "react-redux";
import './components/assert/style.css';
import Loader from "./components/common/Loader";
import RoutesList from "./components/routes/RoutesList";
import { store } from "./redex/Store";

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <Loader />
        <RoutesList />
      </Provider>
    </div>
  );
}

export default App;
