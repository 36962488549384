import React from 'react'

const Experience = () => {
    return (
        <section class="tf-space flat-counter tf-counter">
            <div class="container">
                <div class="row">
                    <div class="col-lg-3 col-md-6">
                        <div class="about-counter-inner wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                            <div class="number-counter">
                                <span></span>
                                <span class="number" data-speed="4000" data-to="30" data-inviewport="yes">30+</span>
                            </div>
                            <div class="heading-count">
                                <h3>YEARS OF<br />
                                    EXPERIENCE</h3>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="about-counter-inner wow fadeInUp" data-wow-delay="300ms" data-wow-duration="1500ms">
                            <div class="number-counter">
                                <span class="number" data-speed="4000" data-to="69" data-inviewport="yes">600+</span>
                            </div>
                            <div class="heading-count">
                                <h3>TRUSTED <br />
                                    CLIENTS</h3>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="about-counter-inner wow fadeInUp" data-wow-delay="600ms" data-wow-duration="1500ms">
                            <div class="number-counter">
                                <span class="number" data-speed="4000" data-to="330" data-inviewport="yes">6000+</span>
                            </div>
                            <div class="heading-count">
                                <h3>PROJECTS <br />
                                    DONE</h3>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="about-counter-inner wow fadeInUp" data-wow-delay="900ms" data-wow-duration="1500ms">
                            <div class="number-counter">
                                <span class="number" data-speed="4000" data-to="25" data-inviewport="yes">25+</span>
                            </div>
                            <div class="heading-count">
                                <h3>TEAM <br />
                                    ADVISORS</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Experience
