import React from 'react'
import BrudCrumb from '../../components/common/BrudCrumb'
import DashNav from '../../components/common/DashNav'
import Content from '../../components/dashBoard/attendance/content'

const Attendance = () => {
    return (
        <DashNav>
            <BrudCrumb title='Attendance' />
            <Content />
        </DashNav>
    )
}

export default Attendance
