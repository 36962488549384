import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {

    return (
        <div>
            <footer id="footer" className="clearfix">
                {/* <div id="footer-top" className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="footer-schedule">
                                <div className="heading-schedule">
                                    <h2 className="section-40px-barlow font-weight-500">Need Consultation ?</h2>
                                </div>
                                <div className="button-footer">
                                    <Link to='/contact' className="button readmore">Book Schedule</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div id="footer-widgets" className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="logo-footer">
                                <a href="index.html" rel="home" className="main-logo">
                                    <img src="assets/images/log.png" alt="images" />
                                </a>
                            </div>
                            <p className="text-widget">At Astral Consulting Limited, we pride ourselves on delivering exceptional service and building long-lasting relationships with our clients.</p>
                            <div className="widget-social">
                                <ul className='p-0'>
                                    <li><Link href="#" className="facebook-icon"></Link></li>
                                    <li><Link href="#" className="twitter-icon"></Link></li>
                                    <li><Link href="#" className="linked-icon"></Link></li>
                                    <li><Link href="#" className="instagram-icon"></Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="footer-widgets-nav-menu text-white">
                                <div className="menu-1">
                                    <h3 className="widget-title-link-wrap">Official Info</h3>
                                    <div className="widget-links">
                                        <ul className="link-wrap">
                                            <li >
                                                <Link to='/' >Home</Link>
                                            </li>
                                            <li >
                                                <Link to='/about-us'>About</Link>
                                            </li>
                                            <li >
                                                <Link to='/services'>Services</Link>
                                            </li>
                                            {/* <li>
                                                <Link to='/case-studies'>Case Studies</Link>
                                            </li> */}
                                            {/* <li>
                                                <Link to='/blog'>Blog</Link>
                                            </li> */}
                                            <li>
                                                <Link to='/media'>Media</Link>
                                            </li>
                                            <li>
                                                <Link to='/contact'>Contact</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="menu-3">
                                    <h3 className="widget-title-link-wrap style-2">Get in Touch</h3>
                                    <ul className="widget-list-contact link-style-4">
                                        <li>
                                            <span className="meta-address">No.14 - A, 4th floor , <br />Ramakrishna Nagar ,<br />New Sidhaputhur , <br />Coimbatore - 641044</span>
                                        </li>
                                        <li><a href="contact@astralconsultants.com" className="meta-mail"><span
                                            className="__cf_email__">contact@astralconsultants.com</span></a></li>
                                        <li><a href="tel:+0422 2312548" className="meta-phone">+0422 2312548, 99949 12548   </a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <div id="bottom" className="clearfix has-spacer">
                <div id="bottom-bar-inner" className="container">
                    <div className="bottom-bar-inner-wrap">
                        <div className="bottom-bar-content-left link-style-4">
                            <div id="copyright"><span className="text">Copyright © 2024 astralconsultants</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Link id="scroll-top"></Link>
        </div>
    )
}

export default Footer
