import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import CommonAction from '../../redex/Common/Actions'
import servicesData from '../servicess/serviesContent'

const SubBanner = ({ title, pageName, image }) => {
    const { navLinks } = useSelector((state) => state?.CommonReducer)
    const history = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation();

    const onRedirect = (e, el) => {
        e?.preventDefault()
        const matchedService = servicesData?.find((val) => val?.title === el?.name);
        if (matchedService && el?.name !== 'Services') {
            history('/sub-servicess', { state: matchedService });
            dispatch({ type: CommonAction.NAV_LINKS, payload: [{ link: el?.link, name: el?.name }] });
        } else {
            history('/services', { state: servicesData });
            dispatch({ type: CommonAction.NAV_LINKS, payload: [] });
        }
    };
    useEffect(() => {
        if ((location?.pathname !== '/services') || (location?.pathname !== '/sub-servicess') || (location?.pathname !== '/servicess-details')) {
            dispatch({ type: CommonAction.NAV_LINKS, payload: [] });
        }

    }, [location, dispatch])

    return (
        <section className="flat-title-page parallax parallax-2" style={{
            backgroundImage: `url(${image})`,
            backgroundRepeat: 'no-repeat',
            objectFit: 'cover',
            backgroundPosition: 'top center',
            position: 'relative'
        }}>
            <div className="container-fluid">
                <div className="row">
                    <div className="breadcrumbs">
                        <h2 className="section-title-page">{title}</h2>
                        <div className="breadcrumb-trail link-style-4">
                            <Link className="home" to='/'>Home</Link>
                            {
                                navLinks?.map((el) => (
                                    <Link className="home" onClick={(e) => onRedirect(e, el)} >{el?.name}</Link>
                                ))
                            }
                            {
                                pageName && <span>{pageName}</span>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SubBanner
