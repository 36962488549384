import React from 'react'
import Header from '../components/common/Header'
import Footer from '../components/common/Footer'
import SubBanner from '../components/common/SubBanner'
import Content from '../components/servicess/Services'
import { ScrollToTop } from '../components/common/Helper'
import BannerImg from '../components/assert/images/subBanner/Services.jpg'

const Services = () => {
    ScrollToTop(0, 0)
    return (
        <div>
            <Header />
            <SubBanner title='Services' pageName='Services' image={BannerImg}/>
            <Content />
            <Footer />
        </div>
    )
}

export default Services
