
import React, { useState } from 'react';
import { AiOutlineMenuFold } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";
import { Link, useLocation } from 'react-router-dom';
import Toaster from './Toaster';

const Header = () => {
    const [showMenu, setShowMenu] = useState(false)
    const location = useLocation()
    const handleMobileMenu = () => {
        if (!showMenu) {
            document.getElementById('mobileMenu').classList.add('showMobileMenu')
        } else {
            document.getElementById('mobileMenu').classList.remove('showMobileMenu')
        }
        setShowMenu(!showMenu)
    }
    return (
        <div>
            <div id="site-header-wrap">
                <header id="site-header">
                    <div id="site-logo" className="clearfix">
                        <div id="site-logo-inner">
                            <Link to='/' rel="home" className="main-logo">
                                <img src="assets/images/log.png" alt="consalti" width="183" height="48"
                                    data-retina="assets/images/log.png" data-width="183" data-height="48" />
                            </Link>
                        </div>
                    </div>
                    <div className="header-box">

                        <div id="top-bar">
                            <div id="top-bar-inner">
                                <div className="top-bar-content">
                                    <div className="top-bar-left hed_mail_id">
                                        <span className="mail section-14px-regular"><a
                                            href="contact@astralconsultants.com"><span
                                                className="__cf_email__">contact@astralconsultants.com</span></a></span>
                                    </div>
                                    <div className="top-bar-right">
                                        <span className="phone section-14px-regular"><a href="tel:0422 2312548">+0422 2312548, 99949 12548</a></span>
                                        <div className="socials-header">
                                            <ul className="widget-socials link-style-3">
                                                <li><Link href="#" className="facebook"></Link></li>
                                                <li><Link href="#" className="twitter"></Link></li>
                                                <li><Link href="#" className="linked-in"></Link></li>
                                                <li><Link href="#" className="instagram"></Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div id="site-header-inner">
                            <div className="wrap-inner clearfix">

                                <div className="mobile-menu-button">
                                    {
                                        showMenu !== true ?
                                            <AiOutlineMenuFold onClick={handleMobileMenu} />
                                            :
                                            <IoMdClose onClick={handleMobileMenu} />
                                    }
                                    {/* <span></span> */}
                                </div>

                                <nav className="main-nav" id='mobileMenu'>
                                    <ul>
                                        <li className={`menu-item ${location?.pathname === '/' && 'current-menu-item'}`}>
                                            <Link to='/' >Home</Link>
                                        </li>
                                        <li className={`menu-item ${location?.pathname === '/about-us' && 'current-menu-item'}`}>
                                            <Link to='/about-us'>About Us</Link>
                                        </li>
                                        <li className={`menu-item ${location?.pathname === '/services' && 'current-menu-item'}`}>
                                            <Link to='/services'>Services</Link>
                                        </li>
                                        {/* <li className={`menu-item ${location?.pathname === '/case-studies' && 'current-menu-item'}`}>
                                            <Link to='/case-studies'>Case Studies</Link>
                                        </li> */}
                                        <li className={`menu-item ${location?.pathname === '/careers' && 'current-menu-item'}`}>
                                            <Link to='/careers'>Careers</Link>
                                        </li>
                                        {/* <li className={`menu-item ${location?.pathname === '/blog' && 'current-menu-item'}`}>
                                            <Link to='/blog'>Blog</Link>
                                        </li> */}
                                        <li className={`menu-item ${location?.pathname === '/media' && 'current-menu-item'}`}>
                                            <Link to='/media'>Media</Link>
                                        </li>
                                        <li className={`menu-item ${location?.pathname === '/contact' && 'current-menu-item'}`}>
                                            <Link to='/contact'>Contact</Link>
                                        </li>
                                    </ul>
                                </nav>

                            </div>

                            <div id="site-header-right">

                                <div className="button-header">
                                    <Link to="/contact">GET REQUEST</Link>
                                </div>
                            </div>
                        </div>

                    </div>
                </header>
                <Toaster />
            </div>

        </div >
    )
}

export default Header