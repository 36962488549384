import actions from './Actions'

const initialState = {
    bannerList: [],
    loader: false,
    managementList: [ ],
    navLinks: [],
    toasterAleart: {
        open: false,
        message: '',
        type: 'err'
    }
}

const CommonReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SET_BANNER:
            return {
                ...state,
                bannerList: action.payload
            }
        case actions.SET_LOADER:
            return {
                ...state,
                loader: action.payload
            }
        case actions.SET_MANAGEMENT:
            return {
                ...state,
                managementList: action.payload
            }

        case actions.NAV_LINKS:
            return {
                ...state,
                navLinks: action.payload
            }

        case actions.SET_TOASTER:
            return {
                ...state,
                toasterAleart: action.payload
            }
        default:
            return state
    }
}

export default CommonReducer