import React, { useEffect } from 'react'
import { MdVerified } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';
import CommonAction from '../../redex/Common/Actions';

const Toaster = () => {
    const dispatch = useDispatch()
    const { toasterAleart } = useSelector((state) => state.CommonReducer)
    useEffect(() => {
        if (toasterAleart?.open) {
            setTimeout(() => (
                dispatch({ type: CommonAction.SET_TOASTER, payload: false })
            ), 5000)
        }
    }, [toasterAleart, dispatch])
    return (
        <>
            {
                toasterAleart?.open &&
                <div className={`toastBar ${toasterAleart?.type !== 'err' ? 'success_alear' : ''}`} >
                    <div class="toastBar-content">
                        <MdVerified />

                        <div class="message">
                            <span class="text text-1">{toasterAleart?.type !== 'err' ? 'Success' : 'Error'} </span>
                            <span class="text text-2">{toasterAleart?.message}</span>
                        </div>
                    </div>
                    <i class="fa-solid fa-xmark close"></i>

                    <div class="progress active"></div>
                </div>
            }
        </>
    )
}

export default Toaster
