import React from 'react';
import BrudCrumb from '../../components/common/BrudCrumb';
import DashNav from "../../components/common/DashNav";

const Projects = () => {
    return (
        <DashNav>
            <BrudCrumb
                navLinks={[
                    // {
                    //     url: '/dash-board',
                    //     pageName: 'Dash Board'
                    // },
                ]}
                title='Projects' />

            <div className='dash_grid'>
                <h1>Projects</h1>
            </div>
        </DashNav>
    )
}

export default Projects
