import React from 'react';

const NestedInputs = ({ errors, register, data }) => {
    return (
        <div className="dash-fm-group">
            <label className="dash-fm-label">
                {data}
            </label>
            <input
                className='dash-fm-input'
                placeholder={`Enter ${data}`}
                {...register(data, { required: `${data} is required` })}
            />
            {errors?.[data]?.message && <p className='error-message'>{errors?.[data]?.message}</p>}
        </div>
    );
};

const InputDynamic = ({ errors, register, data }) => {
    console.log(data)
    return (
        <div>
            <NestedInputs register={register} errors={errors} data={data} />
            {/* {
                Object?.keys(data)?.map((val, ind) => (
                    <NestedInputs key={ind} register={register} errors={errors} data={val} />
                ))
            } */}
        </div>
    );
};

export default InputDynamic;
