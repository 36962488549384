import React, { useEffect } from 'react'
import Header from '../common/Header';
import SubBanner from '../common/SubBanner';
import { Link, useLocation } from 'react-router-dom';
import Footer from '../common/Footer';
import { ScrollToTop } from '../common/Helper';
import BannerImg from '../../components/assert/images/subBanner/Services.jpg'
import { useDispatch } from 'react-redux';
import CommonAction from '../../redex/Common/Actions';

const SubServicess = () => {
    const location = useLocation();
    const dispatch = useDispatch()
    const { state } = location
    ScrollToTop(0, 0)

    useEffect(() => {
        dispatch({
            type: CommonAction.NAV_LINKS, payload: [
                { name: "Services", link: '/services' },
                { name: state?.title, link: '/sub-servicess' },
            ]
        })
    })

    return (
        <div>
            <Header />
            <SubBanner title={state?.title} image={BannerImg} />
            <section class="tf-space flat-service">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="section-title wow fadeInDown" data-wow-delay="0ms" data-wow-duration="1500ms">
                                <div class="features-content-left">
                                    <h2>Our Business & Financial<br />
                                        Consulting Services</h2>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="service-content-right wow fadeInDown" data-wow-delay="0ms"
                                data-wow-duration="1500ms">
                                <p>Partner with Astral Consulting Limited to unlock your organization's full potential. We are committed to delivering exceptional service, fostering long-term relationships, and empowering your business to thrive.</p>
                            </div>
                        </div>
                        <div className='row j-c'>
                            {state?.subServices?.map((el, ind) => (
                                <div class="col-md-4">
                                    <div class="service-box">
                                        <div class="services-post">
                                            <img src={el?.image} alt="images" />
                                        </div>
                                        <div class="service-content">
                                            <div class="inner">
                                                {/* <h4>Services</h4> */}
                                                <Link to={'/servicess-details'} state={el}><h3>{el?.title}</h3></Link>
                                                {/* <hr /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section >
            <Footer />
        </div>

    )
}

export default SubServicess
