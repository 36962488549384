import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import InternalUserAction from '../../../../redex/InternalUser/Actions';
import InputDynamic from '../../../common/InputDynamic';

function ProfileEdit() {
  const { editProfileModel } = useSelector((state) => state?.InternalUserReducer);
  const dispatch = useDispatch();
  const { register, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {},
  });

  const handleClose = () => dispatch({ type: InternalUserAction?.EDIT_PROFILE_MODEL, payload: false });

  const onSubmit = (data) => {
    // Handle form submission here
    console.log(data);
  };

  return (
    <>
      <Modal centered size='lg' show={editProfileModel?.open} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row user_details_sec">
              {editProfileModel?.data?.user && Object.keys(editProfileModel?.data?.user).map((key, ind) => key !== 'address' && (
                <div className="col-md-4" key={ind}>
                  <InputDynamic register={register} errors={errors} data={key} />
                </div>
              ))}
              {editProfileModel?.data?.user?.address && Object.keys(editProfileModel?.data?.user?.address).map((key, ind) => (
                <div className="col-md-4" key={ind}>
                  <InputDynamic register={register} errors={errors} data={key} />
                </div>
              ))}
            </div>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" type="submit">
                Save Changes
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ProfileEdit;
