import React from 'react'
import OwlCarousel from 'react-owl-carousel';

const WhatWeDo = () => {

    const options = {
        items: 1,
        loop: true,
        margin: 10,
        nav: false,
        autoplay: true,
        // navText: [<span>prev</span>, <span>prev</span>],
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 2,
            },
            1000: {
                items: 3,
            },
        },
    };
    const whatWeDoContent = [
        {
            "title": "Internal Audit",
            "content": "Internal Audit is an independent and objective evaluation of an organization's operations to ensure compliance with laws, regulations, and policies."
        },
        {
            "title": "Governance, Risk & Control",
            "content": "Governance, Risk & Control involves assessing the effectiveness of an organization's internal controls and risk management practices."
        },
        {
            "title": "Internal Audit Co Sourcing",
            "content": "Internal Audit Co-Sourcing involves partnering with an external service provider to supplement the internal audit function of an organization."
        },
        {
            "title": "Concurrent Audit",
            "content": "Concurrent Audit involves conducting real-time audits of financial transactions to identify and prevent errors, fraud, and other irregularities."
        },
        {
            "title": "Control Self-Assessment",
            "content": "Control Self-Assessment involves an internal review process that allows an organization to assess the effectiveness of its internal controls."
        },
        {
            "title": "Stock Audit",
            "content": "Stock Audit is a detailed examination of an organization’s inventory to verify its accuracy and value."
        },
        {
            "title": "Fixed Asset Assurance",
            "content": "Fixed Asset Assurance involves verifying and validating the existence, location, and condition of an organization’s fixed assets, such as buildings, machinery, and equipment."
        },
        {
            "title": "Information Security Service",
            "content": "Information Security Services involve assessing and managing the security risks associated with an organization's information assets."
        },
        {
            "title": "ERP Audit",
            "content": "ERP Audit involves reviewing an organization's Enterprise Resource Planning (ERP) system to ensure that it is functioning effectively and efficiently, and that it complies with relevant regulations and standards."
        },
        {
            "title": "ERP Consulting",
            "content": "ERP Consulting involves providing expert advice on the selection, implementation, and optimization of enterprise resource planning systems."
        },
        {
            "title": "ISO 27001 (ISMS) implementation",
            "content": "ISO 27001 (ISMS) Implementation involves helping organizations establish and maintain an information security management system that complies with the international standard for information security."
        },
        {
            "title": "Robotic Process Automation (RPA)",
            "content": "Robotic Process Automation (RPA) involves automating routine and repetitive business processes using software robots to improve efficiency and productivity."
        },
        {
            "title": "Payroll Solutions",
            "content": "Payroll Solutions involve managing and processing payroll for organizations, including calculating employee wages, deductions, and taxes."
        },
        {
            "title": "Performance Management System",
            "content": "Performance Management System involves designing and implementing a framework to measure, evaluate, and improve employee/department/organization performance."
        },
        {
            "title": "Drafting SOP",
            "content": "Drafting SOP involves developing standard operating procedures to ensure consistency and efficiency in business processes."
        },
        {
            "title": "Textile Solution",
            "content": "Textile Solution involves providing specialized consulting and auditing services for the textile industry."
        },
        {
            "title": "Org Structure Designing",
            "content": "Org Structure Designing involves designing an optimal organizational structure to achieve strategic goals and objectives."
        },
        {
            "title": "Defining Roles & Responsibilities",
            "content": "Defining Roles & Responsibilities involves defining clear roles and responsibilities for employees to improve accountability and efficiency."
        },
        {
            "title": "Employee Engagement Survey",
            "content": "Employee Engagement Survey involves conducting surveys to measure employee satisfaction, engagement, and retention."
        },
        {
            "title": "Book Keeping Services",
            "content": "Book Keeping Services involve maintaining accurate and up-to-date financial records for organizations."
        },
        {
            "title": "Shared CFO Services",
            "content": "Shared CFO Services involve providing part-time CFO services to organizations that require financial expertise but do not need a full-time CFO."
        },
        {
            "title": "Forensic Investigation Audit",
            "content": "Forensic Investigation Audit involves conducting an in-depth investigation of financial transactions to identify fraud, embezzlement, or other financial crimes."
        },
        {
            "title": "Vendor Due Diligence",
            "content": "Vendor Due Diligence involves conducting a thorough investigation of potential vendors to ensure that they are reliable and meet the organization's standards."
        },
        {
            "title": "Setting Whistleblower Program",
            "content": "Setting Whistleblower Program involves creating a reporting mechanism for employees to report any unethical or illegal activities within the organization."
        },
        {
            "title": "Revenue Assurance Audit",
            "content": "Revenue Assurance Audit involves conducting an audit to identify revenue leakages and ensure that revenue is being recorded accurately and completely."
        }
    ]
    

    return (
        <section className="tf-space flat-work what_we_do_sec">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="section-title wow fadeInDown" data-wow-delay="0ms" data-wow-duration="1500ms">
                            <h3 className="section-heading">What We Do</h3>
                            <div className="features-content-left">
                                <h2>Our Audit & Assurance services Include</h2>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="swiper-container carousel work-carousel">
                            <div className="swiper-wrapper">
                                <OwlCarousel className='owl-theme' {...options}>
                                    {
                                        whatWeDoContent?.map((el, ind) => (
                                            <div className="swiper-slide" key={ind}>
                                                <div className="work-box wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                                    <div className="work-icon">
                                                        <div className="top item-shape item-shape1"></div>
                                                        <div className="top item-shape item-shape2"></div>
                                                        <div className="bottom item-shape item-shape1"></div>
                                                        <div className="bottom item-shape item-shape2"></div>
                                                        <div className="master-icon-box">
                                                            <div className="icon-wrap">
                                                                <span className="icon icon-programer"></span>
                                                            </div>
                                                            <div className="text-wrap">
                                                                <h3 className="headline">{el?.title}</h3>
                                                                <p>{el?.content}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }

                                </OwlCarousel>
                            </div>
                        </div>
                        {/* <div className="swiper-button-next btn-custom-carousel-next"></div>
                        <div className="swiper-button-prev btn-custom-carousel-prev"></div> */}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WhatWeDo
