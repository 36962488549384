import React, { useState } from 'react'
import bala from '../../components/assert/images/management/bala.png'
import jos from '../../components/assert/images/management/jos.jpeg'
import madhukar from '../../components/assert/images/management/madhukar.png'
import Muthukumar from '../../components/assert/images/management/muthu.jpeg'
import ramesh from '../../components/assert/images/management/ramesh.png'
import shalini from '../../components/assert/images/management/shalini.jpeg'
import sivakumar from '../../components/assert/images/management/sivakumar.jpeg'
const OurManagement = () => {
    const [showDetails, setShowDetails] = useState(false)
    const [managementData] = useState([
        {
            "name": "Muthukumar",
            "designation": "Advisor - Information system",
            "profile": Muthukumar,
            qulaification: 'FCA, CIA,CCSA',
            experience: '30 years of expereince '
        },
        {
            "name": "Balamurali",
            "designation": "Advisor - Consulting",
            "profile": bala,
            qulaification: 'FCA',
            experience: '35+ years of expereince'
        },
        {
            "name": "Ramesh",
            "designation": "Advisor - Forensic Audit",
            "profile": ramesh,
            qulaification: 'FCA,CIA,CFE',
            experience: '25+years of expereince'
        },
        {
            "name": "Shalini",
            "designation": "Advisor - Internal Audit ",
            "profile": shalini,
            qulaification: '15 years of expereince',
            experience: 'FCA,DISA'
        },
        {
            "name": "Madhukar",
            "designation": "Advisor - Taxation",
            "profile": madhukar,
            qulaification: 'FCA',
            experience: '30 years of expereince'
        },
        {
            "name": "Jose Mekkalath",
            "designation": "Advisor - Investment Banking",
            "profile": jos,
            qulaification: 'FCA',
            experience: '25+years of expereince'
        },
        {
            "name": "Sivakumar",
            "designation": "Advisor - Grants & Subsidies",
            "profile": sivakumar,
            qulaification: 'MSC,PHD',
            experience: '30+ years of expereince'
        }

    ])
    return (
        <section className="tf-space flat-team">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="section-title wow fadeInDown" data-wow-delay="0ms" data-wow-duration="1500ms">
                            <h3 className="section-heading">Astral Consulting</h3>
                            <div className="features-content-left">
                                <h2>Our Management</h2>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="swiper-container carousel-2 row justify-content-center">
                            {managementData?.map((el, ind) => (
                                <div className='col-md-3 col-lg-3 col-sm-6 col-6' key={ind} >
                                    <div className="team-content-box wow fadeInUp management_card c-p" data-wow-delay="0ms" data-wow-duration="1500ms" onMouseEnter={() => setShowDetails(ind)} onMouseLeave={() => setShowDetails(false)} >
                                        {/* <HiDotsVertical className='man_more_btn'/> */}
                                        <div className="team-post">
                                            <img src={el?.profile} alt="images" />
                                        </div>
                                        <div className="team-info">
                                            <h4>{el?.name}</h4>
                                            <h5>{el?.designation}</h5>
                                        </div>
                                        <div className="bottom item-shape item-shape1"></div>
                                        <div className="bottom item-shape item-shape2"></div>
                                        {showDetails === ind && (
                                            <div className={`man_detai ${showDetails === ind ? 'show' : ''}`}>
                                                <table className="table mt-3">
                                                    <tbody>
                                                        <tr>
                                                            <th>Name :</th>
                                                            <td>{el?.name}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Designation :</th>
                                                            <td>{el?.designation}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Qualification :</th>
                                                            <td>{el?.qulaification}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Experience :</th>
                                                            <td>{el?.experience}</td>
                                                        </tr>
                                                        {/* <tr>
                                                            <th>Specialization :</th>
                                                            <td>-</td>
                                                        </tr> */}
                                                    </tbody>
                                                </table>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>

                    </div>
                </div>
            </div>
        </section>

    )
}

export default OurManagement
